angular.module('MainApp').config(['$stateProvider', '$urlRouterProvider',
     function($stateProvider, $urlRouterProvider) {
        var buttons = {
            "set1": ["stopl", "abortl", "continuer"],
            "set2": ["backl", "stopl"],
            "set3": ["stopl", "abortl", "nextr"],
            "set4": ["closer"],
            "set5": ["stopl", "againr"],
            "set6": ["backl", "stopl", "input"],
            "set7": ["backl", "stopl", "continuer"],
            "set8": ["backl", "stopl", "nextr"],
            "set9": ["stopl", "continuer"],
            "set10": ["continuer"],
            "set11": ["stopl", "againr", "input"],
            "set12": ["stopl"],
            "set13": ["againr"],
            "set14": ["stop_pay", "againCustomr"],
            "set15": ["backl"],
            "set16": ["backl", "continuer"],
            "set17": ["biggerlockerr", "closer"],
            "set18": ["nocustomer", "againr"],
            "avisageInput": ["backl", "stopl", "input_avisage"],
            "sweep": ["openall", "continuer"]
        };
        var breads = {
            "bread1": ["bread.retrieve_parcel"],
            "bread2": ["bread.retrieve_parcel", "bread.information", "bread.overview", "bread.empty_locker"],
            "bread3": ["bread.retrieve_parcel", "bread.information", "bread.overview", "bread.options", "bread.empty_locker"],
            "bread4": ["bread.retrieve_parcel", "bread.information", "bread.overview", "bread.pay", "bread.empty_locker"],
            "bread5": ["bread.deliver", "bread.scan", "bread.fill_locker"],
            "bread6": ["bread.deliver", "bread.enter_barcode", "bread.fill_locker"],
            "bread7": ["bread.deliver", "bread.options", "bread.fill_locker"],
            "bread8": ["bread.deliver", "bread.safe_type", "bread.fill_locker"],
            "bread9": ["bread.deliver", "bread.safe_type", "bread.pay", "bread.fill_locker"],
            "bread10": ["bread.deliver", "bread.fill_locker"],
            "bread11": ["bread.deliver", "bread.information", "bread.fill_locker"],
            "bread12": ["bread.deliver", "bread.information", "bread.overview", "bread.fill_locker"],
            "bread13": ["bread.deliver", "bread.fill_locker", "bread.options"],
            "bread14": ["bread.deliver", "bread.fill_locker", "bread.options", "bread.fill_different_locker"],
            "bread15": ["bread.deliver", "bread.fill_locker", "bread.options", "bread.aborted"],
            "bread16": ["bread.reopen_safe", "bread.information", "bread.finish"],
            "bread17": ["bread.scan"],
            "bread18": ["bread.scan", "bread.manual_input"],
            "bread19": ["bread.deliver"],
            "bread20": ["bread.deliver", "overig"],
            "bread21": ["bread.rent", "bread.safe_type", "bread.period", "bread.information", "bread.pay", "bread.finish"],
            "bread22": ["bread.fill", "bread.remaining"],
            "bread23": ["bread.avisage", "bread.scan", "bread.fill_locker"],
            "bread24": ["bread.fm", "bread.safe_type", "bread.fill_locker"],
            "rent|type|information": ["bread.fm", "bread.safe_type", "bread.information"],
            "sweep": ["bread.sweep", "bread.module", "bread.lockers"]
        };
        $urlRouterProvider.otherwise("/");
        $stateProvider
            .state('home', {
                url: '/',
                data: {
                    bigheadText: 'bighead.welcome',
                    bigheadReload: true,
                    maintext: 'maintext.emptyone',
	                titleTextButtons: {
		                title: 'maintext.scan_card_or_parcel',
		                text: 'maintext.or_choose',
		                buttons: [
			                { text: 'retrieve_parcel', state: 'retrieve' },
			                { text: 'bring_parcel', state: 'deliver_options' }
		                ]
	                }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2viewLarge.html"
                    },
                    "view0@home": {
                        templateUrl: "views/bighead/speak.html"
                    },
                    "view1@home": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'homeController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/info.html"
                    }
                }
            })
            .state('retrieve', {
                url: '/scan_or_input',
                data: {
                    buttons: buttons.set2,
                    bread: breads.bread1,
                    lefttext:"lefttext.scan_or_input",
	                titleTextButtons: {
		                title: 'maintext.make_choice',
		                buttons: [
			                { text: 'customercode_input', state: 'customercode_input' },
			                { text: 'retrievecode_input', state: 'retrievecode_input' }
		                ]
	                },
                    burned: 0
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@retrieve": {
                        templateUrl: "views/templates/lefttext.html"
                    },
                    "view1@retrieve": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        //templateUrl: "views/scan_or_input.html"
                        controller: 'homeController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('reopen', {
                url: '/reopen',
                data: {
                    buttons: buttons.set2,
                    bread: breads.bread16,
                    lefttext:"lefttext.scan_or_input",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'customercode_input', state: 'customercode_input' },
                            { text: 'retrievecode_input', state: 'retrievecode_input' },
                            { text: 'barcode_input', state: 'deliver_input' }
                        ]
                    },
                    burned: 0
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@reopen": {
                        templateUrl: "views/templates/lefttext.html"
                    },
                    "view1@reopen": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        //templateUrl: "views/scan_or_input.html"
                        controller: 'homeController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
	        .state('customercode_input', {
                url: '/customercode_input',
                params: { user: false, type: '' },
                data: {
                    buttons: buttons.set8,
                    bread: breads.bread2,
                    burned: 1,
                    input_1: { text: 'maintext.enter_customercode', type: 'text' },
                    input_2: { text: 'maintext.also_scan_customercode', type: 'text', class_label: 'small' },
                    input_3: { text: 'maintext.also_scan_drivercode', type: 'text', class_label: 'small' },
                    customer_allowed: true
                },
                views: {
                    "main": {
                        templateUrl: "views/customer_login_input.html",
                        controller: 'customerLoginController'
                    }
                }
            })
	        .state('rent_customercode_input', {
                url: '/rent_customercode_input',
                params: { user: false },
                data: {
                    buttons: buttons.set8,
                    bread: breads.bread21,
                    burned: 0,
                    input_1: { text: 'maintext.enter_customercode', type: 'text' },
                    input_2: { text: 'maintext.also_scan_customercode', type: 'text', class_label: 'small' },
                    anonymous_allowed: true
                },
                views: {
                    "main": {
                        templateUrl: "views/rent_customer_login_input.html"
                    }
                }
            })
            .state('email_input_external', {
                url: '/email_input_external',
                data: {
                    buttons: buttons.set7,
                    bread: breads.bread21,
                    burned: 3,
                    input_1: { text: 'maintext.enter_email', type: 'text' },
                    input_2: { text: 'maintext.enter_confirm_email', type: 'text' }
                },
                views: {
                    "main": {
                        templateUrl: "views/email_input_single.html",
                        controller: "deliveryNotifyMailController"
                    }
                }
            })
            .state('email_input', {
                url: '/email_input',
                params: { anonymous: false, both: false},
                data: {
                    buttons: buttons.set8,
                    bread: breads.bread21,
                    burned: 3,
                    input_1: { text: 'maintext.enter_email', type: 'text' },
                    input_2: { text: 'maintext.enter_confirm_email', type: 'text' }
                },
                views: {
                    "main": {
                        templateUrl: "views/email_input.html",
                        controller: "rentMailController"
                    }
                }
            })
            .state('email_input_haler', {
                url: '/email_input_haler',
                params: { anonymous: false, both: false},
                data: {
                    buttons: buttons.set8,
                    bread: breads.bread21,
                    burned: 3,
                    input_1: { text: 'maintext.enter_email_receiver', type: 'text' },
                    input_2: { text: 'maintext.enter_confirm_email_receiver', type: 'text' }
                },
                views: {
                    "main": {
                        templateUrl: "views/email_input.html",
                        controller: "rentMailController"
                    }
                }
            })
            .state('mobile_input', {
                url: '/mobile_input',
                params: { anonymous: false, both: false},
                data: {
                    buttons: buttons.set8,
                    bread: breads.bread21,
                    burned: 3,
                    input_1: { text: 'maintext.enter_mobile', type: 'text' },
                    input_2: { text: 'maintext.enter_confirm_mobile', type: 'text' }
                },
                views: {
                    "main": {
                        templateUrl: "views/mobile_input.html"
                    }
                }
            })
            .state('mobile_input_haler', {
                url: '/mobile_input_haler',
                params: { anonymous: false, both: false},
                data: {
                    buttons: buttons.set8,
                    bread: breads.bread21,
                    burned: 3,
                    input_1: { text: 'maintext.enter_mobile_receiver', type: 'text' },
                    input_2: { text: 'maintext.enter_confirm_mobile_receiver', type: 'text' }
                },
                views: {
                    "main": {
                        templateUrl: "views/mobile_input.html"
                    }
                }
            })
	        .state('locker_opened', {
		        url: '/locker_opened',
		        params: { delivery_partner_id:0, locker_number:'00.00', message_status:'', message_next_locker:'',
                    autoopen: false, notify_about_avisage:''},
		        data: {
			        buttons: buttons.set17,
                    bread: breads.bread2,
			        burned: 3,
			        bigheadText: 'bighead.thankyou',
			        maintext: 'maintext.locker_opened'
		        },
		        views: {
			        "main": {
				        templateUrl: "views/templates/2view.html",
                        controller: "openLockerController"
			        },
			        "view0@locker_opened": {
				        templateUrl: "views/bighead/speak_arm_bend.html"
			        },
			        "view1@locker_opened": {
				        templateUrl: "views/templates/text.html"
			        }
		        }
	        })
            .state('rent_locker_opened', {
                url: '/rent_locker_opened',
                params: { locker_number:'00.00', message_status:'', message_next_locker:'' },
                data: {
                    buttons: buttons.set4,
                    bread: breads.bread21,
                    burned: 5,
                    bigheadText: 'bighead.thankyou',
                    maintext: 'maintext.locker_opened'
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html",
                        controller: "openLockerController"
                    },
                    "view0@rent_locker_opened": {
                        templateUrl: "views/bighead/speak_arm_bend.html"
                    },
                    "view1@rent_locker_opened": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
	        .state('locker_delivery_opened', {
                url: '/locker_delivery_opened',
                params: { locker_number:'00.00' },
                data: {
                    buttons: buttons.set4,
                    bread: breads["bread10"],
                    burned: 1,
                    bigheadText: 'bighead.thankyou',
                    maintext: 'maintext.locker_delivery_opened'
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html",
                        controller: "openLockerController"
                    },
                    "view0@locker_delivery_opened": {
                        templateUrl: "views/bighead/speak_arm_bend.html"
                    },
                    "view1@locker_delivery_opened": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
	        .state('my_lockers', {
		        url: '/my_lockers',
		        params: { receive_code: false},
		        data: {
			        buttons: buttons.set2,
                    bread: breads.bread2,
			        burned: 2
		        },
		        views: {
			        "main": {
			            controller: "myLockersController",
				        templateUrl: "views/my_lockers.html"
			        }
		        }
	        })
            .state('my_lockers_transporter', {
                url: '/my_lockers_transporter',
                params: { receive_code: false},
                data: {
                    buttons: buttons.set2,
                    bread: breads.bread2,
                    burned: 2
                },
                views: {
                    "main": {
                        controller: "MyLockersTransporterController",
                        templateUrl: "views/my_lockers_transporter.html"
                    }
                }
            })
            .state('retrievecode_input', {
                url: '/retrievecode_input',
                data: {
                    buttons: buttons.set7,
                    bread: breads.bread2,
                    burned: 1,
                    input_1: { text: 'maintext.enter_receivecode', type: 'text' },
                    input_2: { text: 'maintext.also_scan_receivecode', type: 'text', class_label: 'small' }
                },
                views: {
                    "main": {
                        controller: 'barcodeRetrieveController',
                        templateUrl: "views/barcode_receiver_input.html"

                    }
                }
            })
            .state('customer_incorrect', {
                url: '/customer_incorrect',
                data: {
                    buttons: buttons.set5,
                    bread: breads.bread2,
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.customer_incorrect',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@customer_incorrect": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@customer_incorrect": {
                        templateUrl: "views/templates/text.html"
//                        controller: 'mineController',
                    }
                }
            })
            .state('barcode_incorrect', {
                url: '/barcode_incorrect',
                data: {
                    buttons: buttons.set5,
                    bread: breads.bread2,
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.barcode_incorrect',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@barcode_incorrect": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@barcode_incorrect": {
                        templateUrl: "views/templates/text.html"
//                        controller: 'mineController',
                    }
                }
            })
            .state('delivery_incorrect', {
                url: '/delivery_incorrect',
                data: {
                    buttons: buttons.set5,
                    bread: breads["bread6"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.delivery_incorrect',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@delivery_incorrect": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@delivery_incorrect": {
                        templateUrl: "views/templates/text.html"
//                        controller: 'mineController',
                    }
                }
            })
            .state('no_avisage', {
                url: '/no_avisage',
                data: {
                    buttons: buttons.set5,
                    bread: breads["bread6"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.avisage_unknown_barcode',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@no_avisage": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@no_avisage": {
                        templateUrl: "views/templates/text.html"
//                        controller: 'mineController',
                    }
                }
            })
            .state('scan_incorrect', {
                url: '/scan_incorrect',
                data: {
                    buttons: buttons.set11,
                    bread: breads["bread5"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.scan_incorrect',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@scan_incorrect": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@scan_incorrect": {
                        templateUrl: "views/templates/text.html"
//                        controller: 'mineController',
                    }
                }
            })
            .state('scan_again', {
                url: '/scan_again',
                data: {
                    buttons: buttons.set12,
                    bread: breads["bread5"],
                    maintext: 'maintext.scan_again',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@scan_again": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@scan_again": {
                        templateUrl: "views/templates/text.html"
//                        controller: 'mineController',
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('transporter', {
                url: '/transporter',
                data: {
                    buttons: buttons.set9,
                    bread: breads["bread2"],
                    burned: 2
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@transporter": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@transporter": {
                        templateUrl: "views/transporter.html",
                        controller: "transporterOverviewController"
                    }
                }
            })
            .state('transporter_login', {
                url: '/transporter_login',
                params: { user: null },
                data: {
                    buttons: buttons.set8,
                    bread: breads["bread2"],
                    burned: 1,
                    input_1: { text: 'maintext.enter_customercode2', type: 'text' },
                    input_2: { text: 'maintext.also_enter_cardcode2', type: 'text', class_label: 'small' }
                },
                views: {
                    "main": {
                        templateUrl: "views/customer_login_input.html",
                        controller: 'customerLoginController'
                    }
                }
            })
            .state('transporter_choose', {
                url: '/transporter_choose',
                data: {
                    buttons: buttons.set12,
                    bread: breads["bread20"],
                    burned: 1,
                    titleTextButtons: {
                        text: 'transportertext.dear_choose',
                        buttons: [
                            { text: 'fill_locker', state: 'scan_package' },
                            { text: 'empty_locker', state: 'transporter' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html",
                        controller: "TransportersNameController"
                    },
                    "view0@transporter_choose": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@transporter_choose": {
                        templateUrl: "views/templates/title_text_buttons.html"
                    }
                }
            })
            .state('transporter_choose_avisage', {
                url: '/transporter_choose_avisage',
                data: {
                    buttons: buttons.set12,
                    bread: breads["bread22"],
                    burned: 1,
                    titleTextButtons: {
                        text: 'transportertext.dear_choose',
                        buttons: [
                            { text: 'fill_locker', state: 'scan_package' },
                            { text: 'empty_locker', state: 'transporter' },
                            { text: 'avisage', state: 'scan_package_avisage'}
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html",
                        controller: "TransportersNameController"
                    },
                    "view0@transporter_choose_avisage": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@transporter_choose_avisage": {
                        templateUrl: "views/templates/title_text_buttons.html"
                    }
                }
            })
            .state('transporter_sos', {
                url: '/transporter_sos',
                params: { message_status: '', next_locker:'', autoopen: false },
                data: {
                    buttons: buttons.set12,
                    bread: breads['bread2'],
                    maintext: 'maintext.transporter_sos',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html",
                        controller: "TransportersSosController"
                    },
                    "view0@transporter_sos": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@transporter_sos": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('transporter_close_locker', {
                url: '/transporter_close_locker',
                params: { message_status: '', next_locker:'', autoopen: false, 'title':'Chauffeur', 'locker_number': 'xx' },
                data: {
                    buttons: buttons.set12,
                    bread: breads['bread2'],
                    maintext: 'maintext.transporter_close_locker',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@transporter_close_locker": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@transporter_close_locker": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('locker_options_emptied_filled', {
                url: '/locker_options_emptied_filled',
                params: { locker_number:'00.00' },
                data: {
                    buttons: buttons.set12,
                    bread: breads["bread2"],
                    burned: 2,
                    lefttext:"lefttext.one_parcel_to_be_emptied",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'empty_next_locker', actionCommand: 'locker_next_opened' },
                            { text: 'view_options_locker', actionCommand: 'locker_options' },
                            { text: 'overview_opened_lockers', actionCommand: 'locker_overview' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html",
                        controller: 'TransportersController'
                    },
                    "view0@locker_options_emptied_filled": {
                        templateUrl: "views/templates/lefttext.html"
                    },
                    "view1@locker_options_emptied_filled": {
                        templateUrl: "views/templates/title_text_buttons.html"
                    }
                }
            })
            .state('locker_options', {
                url: '/locker_options',
                params: { locker_number:'00.00' },
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread3"],
                    burned: 3,
                    lefttext:"lefttext.options",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'reopen', actionCommand: 'locker_opened' },
                            { text: 'empty', actionCommand: 'locker_empty' }
                            //{ text: 'damaged', state: 'package_damaged' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@locker_options": {
                        templateUrl: "views/templates/lefttext.html"
                    },
                    "view1@locker_options": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'LockerOptionsController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('locker_options_fill', {
                url: '/locker_options_fill',
                data: {
                    buttons: buttons.set12,
                    bread: breads["bread13"],
                    burned: 2,
                    lefttext:"lefttext.options_fill",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'reopen', actionCommand: 'reopen' },
                            { text: 'bigger', state: 'bigger_locker' },
                            { text: 'damaged', state: 'deliver_damaged_confirm' },
                            { text: 'stop_fill', state: 'deliver_abort_confirm'},
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@locker_options_fill": {
                        templateUrl: "views/templates/lefttext.html"
                    },
                    "view1@locker_options_fill": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'deliveryOptionsController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('empty_confirm', {
                url: '/empty_confirm',
                params: {parcel_id: 0},
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread2"],
                    burned: 2,
                    lefttext:"lefttext.empty_confirm",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'yes', actionCommand: 'empty' },
                            { text: 'no', state: 'locker_options' },
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@empty_confirm": {
                        templateUrl: "views/templates/lefttext.html"
                    },
                    "view1@empty_confirm": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'LockerOptionsController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('locker_empty', {
                url: '/locker_empty',
                data: {
                    buttons: buttons.set4,
                    bread: breads["bread2"],
                    maintext: 'maintext.locker_empty',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@locker_empty": {
                        templateUrl: "views/bighead/man_dog_bone.html"
                    },
                    "view1@locker_empty": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('deliver_options', {
                url: '/deliver_options',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread19"],
                    burned: 0,
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'scan_package', state: 'scan_package' },
                            { text: 'rent_locker', state: 'rent_customercode_input', enabled: 'losse_verhuur_enabled' },
                            { text: 'additional', state: 'additional' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@deliver_options": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@deliver_options": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'homeController'
                    }
                }
            })
            .state('deliver_abort_confirm', {
                url: '/deliver_abort_confirm',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread15"],
                    burned: 2,
                    lefttext:"lefttext.deliver_abort",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'yes', actionCommand: 'abort' },
                            { text: 'no', state: 'locker_options_fill' },
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@deliver_abort_confirm": {
                        templateUrl: "views/templates/lefttext.html"
                    },
                    "view1@deliver_abort_confirm": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'deliveryOptionsController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('deliver_abort_final', {
                url: '/deliver_abort_final',
                data: {
                    buttons: buttons.set4,
                    bread: breads["bread15"],
                    burned: 3,
                    maintext: 'maintext.deliver_abort_final'
                },
                views: {
                    "main": {
                        controller: "homeController",
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@deliver_abort_final": {
                        templateUrl: "views/bighead/man_dog_bone.html"
                    },
                    "view1@deliver_abort_final": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('deliver_damaged_final', {
                url: '/deliver_damaged_final',
                data: {
                    buttons: buttons.set4,
                    bread: breads["bread15"],
                    burned: 3,
                    maintext: 'maintext.deliver_damaged_final'
                },
                views: {
                    "main": {
                        controller: "homeController",
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@deliver_damaged_final": {
                        templateUrl: "views/bighead/man_dog_bone.html"
                    },
                    "view1@deliver_damaged_final": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('deliver_damaged_confirm', {
                url: '/deliver_damaged_confirm',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread15"],
                    burned: 2,
                    maintext: 'maintext.confirm_damaged',
                    titleTextButtons: {
                        title: 'title.confirm_damaged',
                        buttons: [
                            { text: 'yes', actionCommand: 'damaged' },
                            { text: 'no', state: 'locker_options_fill' },
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@deliver_damaged_confirm": {
                        templateUrl: "views/templates/text.html"
                    },
                    "view1@deliver_damaged_confirm": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'deliveryOptionsController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('scan_package', {
                url: '/scan_package',
                params: {avisage: false},
                data: {
                    buttons: buttons.set6,
                    bread: breads["bread5"],
                    burned: 1,
                    maintext: 'maintext.scan_package'
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@scan_package": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@scan_package": {
                        controller: "barcodeDeliveryController",
                        templateUrl: "views/templates/text.html"
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('scan_package_avisage', {
                url: '/scan_package_avisage',
                params: {avisage: true},
                data: {
                    buttons: buttons.avisageInput,
                    bread: breads["bread23"],
                    burned: 1,
                    maintext: 'maintext.scan_package'
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@scan_package_avisage": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@scan_package_avisage": {
                        controller: "barcodeDeliveryController",
                        templateUrl: "views/templates/text.html"
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('deliver_input', {
                url: '/deliver_input',
                params: {avisage: false},
                data: {
                    buttons: buttons.set7,
                    bread: breads["bread6"],
                    burned: 1,
                    input_1: { text: 'maintext.enter_deliverycode', type: 'text' }
                },
                views: {
                    "main": {
                        controller: 'barcodeDeliveryController',
                        templateUrl: "views/barcode_receiver_input.html"
                    }
                }
            })
            .state('deliver_input_avisage', {
                url: '/deliver_input_avisage',
                params: {avisage: true},
                data: {
                    buttons: buttons.set7,
                    bread: breads["bread6"],
                    burned: 1,
                    input_1: { text: 'maintext.enter_deliverycode', type: 'text' }
                },
                views: {
                    "main": {
                        controller: 'barcodeDeliveryController',
                        templateUrl: "views/barcode_receiver_input.html"
                    }
                }
            })
            .state('locker_choose', {
                url: '/locker_choose',
                data: {
                    buttons: buttons.set7,
                    bread: breads["bread8"],
                    burned: 1,
                    input_1: { text: 'maintext.locker_choose', type: 'text' },
                    input_2: { text: 'maintext.help', type: 'text'}
                },
                views: {
                    "main": {
                        templateUrl: "views/locker_choose.html",
                        controller: "rentLockerController"
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('days_choose', {
                params: { locker_number:'1111', locker_cost: '97' },
                url: '/days_choose',
                data: {
                    buttons: buttons.set7,
                    bread: breads["bread21"],
                    burned: 1,
                    input_1: { text: 'maintext.locker_choose', type: 'text' },
                    input_2: { text: 'maintext.help', type: 'text'},
                    lefttext:"lefttext.locker_howmany_days",
                    titleTextButtons: { title: 'maintext.make_choice' }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html",
                        controller: "rentDaysController"
                    },
                    "view0@days_choose": {
                        templateUrl: "views/templates/lefttext.html"
                    },
                    "view1@days_choose": {
                        templateUrl: "views/days_choose.html"
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('bigger_locker', {
                url: '/bigger_locker',
                params: { direct:false },
                data: {
                    buttons: buttons.set7,
                    bread: breads["bread8"],
                    burned: 1,
                    input_1: { text: 'maintext.locker_choose', type: 'text' },
                    input_2: { text: 'maintext.help', type: 'text'}
                },
                views: {
                    "main": {
                        templateUrl: "views/locker_choose.html",
                        controller: "biggerLockerController"
                    }
                }
            })
            .state('close_first', {
                url: '/close_first',
                data: {
                    buttons: buttons.set12,
                    bread: breads["bread8"],
                    burned: 1,
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.close_locker',
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html",
                        controller: 'closeLockerController'
                    },
                    "view0@close_first": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@close_first": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('locker_incorrect', {
                url: '/locker_incorrect',
                data: {
                    buttons: buttons.set5,
                    bread: breads["bread8"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.locker_incorrect',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@locker_incorrect": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@locker_incorrect": {
                        templateUrl: "views/templates/text.html"
//                        controller: 'mineController',
                    }
                }
            })
            .state('no_longer_available', {
                url: '/no_longer_available',
                data: {
                    buttons: buttons.set4,
                    bread: breads["bread10"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.no_longer_available',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@no_longer_available": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@no_longer_available": {
                        templateUrl: "views/templates/text.html"
//                        controller: 'mineController',
                    }
                }
            })
            .state('locker_unknown', {
                url: '/locker_unknown',
                data: {
                    buttons: buttons.set5,
                    bread: breads["bread8"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.locker_unknown',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@locker_unknown": {
                        templateUrl: "views/bighead/speak_arm_bend_man_small.html"
                    },
                    "view1@locker_unknown": {
                        templateUrl: "views/templates/text.html"
//                        controller: 'mineController',
                    }
                }
            })
            .state('locker_incomplete', {
                url: '/locker_incomplete',
                data: {
                    buttons: buttons.set5,
                    bread: breads["bread8"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.locker_incomplete',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@locker_incomplete": {
                        templateUrl: "views/bighead/speak_arm_bend_man_small.html"
                    },
                    "view1@locker_incomplete": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('locker_not_available', {
                url: '/locker_not_available',
                data: {
                    buttons: buttons.set4,
                    bread: breads["bread8"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.locker_not_available',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@locker_not_available": {
                        templateUrl: "views/bighead/speak_arm_bend_man_small.html"
                    },
                    "view1@locker_not_available": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('required_empty_expired_parcels', {
                url: '/required_empty_expired_parcels',
                data: {
                    buttons: buttons.set10,
                    bread: breads["bread10"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.required_empty_expired_parcels',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@required_empty_expired_parcels": {
                        templateUrl: "views/bighead/speak_arm_bend_man_small.html"
                    },
                    "view1@required_empty_expired_parcels": {
                        templateUrl: "views/templates/text.html",
                        controller: 'FirstEmptyScreenController'
                    }
                }
            })
            .state('required_login_to_fill', {
                url: '/required_login_to_fill',
                data: {
                    buttons: buttons.set10,
                    bread: breads["bread10"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.required_login_to_fill',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@required_login_to_fill": {
                        templateUrl: "views/bighead/speak_arm_bend_man_small.html"
                    },
                    "view1@required_login_to_fill": {
                        templateUrl: "views/templates/text.html",
                        controller: 'FirstLoginScreenController'
                    }
                }
            })
            .state('additional', {
                url: '/additional',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread20"],
                    burned: 1,
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'reopen', state: 'reopen' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@additional": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@additional": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'homeController'
                    }
                }
            })
            .state('same_barcode_options_external', {
                url: '/same_barcode_options_external',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread7"],
                    burned: 1,
                    titleTextButtons: {
                        text: 'maintext.same_barcode',
                        buttons: [
                            { text: 'last_locker', state: 'locker_options_fill' },
                            { text: 'new_locker', state: 'external_locker_choose' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@same_barcode_options_external": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@same_barcode_options_external": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'homeController'
                    }
                }
            })
            .state('same_barcode_options', {
                url: '/same_barcode_options',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread7"],
                    burned: 1,
                    titleTextButtons: {
                        text: 'maintext.same_barcode',
                        buttons: [
                            { text: 'last_locker', state: 'locker_options_fill' },
                            { text: 'new_locker', actionCommand: 'new_locker' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@same_barcode_options": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@same_barcode_options": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'sameBarcodeController'
                    }
                }
            })
            .state('mustpay', {
                url: '/mustpay',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread4"],
                    burned: 3,
                    lefttext:"lefttext.how_pay",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'bank_card', state: 'pay_by_bank', enabled: 'pin_enabled' },
                            { text: 'customer_card', actionCommand: 'pay_by_saldo' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@mustpay": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@mustpay": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'paymentMustPayController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('mustpay_a', {
                url: '/mustpay_a',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread4"],
                    burned: 3,
                    lefttext:"lefttext.how_pay",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'bank_card', state: 'pay_by_bank' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@mustpay_a": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@mustpay_a": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'paymentMustPayController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('rent_mustpay', {
                url: '/rent_mustpay',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread21"],
                    burned: 4,
                    lefttext:"lefttext.rent_how_pay",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'bank_card', state: 'rent_pay_by_bank', enabled: 'pin_enabled' },
                            { text: 'customer_card', actionCommand: 'pay_by_saldo' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@rent_mustpay": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@rent_mustpay": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'paymentMustPayController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('rent_mustpay_a', {
                url: '/rent_mustpay_a',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread21"],
                    burned: 4,
                    lefttext:"lefttext.rent_how_pay",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'bank_card', state: 'rent_pay_by_bank' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@rent_mustpay_a": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@rent_mustpay_a": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'paymentMustPayController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('payment_low_balance', {
                url: '/payment_low_balance',
                params: { current_balance: null, current_promotion: 0},
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread4"],
                    burned: 3,
                    lefttext:"lefttext.rent_payment_low_balance",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'upgrade_and_pay', actionCommand: 'upgrade_and_pay' },
                            { text: 'pay_by_bank', state: 'pay_by_bank', enabled: 'pin_enabled' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@payment_low_balance": {
                        templateUrl: "views/templates/lefttext.html",
                         controller: 'paymentCardLowController'
                    },
                    "view1@payment_low_balance": {
                        templateUrl: "views/templates/title_text_buttons.html"
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('upgrade_and_pay', {
                url: '/upgrade_and_pay',
                params: { minimal: null},
//                params: { minimal: 500},
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread4"],
                    burned: 3,
                    lefttext:"lefttext.charge_buren_card",
                    titleTextButtons: { title: 'maintext.make_choice' }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@upgrade_and_pay": {
                        templateUrl: "views/templates/lefttext.html"
                    },
                    "view1@upgrade_and_pay": {
                        templateUrl: "views/partials/charge.html",
                        controller: 'paymentChargeController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('rent_upgrade_and_pay', {
                url: '/rent_upgrade_and_pay',
//                params: { minimal: null},
                params: { minimal: 500},
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread21"],
                    burned: 4,
                    lefttext:"lefttext.charge_buren_card",
                    titleTextButtons: { title: 'maintext.make_choice' }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@rent_upgrade_and_pay": {
                        templateUrl: "views/templates/lefttext.html"
                    },
                    "view1@rent_upgrade_and_pay": {
                        templateUrl: "views/partials/charge.html",
                        controller: 'paymentChargeController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('locker_howmany_days', {
                url: '/locker_howmany_days',
                params: { minimal: 1},
                data: {
                    buttons: buttons.set7,
                    bread: breads["bread21"],
                    burned: 2,
                    lefttext:"lefttext.locker_howmany_days",
                    titleTextButtons: { title: 'maintext.make_choice' }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@locker_howmany_days": {
                        templateUrl: "views/templates/lefttext.html"
                    },
                    "view1@locker_howmany_days": {
                        templateUrl: "views/partials/days.html",
                        controller: 'paymentChargeController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('rent_payment_low_balance', {
                url: '/rent_payment_low_balance',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread21"],
                    burned: 4,
                    lefttext:"lefttext.rent_payment_low_balance",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'upgrade_and_pay', actionCommand: 'upgrade_and_pay' },
                            { text: 'pay_by_bank', state: 'pay_by_bank', enabled: 'pin_enabled' }
                        ]
                    }
                },
                params: { current_balance: null, current_promotion: 0},
                views: {
                    "main": {
                        templateUrl: "views/templates/2view48.html",
                        controller: 'paymentCardLowController'
                    },
                    "view0@rent_payment_low_balance": {
                        templateUrl: "views/templates/lefttext.html"
                    },
                    "view1@rent_payment_low_balance": {
                        templateUrl: "views/templates/title_text_buttons.html"
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('pay_by_bank', {
                url: '/pay_by_bank',
                params: { amount: null },
                data: {
                    bread: breads["bread4"],
                    maintext: 'maintext.follow_pin_terminal',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@pay_by_bank": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@pay_by_bank": {
                        templateUrl: "views/templates/text.html",
                        controller: "paymentRetrieveController"
                    }
                }
            })
            .state('rent_pay_by_bank', {
                url: '/rent_pay_by_bank',
                params: { amount: null },
                data: {
                    bread: breads["bread21"],
                    maintext: 'maintext.follow_pin_terminal',
                    burned: 4
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@rent_pay_by_bank": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@rent_pay_by_bank": {
                        templateUrl: "views/templates/text.html",
                        controller: "paymentRetrieveController"
                    }
                }
            })
            .state('payment_down', {
                url: '/payment_down',
                data: {
                    buttons: buttons.set5,
                    bread: breads["bread4"],
                    maintext: 'maintext.rent_payment_down',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@payment_down": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@payment_down": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('rent_payment_down', {
                url: '/rent_payment_down',
                data: {
                    buttons: buttons.set14,
                    bread: breads["bread21"],
                    maintext: 'maintext.rent_payment_down',
                    burned: 4
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@rent_payment_down": {
                        templateUrl: "views/bighead/man_arm_bend.html"
                    },
                    "view1@rent_payment_down": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('payment_failed', {
                url: '/payment_failed',
                data: {
                    buttons: buttons.set14,
                    bread: breads["bread4"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.rent_payment_failed',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@payment_failed": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@payment_failed": {
                        templateUrl: "views/templates/text.html",
                        controller: "paymentAfterMathController"
                    }
                }
            })
            .state('reopen_locker_later', {
                url: '/reopen_locker_later',
                params: { module_id:null, address_id: null, locker_number: null, parcel_id: null},
                data: {
                    buttons: buttons["set2"],
                    bread: breads["bread2"],
                    burned: 3,
                    lefttext:"lefttext.reopen_later",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'yes', actionCommand: 'yes' },
                            { text: 'no', actionCommand: 'no' },
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@reopen_locker_later": {
                        templateUrl: "views/templates/lefttext.html"
                    },
                    "view1@reopen_locker_later": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'reopenOptionsController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })

            .state('rent_payment_failed', {
                url: '/rent_payment_failed',
                data: {
                    buttons: buttons.set14,
                    bread: breads["bread21"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.rent_payment_failed',
                    burned: 4
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@rent_payment_failed": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@rent_payment_failed": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('payment_failure', {
                url: '/payment_failure',
                data: {
                    buttons: buttons.set14,
                    bread: breads["bread4"],
                    maintext: 'maintext.rent_payment_failure',
                    bigheadText: 'bighead.oops',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@payment_failure": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@payment_failure": {
                        templateUrl: "views/templates/text.html",
                        controller: "paymentAfterMathController"
                    }
                }
            })
            .state('rent_payment_failure', {
                url: '/rent_payment_failure',
                data: {
                    buttons: buttons.set14,
                    bread: breads["bread21"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.rent_payment_failure',
                    burned: 4
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@rent_payment_failure": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@rent_payment_failure": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('payment_correct', {
                url: '/payment_correct',
                data: {
                    buttons: buttons.set10,
                    bread: breads["bread4"],
                    bigheadText: 'bighead.thx',
                    maintext: 'maintext.rent_payment_correct',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@payment_correct": {
                        templateUrl: "views/bighead/speak_arm_bend_man_dog.html"
                    },
                    "view1@payment_correct": {
                        templateUrl: "views/templates/text.html",
                        controller: "paymentDoneController"
                    }
                }
            })
            .state('rent_payment_correct', {
                url: '/rent_payment_correct',
                data: {
                    buttons: buttons.set10,
                    bread: breads["bread21"],
                    bigheadText: 'bighead.thx',
                    maintext: 'maintext.rent_payment_correct',
                    burned: 4
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@rent_payment_correct": {
                        templateUrl: "views/bighead/speak_arm_bend_man_dog.html"
                    },
                    "view1@rent_payment_correct": {
                        templateUrl: "views/templates/text.html",
                        controller: "rentPaymentDoneController"
                    }
                }
            })
            .state('payment_correct_customer', {
                url: '/payment_correct_customer',
                params: { balance: null},
                data: {
                    buttons: buttons.set10,
                    bread: breads["bread4"],
                    bigheadText: 'bighead.thx',
                    maintext: 'maintext.payment_correctly',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@payment_correct_customer": {
                        templateUrl: "views/bighead/speak_arm_bend_man_dog.html"
                    },
                    "view1@payment_correct_customer": {
                        templateUrl: "views/templates/text.html",
                        controller: "paymentDoneController"
                    }
                }
            })
            .state('rent_payment_correct_customer', {
                url: '/rent_payment_correct_customer',
                params: { balance: null},
                data: {
                    buttons: buttons.set10,
                    bread: breads["bread21"],
                    bigheadText: 'bighead.thx',
                    maintext: 'maintext.payment_correctly',
                    burned: 4
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@rent_payment_correct_customer": {
                        templateUrl: "views/bighead/speak_arm_bend_man_dog.html"
                    },
                    "view1@rent_payment_correct_customer": {
                        templateUrl: "views/templates/text.html",
                        controller: "rentPaymentDoneController"
                    }
                }
            })
            .state('payment_upgrade_customercard', {
                url: '/payment_upgrade_customercard',
                params: { upgrade:'500', payment:'100', balance:'500' },
                data: {
                    buttons: buttons.set10,
                    bread: breads["bread4"],
                    bigheadText: 'bighead.thx',
                    maintext: 'maintext.rent_payment_upgrade_customercard',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@payment_upgrade_customercard": {
                        templateUrl: "views/bighead/speak_arm_bend_man_dog.html"
                    },
                    "view1@payment_upgrade_customercard": {
                        templateUrl: "views/templates/text.html",
                        controller: "paymentCustomercardController"
                    }
                }
            })
            .state('rent_payment_upgrade_customercard', {
                url: '/rent_payment_upgrade_customercard',
                params: { upgrade:'5', payment:'1', balance:'50' },
                data: {
                    buttons: buttons.set10,
                    bread: breads["bread21"],
                    bigheadText: 'bighead.thx',
                    maintext: 'maintext.rent_payment_upgrade_customercard',
                    burned: 4
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@rent_payment_upgrade_customercard": {
                        templateUrl: "views/bighead/speak_arm_bend_man_dog.html"
                    },
                    "view1@rent_payment_upgrade_customercard": {
                        templateUrl: "views/templates/text.html",
                        controller: "paymentCustomercardController"
                    }
                }
            })
            .state('pay_by_saldo', {
                url: '/pay_by_saldo',
                data: {
                    buttons: buttons.set10,
                    bread: breads["bread4"],
                    bigheadText: 'bighead.thx',
                    maintext: 'maintext.payment_correctly',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@pay_by_saldo": {
                        templateUrl: "views/bighead/speak_arm_bend_man_dog.html"
                    },
                    "view1@pay_by_saldo": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('please_wait', {
                url: '/please_wait',
                data: {
                    bread: breads["bread4"],
                    maintext: 'maintext.please_wait',
                    burned: 0
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@please_wait": {
                        templateUrl: "views/bighead/man_dog_bone.html"
                    },
                    "view1@please_wait": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('no_locker_available', {
                url: '/no_locker_available',
                data: {
                    buttons: buttons.set12,
                    bread: breads["bread19"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.no_locker_available',
                    burned: 0
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@no_locker_available": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@no_locker_available": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('avisage_impossible', {
                url: '/avisage_impossible',
                data: {
                    buttons: buttons.set4,
                    bread: breads["bread19"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.avisage_impossible',
                    burned: 0
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@avisage_impossible": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@avisage_impossible": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('avisage_delivery_postal', {
                url: '/avisage_delivery_postal',
                params: { location: '-' },
                data: {
                    buttons: buttons.set4,
                    bread: breads["bread19"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.avisage_delivery_postal',
                    burned: 0
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@avisage_delivery_postal": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@avisage_delivery_postal": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('avisage_retrieve_postal', {
                url: '/avisage_retrieve_postal',
                params: { location: '-' },
                data: {
                    buttons: buttons.set4,
                    bread: breads["bread1"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.avisage_retrieve_postal',
                    burned: 0
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@avisage_retrieve_postal": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@avisage_retrieve_postal": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('customercode_incorrect', {
                url: '/customercode_incorrect',
                data: {
                    buttons: buttons.set11,
                    bread: breads["bread21"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.customercode_incorrect',
                    burned: 0
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@customercode_incorrect": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@customercode_incorrect": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('rent_scan_again', {
                url: '/rent_scan_again',
                data: {
                    buttons: buttons.set12,
                    bread: breads["bread21"],
                    maintext: 'maintext.scan_again',
                    burned: 0
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@rent_scan_again": {
                        templateUrl: "views/bighead/man_dog_bone.html"
                    },
                    "view1@rent_scan_again": {
                        templateUrl: "views/templates/text.html"
//                        controller: 'mineController',
                    }
                }
            })
            .state('combination_incorrect', {
                url: '/combination_incorrect',
                data: {
                    buttons: buttons.set5,
                    bread: breads["bread21"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.customer_incorrect',
                    burned: 0
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@combination_incorrect": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@combination_incorrect": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('rent_locker_not_exist', {
                url: '/rent_locker_not_exist',
                data: {
                    buttons: buttons.set5,
                    bread: breads["bread21"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.rent_locker_not_exist',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@rent_locker_not_exist": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@rent_locker_not_exist": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('rent_locker_incomplete', {
                url: '/rent_locker_incomplete',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread21"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.rent_locker_incomplete',
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@rent_locker_incomplete": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@rent_locker_incomplete": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('rent_options', {
                url: '/rent_options',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread21"],
                    burned: 1,
                    lefttext:"lefttext.locker_not_available",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'small_locker', state: 'small_locker' },
                            { text: 'short_period', state: 'short_period' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@rent_options": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@rent_options": {
                        templateUrl: "views/templates/title_text_buttons.html"
                        //templateUrl: "views/scan_or_input.html"
                        // controller: 'homeController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('who_emptied', {
                url: '/who_emptied',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread21"],
                    burned: 3,
                    lefttext:"lefttext.who_emptied",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'me', actionCommand: 'empty_by_me' },
                            { text: 'someone', actionCommand: 'empty_by_someone' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@who_emptied": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@who_emptied": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'rentWhoController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('rent_information_sender', {
                url: '/rent_information_sender',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread21"],
                    burned: 3,
                    lefttext:"lefttext.rent_information_sender",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'mail', actionCommand: 'email' },
                            { text: 'mobile', actionCommand: 'mobile' },
                            { text: 'both', actionCommand: 'both' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@rent_information_sender": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@rent_information_sender": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'rentSenderInformationController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
	        .state('rent_information_receiver', {
                url: '/rent_information_receiver',
                data: {
                    buttons: buttons.set7,
                    bread: breads["bread21"],
                    burned: 3,
                    input_1: { text: 'maintext.enter_customercode_receive', type: 'text' },
                    anonymous_allowed: true
                },
                views: {
                    "main": {
                        templateUrl: "views/rent_customer_receiver_input.html",
                        controller: "rentReceiverInformationController"
                    }
                }
            })
            .state('rent_information_receiver_anonymous', {
                url: '/rent_information_receiver_anonymous',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread21"],
                    burned: 3,
                    lefttext:"lefttext.rent_information_receiver",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'mail', actionCommand: 'email' },
                            { text: 'mobile', actionCommand: 'mobile' },
                            { text: 'both', actionCommand: 'both' }
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@rent_information_receiver_anonymous": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@rent_information_receiver_anonymous": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'rentAnonymousReceiverInformationController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('incorrect_mail', {
                url: '/incorrect_mail',
                data: {
                    buttons: buttons.set13,
                    bread: breads["bread21"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.incorrect_mail',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@incorrect_mail": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@incorrect_mail": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('incorrect_mobile', {
                url: '/incorrect_mobile',
                data: {
                    buttons: buttons.set13,
                    params: { area_code:'06' },
                    bread: breads["bread21"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.incorrect_mobile',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@incorrect_mobile": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@incorrect_mobile": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('mail_not_equal', {
                url: '/mail_not_equal',
                data: {
                    buttons: buttons.set13,
                    bread: breads["bread21"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.mail_not_equal',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@mail_not_equal": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@mail_not_equal": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('mobile_not_equal', {
                url: '/mobile_not_equal',
                data: {
                    buttons: buttons.set13,
                    params: { area_code:'06' },
                    bread: breads["bread21"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.mobile_not_equal',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@mobile_not_equal": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@mobile_not_equal": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('customer_not_exist', {
                url: '/customer_not_exist',
                data: {
                    buttons: buttons.set18,
                    bread: breads["bread21"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.customer_not_exist',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html",
                        controller: "rentReceiverErrorController"
                    },
                    "view0@customer_not_exist": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@customer_not_exist": {
                        templateUrl: "views/templates/text.html",

                    }
                }
            })
            .state('customer_not_exist_3x', {
                url: '/customer_not_exist_3x',
                data: {
                    buttons: buttons.set9,
                    bread: breads["bread21"],
                    bigheadText: 'bighead.oops',
                    maintext: 'maintext.customer_not_exist_3x',
                    burned: 3
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html",
                        controller: "rentReceiverErrorController"
                    },
                    "view0@customer_not_exist_3x": {
                        templateUrl: "views/bighead/speak_arm_bend_man.html"
                    },
                    "view1@customer_not_exist_3x": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('rent_overview', {
                url: '/rent_overview',
                data: {
                    buttons: buttons.set7,
                    bread: breads["bread21"],
                    burned: 3,
                    lefttext:"lefttext.check_rent_overview",
                    titleTextButtons: {
                        buttons: [
                            { text: 'mail', state: 'mail' },
                            { text: 'mobile', state: 'mobile' },
                            { text: 'both', state: 'both'}
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@rent_overview": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@rent_overview": {
                        templateUrl: "views/rent_overview.html",
                        controller: 'rentOverviewController'
                    },
                    "view_extra_1": {
                        templateUrl: "views/bighead/doggy_bone.html"
                    }
                }
            })
            .state('notify_sender', {
                url: '/notify_sender',
                data: {
                    buttons: buttons.set2,
                    bread: breads["bread21"],
                    burned: 3,
                    lefttext:"lefttext.notify_sender",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'mail', actionCommand: 'email' },
                            { text: 'mobile', actionCommand: 'sms' },
                            { text: 'both', actionCommand: 'emailandsms'},
                            { text: 'none', actionCommand: 'none'}
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view48.html"
                    },
                    "view0@notify_sender": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@notify_sender": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'rentNotifyController'
                    }
                }
            })
            .state('notify_sender_external', {
                url: '/notify_sender_external',
                data: {
                    buttons: buttons.set2,
                    bread: breads["rent|type|information"],
                    burned: 3,
                    lefttext:"lefttext.notify_sender",
                    titleTextButtons: {
                        title: 'maintext.make_choice',
                        buttons: [
                            { text: 'mail', actionCommand: 'email' },
                            { text: 'none', actionCommand: 'none'}
                        ]
                    }
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view48.html"
                    },
                    "view0@notify_sender_external": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@notify_sender_external": {
                        templateUrl: "views/templates/title_text_buttons.html",
                        controller: 'deliveryNotifyController'
                    }
                }
            })
            .state('overview', {
                url: '/overview',
                data: {
                    buttons: buttons["set3"],
                    bread: breads["bread21"],
                    burned: 3,
                    lefttext:"lefttext.overview"
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@overview": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@overview": {
                        templateUrl: "views/rent_overview.html"
                    }
                }
            })
            .state('information', {
                url: '/information',
                data: {
                    buttons: buttons["set15"],
                    bigheadText: 'bighead.welcome'
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html"
                    },
                    "view0@information": {
                        templateUrl: "views/bighead/speak_arm_bend_man_large.html"

                    },
                    "view1@information": {
                        templateUrl: "views/information.html"
                    }
                }
            })
            .state('help1', {
                url: '/help1',
                data: {
                    buttons: buttons["set16"],
                    bread: breads["bread21"],
                    burned: 1,
                    lefttext:"lefttext.help1"
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@help1": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@help1": {
                        templateUrl: "views/help.html",
                        controller: "rentHelpController"
                    }
                }
            })
            .state('help2', {
                url: '/help2',
                data: {
                    buttons: buttons["set16"],
                    bread: breads["bread21"],
                    burned: 1,
                    lefttext:"lefttext.help2"
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@help2": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@help2": {
                        templateUrl: "views/help.html",
                        controller: "rentHelpController"
                    }
                }
            })
            .state('help3', {
                url: '/help3',
                data: {
                    buttons: buttons["set16"],
                    bread: breads["bread21"],
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/help.html",
                        controller: "rentHelpController"
                    }
                }
            })
            .state('help4', {
                url: '/help4',
                data: {
                    buttons: buttons["set16"],
                    bread: breads["bread21"],
                    burned: 1,
                    lefttext:"lefttext.help4"
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@help4": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@help4": {
                        templateUrl: "views/help.html",
                        controller: "rentHelpController"
                    }
                }
            })
            .state('help5', {
                url: '/help5',
                data: {
                    buttons: buttons["set16"],
                    bread: breads["bread21"],
                    burned: 1,
                    lefttext:"lefttext.help5"
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@help5": {
                        templateUrl: "views/templates/lefttext.html"

                    },
                    "view1@help5": {
                        templateUrl: "views/help.html",
                        controller: "rentHelpController"
                    }
                }
            })

            .state('external_locker_choose', {
                url: '/external_locker_choose',
                data: {
                    buttons: buttons.set7,
                    bread: breads["bread24"],
                    burned: 1,
                    input_1: { text: 'maintext.locker_choose', type: 'text' },
                    input_2: { text: 'maintext.help', type: 'text'}
                },
                views: {
                    "main": {
                        templateUrl: "views/locker_choose.html",
                        controller: "externalDeliveryController"
                    }
                }
            })
	        .state('locker_external_delivery_opened', {
                url: '/locker_external_delivery_opened',
                params: { locker_number:'00.00' },
                data: {
                    buttons: buttons.set4,
                    bread: breads["bread8"],
                    burned: 2,
                    bigheadText: 'bighead.thankyou',
                    maintext: 'maintext.locker_opened'
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/warning.html",
                        controller: "openLockerController"
                    },
                    "view0@locker_external_delivery_opened": {
                        templateUrl: "views/bighead/speak_arm_bend.html"
                    },
                    "view1@locker_external_delivery_opened": {
                        templateUrl: "views/templates/text.html"
                    }
                }
            })
            .state('sweep-login', {
                url: '/sweep/login',
                data: {
                    buttons: buttons.set9,
                    bread: breads.sweep,
                    burned: 0,
                    input_1: { text: 'sweep.login_info', type: 'text' },
                    login_incorrect: 'sweep.login_incorrect'
                },
                views: {
                    "main": {
                        templateUrl: "views/sweep/login.html",
                        controller: "sweepLoginController"
                    }
                }
            })
            .state('sweep-list', {
                url: '/sweep/list',
                data: {
                    buttons: buttons.set12,
                    bread: breads.sweep,
                    burned: 1,
                    welcome: 'sweep.welcome',
                    maintext: 'sweep.module_info'
                },
                views: {
                    "main": {
                        templateUrl: "views/sweep/list.html",
                        controller: "sweepListController"
                    }
                }
            })
            .state('sweep-opened', {
                url: '/sweep/opened',
                params: { module: false },
                data: {
                    buttons: buttons.sweep,
                    bread: breads.sweep,
                    burned: 2,
                    maintext: 'sweep.locker_info'
                },
                views: {
                    "main": {
                        templateUrl: "views/sweep/list.html",
                        controller: "sweepOpenedController"
                    }
                }
            })
            .state('test', {
                url: '/test',
                data: {
                    buttons: buttons.set1,
                    bread: breads["take"],
                    bigheadText: 'bighead.welcome',
                    maintext:"maintext.emptyone",
                    burned: 1
                },
                views: {
                    "main": {
                        templateUrl: "views/templates/2view.html"
                    },
                    "view0@test": {
                        templateUrl: "views/bighead/speak.html"
                    },
                    "view1@test": {
                        templateUrl: "views/mine/new.html",
                        controller: 'mineController'
                    }
                }
            });
     }
 ]);
