(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/bighead/info.html',
    '<div ng-controller="helpController" style="position:absolute; z-index:2; top:-50px; right:150px" draggable="true"><div ng-click="clickItem()" class="helpbutton" translate>{{\'button.info\'}}</div></div>');
}]);
})();
