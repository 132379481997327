(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/templates/2viewLarge.html',
    '<div class="row"><div class="col-md-4" ui-view="view0"></div><div class="show-deburen"><div class="col-md-1"></div></div><div ng-hide="" class="col-md-7" ui-view="view1"></div></div>');
}]);
})();
