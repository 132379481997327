var mainApp = angular.module('MainApp');

mainApp.controller('closeLockerController', function($scope, $rootScope, $state, store, listener) {
    listener($scope, {
        handleHardwareMessage: function(event){
            if( event == 'locker_closed')
                $state.go("bigger_locker", {direct: true});
        }
    });
});

mainApp.controller('openLockerController', function($scope, $rootScope, $state, store, listener, toolkit, hardware,
                                                    DHL_PARTNER_ID, BPOST_PARTNER_ID, $translate) {
    var reopenCallback = false;

    var listeners = {
        handleHardwareMessage: function(event){
            if(reopenCallback && event == 'locker_closed')
                reopenCallback();
        },
        handleFooterButton: function(actionCommand){
            if(store.getLastOpenState()) {
                $state.go("close_first");
            } else {
                if (actionCommand == 'biggerlocker') {
                    $state.go("bigger_locker", {direct: true});
                }
            }
        }
    };
    console.log("Open locker $state.params.autoopen", $state.params.autoopen);
    if($state.params.autoopen){
        var parcels = store.getParcels();
        var p = store.getParcel();
        var parcel = false;
        for(var i=0;i<parcels.length-1&&!parcel;++i){
            if(parcels[i]==p){
                parcel = parcels[i + 1];
            }
        }
        if(parcel){
            reopenCallback = function(){
                var message = '';
                if(parcel['not_collected']) {
                    message = '"' + $translate.instant('maintext.message_not_pickedup') + '"';
                }
                var next_locker = $translate.instant('maintext.message_next_locker_transporter');
                $scope.openLocker(parcel, message, next_locker);
            }
        } else {
            listeners['homeTimeout'] = 10*1000;
        }
    } else {
        listeners['homeTimeout'] = 10*1000;
    }

    listener($scope, listeners);

    var init = function() {
        // Only for DHL
        if ($state.$current.name=="locker_opened"&&$state.params.delivery_partner_id == DHL_PARTNER_ID ||
        $state.$current.name=="locker_opened"&&$state.params.delivery_partner_id == BPOST_PARTNER_ID) {
            $rootScope.$emit("footerburen.button.add", "biggerlockerr");
        } else {
            $rootScope.$emit("footerburen.button.remove", "biggerlockerr");
        }
    };

    $scope.openLocker = function(parcel, message_status, next_locker) {
        store.setParcel(parcel);
        if(parcel.sos){
            $state.go('transporter_sos', {message_status: message_status, next_locker: next_locker, autoopen: true});
        }else {
            var process = store.getProcess();
            var locker_data = {parcel_id: parcel.parcel_id};
            var deliveryType = 'retrieval';
            if (process.type) {
                locker_data.opentype = process.opentype;
                deliveryType = 'delivery';
            }
            hardware.openLocker(parcel.module_id, parcel.address_id, deliveryType, locker_data).then(function (data) {
                if (data) { // Redirect
                    var locker_number = toolkit.formatLockerNumber(parcel.locker_number);
                    $state.go('locker_opened', { show_bigger_locker: 0, locker_number: locker_number,
                        message_status: message_status, message_next_locker: next_locker, autoopen: true});
                } else {
                    // TODO
                    console.log(data);
                }
            }, function (data) {
                // TODO
                console.log(data);
            });
        }
    };

    init();

});