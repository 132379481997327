var listenerModule = angular.module('listenerModule', ['rentModule']);

listenerModule.provider('listener', function() {
    var timeoutTime = 10 * 60 * 1000;
    var timeout = null;
    var state;
    var timeoutModule;
    var _toolkit;
    handleTimeout = function(){
        if(timeout)
            timeoutModule.cancel(timeout);
        if(timeoutTime && state.$current.name != 'home'){
            timeout = timeoutModule(function(){
                _toolkit.close();
            }, timeoutTime, 1);
        }
    };
    handleReload = function(){
        _toolkit.close_soft();
    };

    this.$get = function ($rootScope, $interval, $state, connection, rent, toolkit, hardware) {
        _toolkit = toolkit;
        timeoutModule = $interval;
        state = $state;
        $rootScope.$on('$destroy',
            $rootScope.$on('$stateChangeSuccess', function(){
                handleTimeout();
            })
        );
        $rootScope.$on('$destroy',
            $rootScope.$on('logdump', function(dump){
                hardware.logdump(dump);
            })
        );
        $rootScope.$on('$destroy',
            $rootScope.$on('hardware.payupdate', function(){
                handleTimeout();
            })
        );
        $rootScope.$on('$destroy',
            $rootScope.$on('hardware.reload', function(){
                handleReload();
            })
        );
        $rootScope.$on('$destroy',
            $rootScope.$on('direction_handler', function(event, direction){
                if(direction=="back"){
                    rent.goBack();
                }
            })
        );
        return function(scope, functions){
            if(functions.handleFooterButton){
                scope.$on('$destroy',
                    $rootScope.$on('footerburen.button.clicked', function(event, actionCommand){
                        functions.handleFooterButton(actionCommand);
                    })
                );
            }
            if(functions.breadOverride){
                scope.$on('$destroy',
                    $rootScope.$on('bread.override', function(event, breads){
                        functions.breadOverride(breads);
                    })
                );
            }

            if(functions.debugSignal){
                scope.$on('$destroy',
                    $rootScope.$on('buren.debug', function(event, actionCommand){
                        functions.debugSignal(actionCommand);
                    })
                );
            }

            if(functions.handleWaitingEvents){
                scope.$on('$destroy',
                    $rootScope.$on('buren.wait', function(event, wait){
                        functions.handleWaitingEvents(wait);
                    })
                );
            }

            if(functions.handleDirectionEvent){
                scope.$on('$destroy',
                    $rootScope.$on('direction_handler', function(event, direction){
                        functions.handleDirectionEvent(direction);
                    })
                );
            }
            if(functions.handleActionButton){
                scope.$on('$destroy',
                    $rootScope.$on('actionButtons.button.clicked', function(event, actionCommand){
                        functions.handleActionButton(actionCommand);
                    })
                );
            }
            if(functions.handleBarcodeEvent){
                console.log("add handler barcode", functions);
                scope.$on('$destroy',
                    $rootScope.$on('hardware.barcode', function(event, barcode){
                        functions.handleBarcodeEvent(barcode);
                    })
                );
            }
            if(functions.handleHardwareMessage){
                console.log("add handleHardwareMessage", functions);
                scope.$on('$destroy',
                    $rootScope.$on('hardware.hardware_message', function(event, message){
                        functions.handleHardwareMessage(message);
                    })
                );
            }
            if(functions.stateChangeSuccess){
                scope.$on('$destroy',
                    $rootScope.$on('$stateChangeSuccess', function(){
                        functions.stateChangeSuccess();
                    })
                );
            }
            if(functions.footerButtonsRemove){
                scope.$on('$destroy',
                    $rootScope.$on('footerburen.button.remove', function(event, toRemove){
                        functions.footerButtonsRemove(toRemove);
                    })
                );
            }
            if(functions.footerButtonsAdd){
                scope.$on('$destroy',
                    $rootScope.$on('footerburen.button.add', function(event, toAdd){
                        functions.footerButtonsAdd(toAdd);
                    })
                );
            }
            if(functions.handleWebsocket){
                scope.$on('$destroy',
                    $rootScope.$on('hardware.websocket', function(event, result){
                        functions.handleWebsocket(result);
                    })
                );
            }
            if(functions.sweepLoginState){
                scope.$on('$destroy',
                    $rootScope.$on('hardware.sweep', function(){
                        functions.sweepLoginState();
                    })
                );
            }
            timeoutTime = 10 * 60 * 1000;
            if(functions.homeTimeout){
                timeoutTime = functions.homeTimeout;
            }
            handleTimeout();
        };
    };
});