var mainApp = angular.module('MainApp');

mainApp.controller('homeController', function($scope, $state, connection, listener, store, toolkit, $element) {
    listener($scope, {
        handleBarcodeEvent: function(barcode){
            connection("/api/scan", {code: barcode}).then(function(data){
                if(data.type=="customer"){
                    if(data.loggedin){
                        store.setCustomer(data.customer);
                        $state.go('my_lockers');
                    }else{
                        $state.go("customercode_input", {user: barcode, type:data.type})
                    }
                }else if(data.type=="driver"){
                    if(data.loggedin){
                        store.setDriver(data.driver, $state);
                    }else{
                        $state.go("customercode_input", {user: barcode, type:data.type})
                    }
                }else if(data.type=="receive"){
                    store.setAnonymousReceiveBarcode(barcode);
                    $state.go("my_lockers");
                }else if(data.type=="delivery"){
	                data.barcode = $scope.barcode;
                    toolkit.handleDeliveryFound(data);
                }else if(data.type=="failed_avisage"){
                    toolkit.handleFailedAvisageFound(data);
                }else{
                    $state.go('delivery_incorrect');
                }
            });
        },
        sweepLoginState: function() {
            $state.go('sweep-login');
        }
    });
});