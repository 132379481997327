var mainApp = angular.module('MainApp');

mainApp.controller('rentHelpController', function($scope, $state, $stateParams, listener, rent) {
    $scope.location = window.location;
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if(actionCommand=="continue"){
                if(rent.hasStarted()){
                    rent.incrHelp();
                    rent.next($state);
                }else{
                    var state = parseInt($state.current.name.substr(-1), 10) + 1;
                    if(state>5){
                        $state.go('external_locker_choose');
                    }else{
                        $state.go('help' + state);
                    }
                }
            }
        }
    });
});

mainApp.controller('rentLockerController', function($scope, $rootScope, $state, $stateParams, connection, store,
                    listener, hardware, rent) {

    $scope.settings = store.getTerminalConfig();

    rent.checkStart();
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if (actionCommand == 'continue') {
                chooseLocker();
            }
        }
    });

    $scope.clickButton = function(){
        rent.incrHelp();
        rent.next($state);
    }

	$scope.$state = $state;

    $scope.input_sub = ['','','','']
	$scope.input = { value_1: ''};
	var active_field = 'value_1';
    var module = true;


    $scope.shouldBeDisabled = true;

    if ($scope.settings) {
        console.log($scope.settings);
        if ($scope.settings.type_locker == 0){
            $scope.shouldBeDisabled = false;
            module = false;
        }
    }

	$scope.keyboard_input = function(value) {

        if(module === false){
            value = '00' + value;
        }

        console.log(value);

        if (active_field == 'value_1') {
		    if(value.length>4)
		        value = value.substr(0, 4);
			$scope.input[active_field] = value.toUpperCase();
			for(var i=0;i<4;++i){

                if(module === false && i === 0 || module === false && i === 1){
                    $scope.input_sub[i] = 0;
                }else{
                    $scope.input_sub[i] = i<value.length?value.substr(i,1):"";
                }
			}
		} else {
			$scope.input[active_field] = value;
		}
	};

	var chooseLocker = function() {
	    if($scope.input.value_1.length==4){
            connection("/api/deliver/locker_cost", {locker_number: $scope.input.value_1}).then(function(result){
                if(result){
                    rent.pickLocker($scope.input.value_1, result);
                    rent.next($state);
                }else{
                    rent.goError("locker_unknown", $state);
                }
            }, function(){
                rent.goError("locker_unknown", $state);
            });
        }else{
            rent.goError("locker_incomplete", $state);
        }
	};
});


mainApp.controller('rentDaysController', function($scope, connection, $state, store, toolkit, hardware,
                    listener, $filter, rent) {
    rent.checkStart();
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if (actionCommand == 'continue') {
                connection("/api/deliver/reserve_locker", {locker_number: locker_number}).then(function(result){
                    if(result.type=="success"){
                        rent.setReservedLocker(result.locker, $scope.totalRent, $scope.days);
                        rent.next($state);
                    }else if(result.type=="smaller"){
                        $state.go('locker_not_available');
                    }else if(result.type=="no_empty"){
                        $state.go('no_locker_available');
                    }else{
                        $state.go("locker_unknown");
                    }
                });
            }
        }
    });
    var locker_number = rent.getChosenLocker();
    var cost = rent.getLockerCost();
    if(locker_number==null)
        toolkit.close();
    $scope.days = 1;
    $scope.cannot_less = function(){
        return $scope.days <= 1;
    };
    $scope.startDate = Date.now();
    rent.setDays(1);
    $scope.endDate = function(){
        return rent.getEndDate();
    };
    $scope.totalRent = cost;
    $scope.subtract = function(){
        $scope.days -= 1;
        rent.setDays($scope.days);
        $scope.totalRent = cost * $scope.days;
    };
    $scope.add = function(){
        $scope.days += 1;
        rent.setDays($scope.days);
        $scope.totalRent = cost * $scope.days;
    };
});

mainApp.controller('rentWhoController', function($scope, $state, store, listener, rent) {
    rent.checkStart();
    listener($scope, {
        handleActionButton: function(actionCommand){
            rent.setPickupSelf(actionCommand == 'empty_by_me');
            rent.next($state);
        }
    });
});

mainApp.controller('rentMailController', function($scope, $state, store, listener, $rootScope, rent) {
    rent.checkStart();
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if (actionCommand == 'next') {
                nextClicked();
            } else if (actionCommand == 'backForm') {
                backClicked();
            } else if (actionCommand == 'continue') {
                submit();
            }
        }
    });
	$scope.keyboard_input = function(value) {
        $scope.input[active_field] = value;
	};
    $scope.$state = $state;
    $scope.input = { value_1: '', value_2: '' };
    var active_field = 'value_1';
    var backClicked = function() {
        active_field = 'value_1';

        $rootScope.$emit("keyboard.set", { value: $scope.input[active_field] });

        $rootScope.$emit("footerburen.button.add", "nextr");
        $rootScope.$emit("footerburen.button.remove", "continuer");
        $rootScope.$emit("footerburen.button.add", "backl");
        $rootScope.$emit("footerburen.button.remove", "back2");

        angular.element('#customercode').show();
        angular.element('#scan_customercode').html('Je kunt je klantnummer ook scannen');
        angular.element('#txtCustomercode').removeAttr('disabled');
        angular.element('#txtCustomercode_1').removeAttr('disabled');
        angular.element('#txtCustomercode').removeClass('input-disabled');
        angular.element('#txtCustomercode_1').removeClass('input-disabled');
        angular.element('#txtScanCustomercode').prop('disabled', 'disabled');
        angular.element('#txtScanCustomercode').addClass('input-disabled');

    };

    var nextClicked = function() {
        $rootScope.$emit("keyboard.reset");

        active_field = 'value_2';

        $rootScope.$emit("footerburen.button.remove", "nextr");
        $rootScope.$emit("footerburen.button.add", "continuer");
        $rootScope.$emit("footerburen.button.remove", "backl");
        $rootScope.$emit("footerburen.button.add", "back2");

        angular.element('#txtCustomercode').prop('disabled', 'disabled');
        angular.element('#txtCustomercode_1').prop('disabled', 'disabled');
        angular.element('#txtCustomercode').addClass('input-disabled');
        angular.element('#txtCustomercode_1').addClass('input-disabled');
        angular.element('#customercode').hide();
//        angular.element('#scan_customercode').html('Vul je geheime code in');
        angular.element('#txtScanCustomercode').removeAttr('disabled');
        angular.element('#txtScanCustomercode').removeClass('input-disabled');
    };

    var submit = function() {
        // todo check mail validity;
        if($scope.input.value_1==$scope.input.value_2){
            if(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    .test($scope.input.value_1)){
                rent.setMail($scope.input.value_1);
                rent.next($state);
            }else{
                $state.go('incorrect_mail');
            }
        }else{
            $state.go('mail_not_equal');
        }
    };
});

mainApp.controller('rentMobileController', function($scope, $state, store, listener, $rootScope, rent, $translate) {
    rent.checkStart();
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if (actionCommand == 'next') {
                nextClicked();
            } else if (actionCommand == 'backForm') {
                backClicked();
            } else if (actionCommand == 'continue') {
                submit();
            }
        }
    });
    $scope.keyboard_input = function(value) {
        //$scope.input[active_field] = '06'+value;
        $scope.input[active_field] = value;
    };
    $scope.$state = $state;
    $scope.country = 'nl';
    console.log($scope.country);
    // TODO: For some countries you do not want to show the prefix, because there are multiple
    // mobile prefixes, so maybe hide the mobile prefix?
    $scope.mobile_prefix = function(){
        $result = '';
        switch($scope.country){
            case 'nl':
                $result = '06';
                break;
            case 'be':
                $result = '4';
                break;
            case 'de':
                $result = 'xx';
                break;
        }
        return $result;
    };
    //$scope.input = { value_1: '06', value_2: '06' };
    $scope.input = { value_1: '', value_2: '' };
    var active_field = 'value_1';
    var backClicked = function() {
        active_field = 'value_1';

        $rootScope.$emit("keyboard.set", { value: $scope.input[active_field] });

        $rootScope.$emit("footerburen.button.add", "nextr");
        $rootScope.$emit("footerburen.button.remove", "continuer");
        $rootScope.$emit("footerburen.button.add", "backl");
        $rootScope.$emit("footerburen.button.remove", "back2");

        angular.element('#countrycode').show();
        angular.element('#mobilenr2').hide();
        angular.element('#mobilenr1').show();
        angular.element('#mobilenr_label2').hide();
        angular.element('#mobilenr_label1').hide();
        angular.element('#countrypicker').removeClass('input-disabled');
        angular.element('#countrypicker').removeAttr('disabled');
    };

    var nextClicked = function() {
        $rootScope.$emit("keyboard.reset");

        active_field = 'value_2';

        $rootScope.$emit("footerburen.button.remove", "nextr");
        $rootScope.$emit("footerburen.button.add", "continuer");
        $rootScope.$emit("footerburen.button.remove", "backl");
        $rootScope.$emit("footerburen.button.add", "back2");

        // $translate(['maintext.enter_confirm_mobile'])
        //     .then(function (translation) {
        //         angular.element('#countrypicker').prop('disabled', 'disabled');
        //         console.log(translation['maintext.enter_confirm_mobile']);
        //     });

        angular.element('#countrycode').hide();
        angular.element('#countrypicker').prop('disabled', 'disabled');
        angular.element('#countrypicker').addClass('input-disabled');
        angular.element('#mobilenr1').hide();
        angular.element('#mobilenr2').show();
        angular.element('#mobilenr_label1').show();
        //angular.element('#mobilenr_label2').show();
    };

    var submit = function() {
        // todo check mail validity;
        if($scope.input.value_1===$scope.input.value_2){
            var number = phoneUtils.formatE164($scope.input.value_1, $scope.country);
            if(number.length>0){
                rent.setMobile(number);
                rent.next($state);
            }else{
                $state.go("incorrect_mobile");
            }
        }else{
            $state.go('mobile_not_equal');
        }
    };
});

mainApp.controller('rentNotifyController', function($scope, $state, store, listener, rent) {
    rent.checkStart();
    listener($scope, {
        handleActionButton: function(actionCommand){
            rent.setSenderNotify(actionCommand);
            rent.next($state);
        }
    });
});


mainApp.controller('rentSenderInformationController', function($scope, $state, store, listener, rent) {
    rent.checkStart();
    listener($scope, {
        handleActionButton: function(actionCommand){
            rent.setSenderInformationType(actionCommand);
            rent.next($state);
        }
    });
});

mainApp.controller('rentAnonymousReceiverInformationController', function($scope, $state, store, listener, rent) {
    rent.checkStart();
    listener($scope, {
        handleActionButton: function(actionCommand){
            rent.setReceiverInformationType(actionCommand);
            rent.next($state);
        }
    });
});



mainApp.controller('rentReceiverErrorController', function($scope, $state, listener, rent) {
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if(actionCommand=="continue"||actionCommand=="nocustomer"){
                rent.fromErrorToAnonymous($state);
            }
        }
    });
});

mainApp.controller('rentReceiverInformationController', function($scope, $state, store, listener, rent, connection) {
    rent.checkStart();
    $scope.settings = store.getTerminalConfig();

    if($scope.settings.pin_is_live == 0){
        angular.element('#anonymous-button').hide();
    }

    $scope.anonymous_clicked = function(){
        rent.useUserReceiver(null);
        rent.next($state);
    }
    angular.element('#customercode').show();
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if(actionCommand=="continue"){
                connection("/api/deliver/user_exists", {"user":  $scope.input[active_field]}).then(function(result){
                    if(result){
                        rent.useUserReceiver($scope.input[active_field]);
                        rent.next($state);
                    }else{
                        if(rent.toManyWrong()){
                            rent.goError("customer_not_exist_3x", $state);
                        }else{
                            rent.goError("customer_not_exist", $state);
                        }
                    }
                }, function(){
                    if(rent.toManyWrong()){
                        rent.goError("customer_not_exist_3x", $state);
                    }else{
                        rent.goError("customer_not_exist", $state);
                    }
                });
            }
        }
    });

    $scope.$state = $state;

    $scope.keyboard_input = function(value) {
        $scope.input[active_field] = value.toUpperCase();
    };
    $scope.input = { value_1: '', value_2: '' };
    var active_field = 'value_1';
});

mainApp.controller('rentOverviewController', function($scope, $state, store, listener, rent, toolkit) {
    $scope.settings = store.getTerminalConfig();
    rent.checkStart();
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if(actionCommand=="continue"){
                rent.setPayment(true);
                rent.next($state);
            }
        }
    });
    console.log(rent.getFrom());
    $scope.from = rent.getFrom();

    $scope.getChosenLocker = toolkit.formatLockerNumber(rent.getReservedLocker().locker_number);
    $scope.getLockerCost = rent.getLockerCost();
    $scope.getTotalCost = rent.getTotalCost()/100;
    $scope.getReservedLocker = rent.getReservedLocker();
    $scope.getNotification = rent.getNotification();

    switch($scope.getNotification){
        case 'email':
            $scope.notification = 'Ja, per e-mail';
            break;
        case 'sms':
            $scope.notification = 'Ja, per sms';
            break;
        case 'emailandsms':
            $scope.notification = 'Ja, per e-mail en sms';
            break;
        case 'none':
            $scope.notification = '-';
            break;
    }

    $scope.state = rent.getState();
    $scope.getReceiver = rent.getReceiver();
    $scope.endDate = rent.getEndDate();
    $scope.startDate = Date.now();
    $scope.getObject = rent.getObject();
    console.log(rent.getObject());


});


mainApp.controller('rentPaymentDoneController', function($scope, $state, store, listener, rent, connection, $filter,
        $stateParams) {
    rent.checkStart();
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if(actionCommand=='continue'){
                rent.isPayed($state, connection, store);
            }
        }
    });
    $scope.translate_values = {};
    $scope.translate_values.balance = $filter('toCurrency')($stateParams.balance);
});