var mainApp = angular.module('MainApp');

mainApp.controller('defaultController', function($scope, $state, $rootScope, store) {
	$scope.$state = $state;

    $scope.getButtons = function() {

        $scope.settings = store.getTerminalConfig();

        var buttons = [];

        for (i in $state.current.data.titleTextButtons.buttons) {

            var button = $state.current.data.titleTextButtons.buttons[i];

            if(!button.enabled||(button.enabled=='losse_verhuur_enabled'&&$scope.settings.losse_verhuur == 1)||
                    (button.enabled=='pin_enabled'&&$scope.settings.pin_is_live == 1)){
                buttons.push(button);
            }
        }
        return buttons;
    };

	$scope.clickButton = function(btn){
	    console.log("btn", btn);

		if (btn.state) {
		    if(btn.params){
		        $state.go(btn.state, btn.params);
		    }else{
			    $state.go(btn.state);
		    }
		} else if (btn.href) {
			document.location = btn.href;
		} else if (btn.actionCommand) {
            $rootScope.$emit("actionButtons.button.clicked", btn.actionCommand);
        }else{
			console.log('TODO: call what?!');
		}

		//$rootScope.$emit("default.button.clicked", actionCommand);
	};
});