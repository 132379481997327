(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/my_lockers_transporter.html',
    '<div><div class="row"><div class="col-md-12"><p class="txt-buren1-welcome" translate translate-values="{ title: driver.name }">{{\'transportertext.dear\'}}</p><p class="txt-buren1-parcels" translate>{{\'transportertext.select_locker_option\'}}</p></div></div><div><div ng-if="parcels.length > 0"><div class="row"><div ng-repeat="col_index in [start_parcel_index, start_parcel_index+4, start_parcel_index+8, start_parcel_index+12]" class="col-md-3"><div ng-repeat="parcel_index in [col_index, col_index+1, col_index+2, col_index+3]"><button ng-if="(parcel_index < parcels.length)" ng-click="lockerOptions(parcels[parcel_index]);" ng-disabled="pay.mustpay" class="btn btn-buren1 btn-block" style="text-align:center; width:90%; padding:6px 12px"><span>{{parcels[parcel_index].locker_number | formatLockerNumber}}</span> <i class="glyphicon glyphicon-triangle-right pull-right"></i></button></div></div></div><div ng-if="parcels.length > max_items_on_page" class="row"><div class="col-md-offset-6 col-md-6" style="text-align:center; margin-top: 15px"><button class="btn btn-buren3 pull-left" ng-click="nav(start_parcel_index - max_items_on_page);" ng-disabled="(start_parcel_index == 0)" style="font-size:26px !important"><i style="top: 5px" class="glyphicon glyphicon-triangle-left"></i> <span>{{\'maintext.previous_page\' | translate}}</span></button> <span style="display:inline-block; font-size:26px; line-height:56px" translate translate-values="{ start_nr:(start_parcel_index + 1), stop_nr:(start_parcel_index + parcels_on_page.length) }">{{\'maintext.locker_from_to\'}}</span> <button class="btn btn-buren3 pull-right" ng-click="nav(start_parcel_index + max_items_on_page);" ng-disabled="(start_parcel_index + max_items_on_page) >= parcels.length" style="font-size:26px !important"><span>{{\'maintext.next_page\' | translate}}</span> <i style="top: 5px" class="glyphicon glyphicon-triangle-right"></i></button></div></div></div></div></div>');
}]);
})();
