(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/bighead/doggy.html',
    '<div class="show-bighead" ng-controller="blankController" style="position:absolute; z-index:2; top:-120px; left:200px" draggable="true"><img src="images/deburen_graphics/dog_2.png" style="position:absolute; width:150px; top:25px" draggable="false"></div>');
}]);
})();
