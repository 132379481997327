var mainApp = angular.module('MainApp');

mainApp.controller('helpController', function($scope, $state, $stateParams, listener, rent) {
    $scope.$state = $state;
	$scope.$stateParams = $stateParams;
    $scope.location = window.location;
    $scope.clickItem = function(){
        $state.go('information');
    };
    $scope.clickButton = function(){
        rent.incrHelp();
        rent.next($state);
    };
});