(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/partials/numeric_keypad.html',
    '<div class="num"><div class="row"><div class="col-md-12"><button class="btn btn-numeric" ng-click="keypress(\'1\')">1</button> <button class="btn btn-numeric" ng-click="keypress(\'2\')">2</button> <button class="btn btn-numeric" ng-click="keypress(\'3\')">3</button></div></div><div class="row"><div class="col-md-12"><button class="btn btn-numeric" ng-click="keypress(\'4\')">4</button> <button class="btn btn-numeric" ng-click="keypress(\'5\')">5</button> <button class="btn btn-numeric" ng-click="keypress(\'6\')">6</button></div></div><div class="row"><div class="col-md-12"><button class="btn btn-numeric" ng-click="keypress(\'7\')">7</button> <button class="btn btn-numeric" ng-click="keypress(\'8\')">8</button> <button class="btn btn-numeric" ng-click="keypress(\'9\')">9</button></div></div><div class="row"><div class="col-md-12"><button class="btn btn-numeric" ng-click="keypress(\'0\')">0</button> <button class="btn btn-numeric-15" ng-click="keypress(\'[BACK]\')">&lt; {{\'maintext.correction\' | translate}}</button></div></div></div>');
}]);
})();
