(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/bighead/speak_arm_bend_man_large.html',
    '<div class="show-bighead" ng-controller="blankController" style="position:absolute; z-index:2; top:50px" draggable="true"><div class="bubble btm-right-in" style="top:20px; left:15px; min-height: 100px; padding: 25px; font-size: 52px; max-width: 50px" translate="{{$state.current.data.bigheadText}}"></div><img src="images/deburen_graphics/man_2.png" style="position:absolute; width:265px; top:200px; left:75px" draggable="false"></div>');
}]);
})();
