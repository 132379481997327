(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/partials/days.html',
    '<div class="keyboard" ng-controller="defaultController"><div class="row"><div class="col-md-12"><h1 class="title-buren1" ng-show="$state.current.data.titleTextButtons.title" translate="{{$state.current.data.titleTextButtons.title}}"></h1><div class="col-md-8" style="background-color: #C3A802; height: 100px; padding: 10px; border-radius: 20px"><div class="col-md-2" style="padding-left: 5px !important"><button style="float: left" class="btn btn-keyboard" ng-disabled="cannot_less()" ng-click="subtractDays()">-</button></div><div class="col-md-8" style="padding-right: 5px; padding-left: 5px"><input style="height: 70px; border-radius: 10px; text-align: center; font-size: 32px; color: #99001A" id="txtCustomercode" class="form-control input-lg" value="{{days_html()}}"></div><div class="col-md-2" style="padding: 0!important"><button class="btn btn-keyboard" ng-click="addDays()">+</button></div></div></div><div class="col-md-12"><p>van</p><p>tot</p></div><div class="col-md-12"><p>Totale kosten</p><p>€ 2.00</p></div></div></div>');
}]);
})();
