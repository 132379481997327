var mainApp = angular.module('MainApp');

mainApp.controller('LockerOptionsController', function($scope, $rootScope, $state, $stateParams, $translate, toolkit, connection, store, listener, hardware) {
    $scope.$state = $state;
    $scope.$stateParams = $stateParams;
    $scope.parcels = [];
    $scope.parcels_on_page = [];
    $scope.start_parcel_index = 0;
    $scope.max_items_on_page = 16;
    $scope.already_opened_count = 0;

    $scope.driver = store.getDriver();
    //$scope.driver.name = $scope.driver.title;

    listener($scope, {
        handleActionButton: function(actionCommand) {
            var parcel = store.getParcel();

            if (actionCommand == 'locker_opened') {
                var message = "";
                if(parcel['not_collected']) {
                    message = '"' + $translate.instant('maintext.message_not_pickedup') + '"';
                }
                var next_locker = $translate.instant('maintext.message_next_locker_transporter');
                $scope.openLocker(parcel, message, next_locker);
            } else if (actionCommand == 'locker_empty') {
                $state.go('empty_confirm', {parcel_id: parcel.parcel_id});
            } else if (actionCommand == 'empty') {
                $scope.isEmpty($stateParams.parcel_id);
            }
        },

        handleFooterButton: function(actionCommand){
            // if (actionCommand == 'options') {
            //     $state.go('locker_options');
            // }else if(actionCommand=='open_lockers'){
            //     $state.go("my_lockers_transporter");
            // }
        }
//        ,homeTimeout: 5000
    });

    $scope.openLocker = function(parcel, message_status, next_locker) {
        var process = store.getProcess()
        locker_data = {parcel_id: parcel.parcel_id}
        var deliveryType = 'retrieval';
        if(process.type){
            locker_data.opentype = process.opentype;
            deliveryType = 'delivery';
        }
        var autoopen = $scope.driver&&$scope.driver.autoOpen;
        hardware.openLocker(parcel.module_id, parcel.address_id, deliveryType, locker_data).then(function(data) {
            if (data) { // Redirect
                var locker_number = toolkit.formatLockerNumber(parcel.locker_number);
                $state.go('locker_opened', { show_bigger_locker:0, locker_number:locker_number,
                        message_status: message_status, message_next_locker: next_locker, autoopen: autoopen });
            } else {
                hardware.logdump("failed to open locker" + JSON.stringify({"request":
                        {parcel_id: parcels[0].parcel_id, remark: "fill_aborted_parcel_damaged"},
                        "response": data}));
                // TODO
                console.log(data);
            }
        }, function(data) {
            // TODO
            console.log(data);
        });
    };

    $scope.isEmpty = function(parcel_id) {
        connection("/api/deliver/add_remark", {parcel_id: parcel_id, remark: "locker_empty"})
        .then(function(data){
            if(data){
                $state.go('locker_empty', {});
            }else{
                console.log("Failed to set status :(");
            }
            //console.log("data", data);
        });
    }
});


