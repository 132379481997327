var mainApp = angular.module('MainApp');

mainApp.controller('mineController',
    function($scope, connection, $location, $window, hardware, $state, $view, $stateParams, $rootScope, listener, $http) {
        listener($scope, {handleFooterButton: function(actionCommand){
            console.log("actionCommand: ", actionCommand);
        },
        handleHardwareEvent: function(actionCommand){
            alert("Woei: " + actionCommand);
        }});

        $scope.test = "woei";
        $scope.woei = "controller 1";

        $scope.lockerHeaders = ['-'];
        for(var i=0;i<12;++i){
            $scope.lockerHeaders[i+1] = i+1;
        }
        $scope.locker = {};
        $scope.inputvalue1 = 1;
        $scope.inputvalue2 = 2;

        $scope.col = 21;
        $scope.row = 1;

        $scope.mine = function(){
//            hardware.getStatus().then(function(test){
//                console.log(test);
//            });
            hardware.openLocker(parseInt($scope.inputvalue1, 10), parseInt($scope.inputvalue2, 10), 'delivery', {});
        };

        $scope.mine2 = function(){
            _data = {"test":"data"};
            $http({
                headers: {},
                method: 'POST',
                url: "http://localhost:8002",
                data: _data
            }).then(function(result){
                console.log(result);
            }, function(result){
                console.log(result);
            });
        };

        $scope.login = function(){
            console.log(connection("/api/login/login", {"user":"PARCEL4", "password":"blaat"}));
//            console.log(connection("/api/login/login", {"user":"TE5000", "password":"blaat"}));
        };

        $scope.parcels = function(){
            console.log(connection("/api/login/login", {"user":"TE5000", "password":"blaat"}));
        };

        $scope.check = function(){
            console.log(connection("/api/login/check"));
        };

        $scope.logout = function(){
            console.log(connection("/api/login/logout"));
        };

        $scope.testButton = function(){
            hardware.test("woei").then(function(result){console.log("jeeej", result);}, function(){console.log("nope :(")});
        };
        $scope.changeLocker = function(status){
            hardware.changeLocker(parseInt($scope.col), parseInt($scope.row), status).then(function(result){console.log("jeeej", result);}, function(){console.log("nope :(")});
//            connection("/api/test", {}).then(function(result){console.log("jeeej", result);}, function(){console.log("nope :(")});
        };
        $scope.testButton3 = function(){
            hardware.getStatus().then(function(result){
                var locker = {};
                for(var num in result.s){
                    console.log(num);
                    var data = [];
                    for(var i=0;i<12;++i){
                        var status = "";
                        if((result.l[num]&(1<<i)) == 0)
                            status += 'l';
                        if((result.s[num]&(1<<i)) != 0)
                            status += 's';
                        data.push(status);
                    }
                    locker[num] = data;
                }
                $scope.locker = locker;
                console.log("jeeej", result);
            }, function(){console.log("nope :(")});
//            connection("/api/test", {}).then(function(result){console.log("jeeej", result);}, function(){console.log("nope :(")});
        };
        console.log('test', $scope, $state, $view, $stateParams);
    }
);