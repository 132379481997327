var mainApp = angular.module('MainApp');

mainApp.controller('paymentRetrieveController', function($scope, connection, $state, store, toolkit, hardware, listener,
                $stateParams, rent, toolkit) {
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if(actionCommand=='abort'){
                if(rent.isPaying())
                    toolkit.close();
                else
                    $state.go('my_lockers');
            }else if(actionCommand=='stop_pay'){
                if(rent.isPaying())
                    rent.next($state);
                else
                    $state.go('mustpay');
            }

        }
//        ,homeTimeout: 5000
    });

    var amount = rent.isPaying()?rent.getTotalCost()/100:store.getAmountToPayRetrieve() +
            store.getAmountProductToPayRetrieve();
    if(!amount){
        toolkit.close();
    }else{
        if($stateParams.amount!=null){
            amount = parseInt($stateParams.amount, 10);
        }else{
            amount *= 100;
        }
        console.log('amount?', amount);
        hardware.pay(amount / 100, 'nl').then(function(result){
            if(result=="success"){
                if($stateParams.amount!=null){
                    if(rent.isPaying()){
                        connection("/api/payment/rent/burenCard", {amount: rent.getTotalCost(),
                                pinned: amount}).then(function(result){
                            console.log('result', result);
                            if(result['payed']){
                                rent.payed_by_card();
                                $state.go('rent_payment_upgrade_customercard', {upgrade:amount,
                                        payment:rent.getTotalCost(), balance:result.balance+result.promotion});
                            }else{
                                $state.go('rent_payment_failure');
                            }
                        }, function(err){
                            $state.go('rent_payment_failure');
                        });
                    }else{
                        connection("/api/payment/retrieve/burenCard", {amount: store.getAmountToPayRetrieve() * 100,
                                amount_product: store.getAmountProductToPayRetrieve() * 100, pinned: amount})
                                .then(function(result){
                            console.log('result', result);
                            if(result['payed']){
                                $state.go('payment_upgrade_customercard', {upgrade:amount,
                                        payment:store.getAmountToPayRetrieve() * 100,
                                        balance:result.balance+result.promotion});
                            }else{
                                $state.go('payment_failure');
                            }
                        }, function(err){
                            $state.go('payment_failure');
                        });
                    }
                }else{
                    if(rent.isPaying()){
                        $state.go('rent_payment_correct');
                    }else{
                        var data = {pinned: amount};
                        if(store.getAnonymousReceiveBarcode())
                            data.retrieve_code = store.getAnonymousReceiveBarcode();
                        connection("/api/payment/retrieve/pin", data).then(function(result){
                            console.log('result', result);
                            $state.go('payment_correct');
                        }, function(err){
                            $state.go('payment_failure');
                        });
                    }
                }
            }else if(result=="aborted"){
                $state.go('payment_failed');
            }else{
                $state.go('payment_failure');
            }
        },function(err){
            $state.go('payment_failure');
        });
    }
});

mainApp.controller('paymentAfterMathController', function($scope, connection, $state, store, toolkit, hardware,
            listener, rent) {
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if(actionCommand=='stop_pay'){
                if(rent.isPaying()){
                    toolkit.close();
                }else{
                    $state.go('my_lockers');
                }
            }else if(actionCommand=='againCustom'){
                $state.go('mustpay');
            }
        }
    });
});

mainApp.controller('paymentMustPayController', function($scope, connection, $state, store, toolkit, hardware,
            listener, rent) {
    $scope.settings = store.getTerminalConfig();
    var amount = rent.isPaying()?rent.getTotalCost()/100:
            (store.getAmountToPayRetrieve()+store.getAmountProductToPayRetrieve());
    if(!amount)
        toolkit.close();



    listener($scope, {
        handleActionButton: function(actionCommand){
            if(actionCommand=='pay_by_saldo'){
                if(rent.isPaying()){
                    connection("/api/payment/rent/burenCard", {amount: amount * 100}).then(function(result){
                        if(result.payed){
                            rent.payed_by_card();
                            $state.go('rent_payment_correct_customer', {balance: result.balance + result.promotion});
                        }else{
                            $state.go('rent_payment_low_balance', {current_balance: result.balance,
                                    current_promotion: result.promotion});
                        }
                    }, function(err){
                        console.error(err);
                    });
                }else{
                    connection("/api/payment/retrieve/burenCard",
                            {amount: (amount-store.getAmountProductToPayRetrieve()) * 100,
                                amount_product: store.getAmountProductToPayRetrieve() * 100}).then(function(result){
                        if(result.payed){
                            $state.go('payment_correct_customer', {balance: result.balance + result.promotion});
                        }else{
                            $state.go('payment_low_balance', {current_balance: result.balance,
                                current_promotion: result.promotion});
                        }
                    }, function(err){
                        console.error(err);
                    });
                }
            }
        }
    });
});

mainApp.controller('paymentCardLowController', function($scope, $stateParams, connection, $state, store, toolkit,
            hardware, listener, $filter, rent) {
    var amount = 0;
    if(rent.isPaying()){
        amount = rent.getTotalCost()/100 - $stateParams.current_promotion/100;
        if(amount<0)
            amount = 0;
        console.log("woop woop: ", amount, rent.getTotalCost(), $stateParams.current_promotion);
    }else{
        amount = store.getAmountToPayRetrieve() - $stateParams.current_promotion/100;
        if(amount<0)
            amount = 0;
        amount += store.getAmountProductToPayRetrieve()
    }
    var current_balance = $stateParams.current_balance;
    if(!amount||current_balance==null){
        console.log("jep failure: ", this, amount, current_balance, $stateParams);
        toolkit.close();
    }
    $scope.translate_values = {};
    $scope.translate_values.balance = $filter('toCurrency')(current_balance + $stateParams.current_promotion);
    listener($scope, {
        handleActionButton: function(actionCommand){
            if(actionCommand=='upgrade_and_pay'){
                $state.go((rent.isPaying()?"rent_":"") + 'upgrade_and_pay',
                        {minimal: Math.ceil(amount-(current_balance/100))*100});
            }
        }
    });
});

mainApp.controller('paymentChargeController', function($scope, $stateParams, connection, $state, store, toolkit,
            hardware, listener, $filter, rent) {
    var minimal = $stateParams.minimal;
    if(minimal==null)
        toolkit.close();
    $scope.amount = minimal;
    $scope.amount_html = function(){
        return $filter('toCurrency')($scope.amount);
    };
    $scope.cannot_less = function(){
        return $scope.amount <= minimal;
    };
    $scope.subtract = function(){
        $scope.amount -= 100;
    };
    $scope.add = function(){
        $scope.amount += 100;
    };
    $scope.charge = function(){
        $state.go((rent.isPaying()?"rent_":"") + 'pay_by_bank', {amount: $scope.amount});
    };
    $scope.days_html = function(){
        return $scope.amount;
    };
    $scope.subtractDays = function(){
        $scope.amount -= 1;
    };
    $scope.addDays = function(){
        $scope.amount += 1;
    };
});

mainApp.controller('paymentCustomercardController', function($scope, $stateParams, connection, $state, store, toolkit,
            hardware, listener, $filter, rent) {
    if($stateParams.balance==null)
        toolkit.close();
    listener($scope, {
        handleFooterButton: function(actionCommand){
            console.log("actionCommand", actionCommand);
            if(actionCommand=='continue'){
                if(rent.isPaying()){
                    rent.isPayed($state, connection);
                }else{
                    $state.go("my_lockers");
                }
            }
        }
    });
    $scope.translate_values = {};
    $scope.translate_values.balance = $filter('toCurrency')($stateParams.balance);
    if($stateParams.upgrade!=null)
        $scope.translate_values.upgrade = $filter('toCurrency')($stateParams.upgrade);
    if($stateParams.payment!=null)
        $scope.translate_values.payment = $filter('toCurrency')($stateParams.payment);
    console.log($scope.translate_values);
});

mainApp.controller('paymentDoneController', function($scope, $state, listener, connection, $filter, $stateParams) {
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if(actionCommand=='continue'){
                $state.go('my_lockers');
            }
        }
    });
    $scope.translate_values = {};
    $scope.translate_values.balance = $filter('toCurrency')($stateParams.balance);
});