var mainApp = angular.module('MainApp');

mainApp.directive('bread', function() {
    return {
        restrict: 'E',
        templateUrl: 'views/bread.html'
      };
});

mainApp.controller('breadController', function($scope, $state, listener) {
    $scope.$state = $state;
    var override = false;
    var currentBread = [];

    listener($scope, {
        stateChangeSuccess: function(){
            override = false;
        },
        breadOverride: function(overrideBread){
            override = overrideBread;
        }
    });

    $scope.bread = function(){
        if (override) {
            currentBread = override;
        } else if ($state.current && $state.current.data) {
           currentBread = $state.current.data.bread;
        }
        return currentBread;
    };
});
