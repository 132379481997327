var hardwareModule = angular.module('hardwareModule', ['ngWebSocket', 'connectionModule', 'storeModule',
        'waitModule']);

hardwareModule.provider('hardware', function() {
    var dataStream;
    var id = Math.round(Math.random()*2147483647);
    var requests = {};
    var rootScope;
    var loading_config = false;
    var connect;
        var timeout;
    var url;
    var websocket;
    var websocketCloseState = false;
    var storage;
    var promise;
    var wait;
    var hardwareConnect = function(){
        wait.start();
        dataStream = websocket(url, {reconnectIfNotNormalClose: true, maxTimeout: 1000});
        dataStream.onMessage(handleMessage, rootScope);
        // dataStream.onError(onError, rootScope);
        dataStream.onClose(onClose, rootScope);
        dataStream.onOpen(onOpen, rootScope);
        load_config();
        wait.stop();
    };
    var handleMessage = function(data){
        data = JSON.parse(data.data);
        if(data.hasOwnProperty("response")&&requests[data.response]){
            if(requests[data.response].timeout)
                timeout.cancel(requests[data.response].timeout);
            requests[data.response].resolve(data.data);
            delete requests[data.response];
        }else if(data.hasOwnProperty("event")){
            if(data['event']=="code_scanned"){
                if(!wait.isWaiting()){
                    rootScope.$emit("hardware.barcode", data.data);
                }
            }else if(data['event']=="pay_update"){
                rootScope.$emit("hardware.payupdate", null);
            }else if(data['event']=="reload"){
                rootScope.$emit("hardware.reload", null);
            }else if(data['event']=="locker_opened" || data['event']=="locker_closed" ){
                rootScope.$emit("hardware.hardware_message", data['event']);
            }else if(data['event']=="init_sweep"){
                rootScope.$emit("hardware.sweep", null);
            }
        }else
            console.log("unknown: ", data);
    };
    // var onError = function(data){
    //     console.log("failure: ", data);
    //     hardwareConnect();
    // };
    var onClose = function(data){
        if (!websocketCloseState) {
            rootScope.$emit("hardware.websocket", false);
            websocketCloseState = true;
            wait.start();
        }
    };
    var onOpen = function(data){
        if (websocketCloseState) {
            rootScope.$emit("hardware.websocket", true);
            websocketCloseState = false;
            wait.stop();
        }
    };
    var load_config = function(){
        if(!loading_config){
            loading_config = true;
            storage.setTerminalConfigPromise(promise(function(resolve, reject) {
                wait.start();
                var updateHardwareServerConfig = function(){
                    connect("/api/hardware/get_config", {}, true).then(function(data){
                        _sendCommand("set_config", data).then(function(){
                            wait.stop();
                            resolve();
                        }, updateHardwareServerConfig)
                    }, updateHardwareServerConfig);
                };
                var performUpdate = function(){
                    _sendCommand("get_config", {}).then(function(data){
                        console.log("terminal config promise: ", data, data["config_update"]);
                        storage.setTerminalConfig(data);
                        loading_config = false;
                        if(data["config_update"]){
                            updateHardwareServerConfig();
                        }else{
                            wait.stop();
                            resolve();
                        }
                    }, performUpdate);
                };
                performUpdate();
            }));
//            connect_back.start();
        }
    };
    var _sendCommand = function(command, data, setTimeout){
        if(setTimeout==undefined)
            setTimeout = true;
        var sendid = id++;
        return promise(function(resolve, reject) {
                var _timeout = setTimeout?timeout(function(){
                    console.log("Failed!!!!!");
                    if(requests[sendid]){
                        requests[sendid].promise.cancel();
                        reject();
                        delete requests[sendid];
                        console.log("Removed!!!!!", requests);
                    }
                }, 5000):false;
                requests[sendid] = {reject:reject, resolve:resolve, timeout: _timeout};
                requests[sendid].promise = dataStream.send({command: command, id: sendid, data:data});
                requests[sendid].promise.catch(function(){
                    console.log("Failed!!!!!");
                    if(requests[sendid]){
                        requests[sendid].promise.cancel();
                        reject();
                        delete requests[sendid];
                        console.log("Removed!!!!!", requests);
                    }
                });
            });
    };
    this.$get = function ($websocket, $q, $rootScope, connection, $timeout, store, HARDWARE_URL, $wait) {
        rootScope = $rootScope;
        connect = connection;
        timeout = $timeout;
        url = HARDWARE_URL;
        websocket = $websocket;
        storage = store;
        promise = $q;
        wait = $wait;
        hardwareConnect();
        return {
            start: function(){},
            test: function(data){
                console.log(data);
            },
            pay: function(amount, language){
                console.log("pay:", amount, language);
                $result = this.sendCommand("pay", {amount: amount, language: language, status: true}, false);
                $result.then(function(result){
                    console.log(["result", result]);
                }, function(err){
                    console.log(["failure", err]);
                });
                return $result;
            },
            abort: function(){
                console.log("abort");
                $result = this.sendCommand("abort_payment", {}, false);
                $result.then(function(result){
                    console.log(["result", result]);
                }, function(err){
                    console.log(["failure", err]);
                });
                return $result;
            },
            openLocker: function(col, row, delivery_type, data) {
                var _this = this;
                var driver = store.getDriver();
                console.log("driver test: ", driver, data);
                if(driver&&driver.id)
                    data.driver_id = driver.id;
                if(driver&&driver.businesspartner_id)
                    data.businesspartner_id = driver.businesspartner_id;
                console.log("driver test: ", driver, data);
                console.log("openLocker:", col, row);
                $result = this.sendCommand("changeLocker", {column: col, row:row, status: true,
                        delivery_type: delivery_type, data: data});
                $result.then(function(err){
                    console.log(["correct", err]);
                }, function(err){
                    _this.logdump("failed to open locker" + JSON.stringify({"request":
                            [col, row, delivery_type, data],
                            "response": data}));
                    console.log(["failure", err]);
                });
                return $result;
            },
            getStatus: function(){
                return this.sendCommand("statusLockers", {})
            },
            logdump: function(dump){
                return this.sendCommand("logdump", dump)
            },
            sendCommand:function(command, data, setTimeout){
                return _sendCommand(command, data, setTimeout);
            },
            sweepLogin:function(pin){
                return this.sendCommand("sweep_login", {'pin': pin});
            },
            getSweepLockers:function(){
                return this.sendCommand("sweep_lockers", {});
            },
            sweepOpenModule:function(module){
                return this.sendCommand("sweep_open_module", {'module': module});
            },
            abortSweep:function(){
                return this.sendCommand("sweep_abort", {});
            },
            isSweeping:function(){
                return this.sendCommand("sweep_busy", {});
            }
        };
    };
});