(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/help.html',
    '<div ng-show="location.hash === \'#/help1\'"><img src="images/deburen_graphics/help1.png" style="position:absolute; top:70px" draggable="false"></div><div ng-show="location.hash === \'#/help2\'"><img src="images/deburen_graphics/help2.png" style="position:absolute; top:70px" draggable="false"></div><div ng-show="location.hash === \'#/help3\'"><img src="images/deburen_graphics/help3.png" style="position:absolute; top:150px; left: 50px" draggable="false"></div><div ng-show="location.hash === \'#/help4\'"><img src="images/deburen_graphics/help4.png" style="position:absolute; top:70px" draggable="false"></div><div ng-show="location.hash === \'#/help5\'"><img src="images/deburen_graphics/help5.png" style="position:absolute; top:70px" draggable="false"></div>');
}]);
})();
