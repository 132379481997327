var mainApp = angular.module('MainApp');

mainApp.controller('myLockersController', function($scope, $rootScope, $state, $translate, $sce, connection, hardware,
                                                    store, $stateParams, $filter, toolkit, listener, $wait) {
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if (actionCommand == 'pay') {
                if(store.getAnonymousReceiveBarcode())
                    $state.go("mustpay_a");
                else
                    $state.go("mustpay");
            }
        }
    });
	$scope.$state = $state;
	$scope.parcels = [];
	$scope.parcels_on_page = [];
	$scope.start_parcel_index = 0;
	$scope.max_items_on_page = 4;
	$scope.pay = { mustpay:false, total_rent:0, total_product:0 };

	$scope.customer = store.getCustomer();
    $scope.settings = store.getTerminalConfig();

    if ($scope.customer) {
        if($scope.lang){
            // $translate.refresh();
            $translate.use($scope.customer.language);
        }

        $translate(($scope.customer.gender == 'm' ? 'maintext.sir' : 'maintext.madame')).then(function (sir) {
            $scope.customer.gendertext = sir;
        });
    }

	var showParcels = function() {
		var stopIndex = ($scope.start_parcel_index + $scope.max_items_on_page);
		$scope.parcels_on_page = $scope.parcels.slice($scope.start_parcel_index, stopIndex);
        for (var i=0;i<$scope.parcels_on_page.length;++i) {
            $scope.parcels_on_page[i].formattedLockerNumber =
                toolkit.formatLockerNumber($scope.parcels_on_page[i].locker_number);
        }
		//console.log($scope.parcels_on_page);
	};

	var calcPay = function() {
		var total_rent = 0;
		var pay_total_product = 0;
		$scope.comment_pay_rent = '';

		angular.forEach($scope.parcels, function(item, key) {
			total_rent += item.rent_pay;
			pay_total_product += item.product_pay;
		});
		$scope.pay.total_rent = total_rent;
        $scope.pay.total_product = pay_total_product;
		$scope.pay.mustpay = ($scope.pay.total_rent || $scope.pay.total_product) ? true : false;

		// Show 'betalen' knop
		if ($scope.pay.mustpay) {
		    $rootScope.$emit("footerburen.button.add", 'payr');
		}
	};

    $scope.html_total_rent = function(){
        return $filter('toCurrency')($scope.pay.total_rent);
    };

    $scope.html_total_product = function(){
        return $filter('toCurrency')($scope.pay.total_product);
    };

	var fetchParcels = function() {
	    $wait.start();
	    var data = {};
	    if(store.getAnonymousReceiveBarcode())
	        data.receive_code = store.getAnonymousReceiveBarcode();
		connection("/api/retrieve/parcels", data).then(function(data) {
			$scope.parcels = data;

            angular.forEach($scope.parcels, function(item, key) {

                if(item.language){
                    // $translate.refresh();
                    $translate.use(item.language);
                }

            });

			calcPay();
			if($scope.pay.mustpay){
			    store.setAmountToPayRetrieve($scope.pay.total_rent/100);
			    store.setAmountProductToPayRetrieve($scope.pay.total_product/100);
            }
			showParcels();
			$wait.stop();
		}, function(data) {
			// TODO
			$wait.stop();
			console.log(data);
		});
	};

	$scope.nav = function(new_start_index) {
		$scope.start_parcel_index = new_start_index;
		showParcels();
	};

	$scope.openLocker = function(parcel) {
        var locker_number = toolkit.formatLockerNumber(parcel.locker_number);
        if(parcel.can_reopen){
            $state.go('reopen_locker_later',{ module_id: parcel.module_id, parcel_id: parcel.parcel_id,
                locker_number: locker_number, address_id: parcel.address_id});
        }else{
            var next_locker = $translate.instant('maintext.message_next_locker_customer');
            var process = store.getProcess()
            var locker_data = { parcel_id: parcel.parcel_id };
            var deliveryType = 'retrieval';
            if(process.type){
                locker_data.opentype = process.opentype;
                deliveryType = 'delivery';
            }
            hardware.openLocker(parcel.module_id, parcel.address_id, deliveryType, locker_data).then(function(data) {
                if (data) { // Redirect
                    $state.go('locker_opened', { locker_number:locker_number, message_next_locker: next_locker });
                } else {
                    // TODO
                    console.log(data);
                }
            }, function(data) {
                // TODO
                console.log(data);
            });
        }
    };
	fetchParcels();
});

