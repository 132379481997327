(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/bighead/speak_arm_bend.html',
    '<div class="show-bighead" ng-controller="blankController" style="position:absolute; z-index:2; top:50px; margin-left: 25px" draggable="true"><div class="bubble" style="top:-40px; left:0px; min-width:340px" translate="{{$state.current.data.bigheadText}}"></div><img src="images/deburen_graphics/man_2.png" style="position:absolute; width:250px; top:150px; left:50px" draggable="false"> <img src="images/deburen_graphics/dog_2.png" style="position:absolute; width:190px; top:480px; left:280px" draggable="false"> <img src="images/deburen_graphics/bone_2.png" style="position:absolute; width:91px; top:580px; left:200px" draggable="false"></div>');
}]);
})();
