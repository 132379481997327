(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/sweep/login.html',
    '<div><div class="col-md-4" ng-if="$state.current.data.input_1" style="overflow:hidden"><div class="pull-left" style="padding:20px 30px 10px 30px"><p id="receivecode" class="{{$state.current.data.input_1.class_label}} choose" translate="{{$state.current.data.input_1.text}}"></p></div></div><div class="col-md-4"><div ng-if="$state.current.data.input_1" class="pull-right lockerChoose" style="background-color:#ccc; border-radius: 20px 20px 0 0; padding:20px 20px 5px 30px; float: right; margin-top: 50px; width: 88%"><div><input type="{{$state.current.data.input_1.type}}" class="form-control input-lg numeric numeric-sweep" value="" ng-model="input.value_1"></div></div><div class="keypad" ng-controller="keyboardController as keyboard" style="width: 88%; float: right"><div class="keyboard-fluid" style="background-color:#cccccc; border-radius: 0px 0 20px 20px; border:1px solid #cccccc; padding:20px"><div ng-include="\'views/partials/numeric_keypad.html\'"></div></div></div></div><div class="col-md-4" ng-if="inCorrectPin > 0" style="overflow:hidden"><div class="pull-left" style="padding:20px 30px 10px 30px"><p id="incorrect_pin" class="{{$state.current.data.input_1.class_label}} choose" translate="{{$state.current.data.login_incorrect}}"></p></div></div></div>');
}]);
})();
