(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/bread.html',
    '<div ng-if="bread()" class="row" style="margin-top:10px"><div class="col-md-12"><ul class="breadcrumbdb"><li ng-repeat="value in bread() track by $index"><a ng-if="$state.current.data.burned==$index" class="active">{{value | translate}}</a> <a ng-if="$state.current.data.burned!=$index">{{value | translate}}</a></li></ul></div></div>');
}]);
})();
