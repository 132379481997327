(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/bighead/speak.html',
    '<div class="show-bighead" ng-controller="blankController" style="position:absolute; z-index:2; top:50px" draggable="true"><div class="bubble btm-right-in" style="top:-60px; left:40px" translate="{{$state.current.data.bigheadText}}"></div><img src="images/deburen_graphics/man_3.png" style="position:absolute; width:420px; top:130px; left:50px" draggable="false"> <a draggable="false" href="javascript:location.reload(true)" ng-show="{{$state.current.data.bigheadReload}}"><img src="images/deburen_graphics/dog_2.png" style="position:absolute; width:190px; top:500px; left:320px"> </a><img src="images/deburen_graphics/dog_2.png" style="position:absolute; width:190px; top:500px; left:320px" ng-hide="{{$state.current.data.bigheadReload}}" draggable="false"> <img src="images/deburen_graphics/bone_2.png" style="position:absolute; width:91px; top:666px; left:425px" draggable="false"></div>');
}]);
})();
