(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/bighead/man_dog_bone.html',
    '<div class="show-bighead" ng-controller="blankController" style="position:absolute; z-index:2; top:50px" draggable="true"><img src="images/deburen_graphics/man_3.png" style="position:absolute; width:400px; top:60px; left:75px" draggable="false"> <img src="images/deburen_graphics/dog_2.png" style="position:absolute; width:175px; top:415px; left:350px" draggable="false"> <img src="images/deburen_graphics/bone_2.png" style="position:absolute; width:91px; top:580px; left:450px" draggable="false"></div>');
}]);
})();
