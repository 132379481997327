var mainApp = angular.module('MainApp');

mainApp.controller('MyLockersTransporterController', function($scope, $rootScope, $state, $stateParams, $translate,
                                                              toolkit, connection, store, listener, hardware,
                                                              filterFilter) {
    $scope.$state = $state;
    $scope.$stateParams = $stateParams;
    $scope.parcels = [];
    $scope.parcels_on_page = [];
    $scope.start_parcel_index = 0;
    $scope.max_items_on_page = 16;
    $scope.already_opened_count = 0;

    $scope.driver = store.getDriver();
    $scope.driver.name = $scope.driver.title;

    var fetchParcels = function() {
        $scope.parcels = store.getParcels();

        $scope.already_opened_count = 0;
        angular.forEach($scope.parcels, function(parcel) {
            if (parcel.already_opened) { $scope.already_opened_count++; }
        });

        showParcels();
    };

    var showParcels = function() {
        var stopIndex = ($scope.start_parcel_index + $scope.max_items_on_page);
        $scope.parcels_on_page = $scope.parcels.slice($scope.start_parcel_index, stopIndex);
        for (var i=0;i<$scope.parcels_on_page.length;++i) {
            $scope.parcels_on_page[i].formattedLockerNumber =
                toolkit.formatLockerNumber($scope.parcels_on_page[i].locker_number);
        }
    };

    $scope.nav = function(new_start_index) {
        $scope.start_parcel_index = new_start_index;
        showParcels();
    };

    fetchParcels();

    listener($scope, {
        handleActionButton: function(actionCommand) {
            var parcel = store.getParcel();

            if (actionCommand == 'locker_options') {
                parcel = $scope.parcels[0];
                $scope.lockerOptions(parcel);
            } else if (actionCommand == 'locker_overview') {
//                $state.go("my_lockers_transporter");
            }
        },

        handleFooterButton: function(actionCommand){
            // if (actionCommand == 'options') {
            //     $state.go('locker_options');
            // }else if(actionCommand=='open_lockers'){
            //     $state.go("my_lockers_transporter");
            // }
        }
//        ,homeTimeout: 5000
    });

    $scope.lockerOptions = function(parcel) {
        store.setParcel(parcel);
        var locker_number = toolkit.formatLockerNumber(parcel.locker_number);
        $state.go('locker_options', { locker_number:locker_number });
    };
});


