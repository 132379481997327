(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/templates/title_text_buttons.html',
    '<div ng-controller="defaultController" style="margin-left: 10px"><h1 class="title-buren1" ng-show="$state.current.data.titleTextButtons.title" translate="{{$state.current.data.titleTextButtons.title}}"></h1><p class="txt-buren1" style="margin-top: 50px; width: 450px; line-height: 35px" ng-show="$state.current.data.titleTextButtons.text" translate-values="{{ translate_values }}" translate="{{$state.current.data.titleTextButtons.text}}"></p><button class="btn btn-buren1 btn-block" ng-repeat="(key, button) in getButtons()" ng-click="clickButton(button);"><span class="pull-left" translate-values="{{ translate_values }}" translate>{{\'button.\' + button.text}} </span><i class="glyphicon glyphicon-triangle-right pull-right"></i></button></div>');
}]);
})();
