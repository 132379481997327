var mainApp = angular.module('MainApp');

mainApp.controller('barcodeDeliveryController', function($scope, $rootScope, $state, $stateParams, connection, store,
        listener, toolkit) {
    console.log("add listener!!! $stateParams!", $stateParams);
    listener($scope, {
        handleFooterButton: function(actionCommand){
            console.log("handleFooterButton", actionCommand);
            if (actionCommand == 'continue') {
                checkBarcode($scope.input.value_1);
            }
        },
        handleBarcodeEvent: function(barcode){
            checkBarcode(barcode);
        }
    });

	$scope.$state = $state;

	$scope.input = { value_1: ''};
	var active_field = 'value_1';

	$scope.keyboard_input = function(value) {
		if (active_field == 'value_1') {
			$scope.input[active_field] = value.toUpperCase();
		} else {
			$scope.input[active_field] = value;
		}
	};

	var checkBarcode = function($barcode) {
        $types = [];
	    if ($stateParams['avisage']) {
            $types =["avisage"];
        } else {
            $types = ["delivery"];
        }
        connection("/api/scan", {code: $barcode, types: $types}).then(function(data){
            if(data.type=="delivery"){
	            data.barcode = $scope.input.value_1;
	            toolkit.handleDeliveryFound(data);
            }else if(data.type=="failed_avisage"){
                toolkit.handleFailedAvisageFound(data);
            }else{
                failure();
            }
        }, failure);
	};

	var failure = function () {
        if ($stateParams['avisage']) {
            $state.go("no_avisage");
        } else {
            $state.go("delivery_incorrect");
        }
    }
});

