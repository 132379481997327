var emulationModule = angular.module('emulationModule', ['connectionModule', 'storeModule']);

emulationModule.provider('emulation', function() {
    var timer = null;
    var state = 0;
    var stateModule;
    var connect;
    var timeoutModule;
    var storeModule;
    var reloadHome = true;
    var switchState = function(){
        ++state;
        switch(state){
        case 1:
            connect("/api/login/logout");
            stateModule.go('home');
            break;
        case 2:
            stateModule.go('retrieve');
            break;
        case 3:
            stateModule.go('customercode_input');
            break;
        case 4:
            connect("/api/login/login", {"user":"TE5000", "password":"blaat"}).then(function(data) {
                if (data.success) {
                    if(data.driver){
                        storeModule.setDriver(data.driver);
                        stateModule.go('transporter');
                    }
                    if(data.customer){
                        storeModule.setCustomer(data.customer);
                        stateModule.go('my_lockers');
                    }
                }else{
                    stateModule.go('customer_incorrect');
                }
            }, function() {
                // TODO
                console.log('Error while logging on...')
            });
            break;
        case 5:
            if(reloadHome)
                window.location.href="/";
            else
                state = 0;
        default:
            state = 0;
        }
        timeout = timeoutModule(switchState, reloadHome?5000:500);
    };

    this.$get = function ($state, connection, $timeout, store) {
        stateModule = $state;
        connect = connection;
        timeoutModule = $timeout;
        storeModule = store;
        return {
            enabled: false,
            reload: true,
            toggleReload: function(){
                this.reload = !this.reload;
                reloadHome= this.reload;
            },
            toggleEnabled:function(byTimer){
                this.enabled = !this.enabled;
                if(this.enabled){
                    var d = new Date();
                    d.setTime(d.getTime() + 100000000);
                    var expires = "expires="+d.toUTCString();
                    document.cookie = "run=true; " + expires;
                    if(byTimer)
                        timeout = timeoutModule(switchState, 5000);
                    else
                        switchState();
                }else if(timeout){
                    var d = new Date();
                    d.setTime(d.getTime() - 100000000);
                    var expires = "expires="+d.toUTCString();
                    document.cookie = "run=true; " + expires;
                    $timeout.cancel(timeout);
                }
            }
        };
    };
});