var connectionModule = angular.module('connectionModule', ['ngCookies', 'storeModule', 'burenConfig', 'waitModule']);

connectionModule.provider('connection', function() {
    var csrf_token = null;

    this.$get = function ($http, $q, $cookies, store, $wait) {
        return function(url, data, ignorePromise){
            return $q(function(resolve, reject) {
                $wait.start();
                var rejectFunction = function(){
                    $wait.stop();
                    return reject.apply(this, arguments);
                };
                var makeRequest = function(){
                    if(!data)
                        data = {};
                    var _data = {_token: csrf_token};
                    for (var key in data) {
                        if (data.hasOwnProperty(key)) {
                            _data[key] = data[key];
                        }
                    }
                    $http({
                        headers: {  "X-CSRF-TOKEN": csrf_token,
                                    "x-buren-password": store.getTerminalConfig().terminal_password,
                                    "x-buren-user": store.getTerminalConfig().terminal_user},
                        method: 'POST',
                        withCredentials: true,
                        url: store.getTerminalConfig()['backend_url'] + url,
                        data: _data
                    }).then(function(result){
                        if(result.data.token_mismatch)
                            getCsrfToken();
                        else{
                            $wait.stop();
                            resolve(result.data);
                        }
                    }, rejectFunction);
                };
                var getCsrfToken = function(){
                    $http({
                            method: 'GET',
                            withCredentials: true,
                            url: store.getTerminalConfig()['backend_url'] + "/get_csrf",
                            headers: {  "x-buren-password": store.getTerminalConfig().terminal_password,
                                        "x-buren-user": store.getTerminalConfig().terminal_user}
                        }).then(function(result){
                            csrf_token = result.data;
                            makeRequest();
                        }, rejectFunction);
                };
                if(csrf_token==null){
                    if(ignorePromise){
                        getCsrfToken();
                    }else{
                        store.getTerminalConfigPromise().then(function(){
                            getCsrfToken();
                        }, rejectFunction);
                    }
                }else{
                    makeRequest();
                }
            });
        };
    };
});
