var mainApp = angular.module('MainApp');

mainApp.controller('sameBarcodeController', function($scope, listener, store, toolkit){
     listener($scope, {
        handleActionButton: function(actionCommand){
            if(actionCommand=='new_locker'){
                openNext();
            }
        }
    });


    var openNext = function(){
        var parcels = store.getDeliveryParcels();
        var parcel = false;
        for(var i=0;i<parcels.length && !parcel;++i){
            if(!parcels[i].already_filled){
                parcel = parcels[i];
            }
        }
        if(parcel){
            toolkit.openLocker(parcel);
        }else{
            toolkit.close();
        }
    };
});

mainApp.controller('deliveryNotifyController', function($scope, $state, store, listener) {
    listener($scope, {
        handleActionButton: function(actionCommand){
            if (actionCommand=='email') {
                $state.go('email_input_external');
            } else {
                $state.go("external_locker_choose");
            }
        }
    });
});

mainApp.controller('deliveryNotifyMailController', function($scope, $state, store, listener, $rootScope, rent) {
    listener($scope, {
        handleFooterButton: function(actionCommand){
            console.log("actionCommand: " + actionCommand);
            if (actionCommand == 'continue') {
                submit();
            }
        }
    });
    $scope.keyboard_input = function(value) {
        $scope.input[active_field] = value;
    };
    $scope.$state = $state;
    $scope.input = { value_1: '', value_2: '' };
    var active_field = 'value_1';

    var submit = function() {
        // todo check mail validity;
        if(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                .test($scope.input.value_1)){
            store.getDeliveryExternal().customer_email = $scope.input.value_1;
            $state.go("external_locker_choose");
        }else{
            $state.go('incorrect_mail');
        }
    };
});


mainApp.controller('externalDeliveryController', function($scope, $rootScope, $state, $stateParams, connection, store,
        listener, hardware, $wait, $translate, toolkit) {
     listener($scope, {
         handleFooterButton: function(actionCommand){
             if (actionCommand == 'continue') {
                 chooseLocker();
             }
         }
     });

    $scope.settings = store.getTerminalConfig();
    console.log($scope.settings, store.getDeliveryExternal());
    $scope.shouldBeDisabled = true;
    var module = true;

    if ($scope.settings) {
        console.log($scope.settings);
        if ($scope.settings.type_locker == 0){
            $scope.shouldBeDisabled = false;
            module = false;
        }
    }

     $scope.clickButton = function(){
         $state.go('help1');
     }

    $scope.$state = $state;

    $scope.input_sub = ['','','',''];
    $scope.input = { value_1: ''};
    var active_field = 'value_1';

    $scope.keyboard_input = function(value) {

        if(module === false){
            value = '00' + value;
        }

        if (active_field == 'value_1') {
            if(value.length>4)
                value = value.substr(0, 4);
            $scope.input[active_field] = value.toUpperCase();
            for(var i=0;i<4;++i){

                if(module === false && i === 0 || module === false && i === 1){
                    $scope.input_sub[i] = 0;
                }else{
                    $scope.input_sub[i] = i<value.length?value.substr(i,1):"";
                }
            }
        } else {
            $scope.input[active_field] = value;
        }

        //if (active_field == 'value_1') {
        //    if(value.length>4)
        //        value = value.substr(0, 4);
        //    $scope.input[active_field] = value.toUpperCase();
        //    for(var i=0;i<4;++i){
        //        $scope.input_sub[i] = i<value.length?value.substr(i,1):"";
        //    }
        //} else {
        //    $scope.input[active_field] = value;
        //}
    };
    var chooseLocker = function() {
        if($scope.input.value_1.length==4){
            $wait.start();
            connection("/api/deliver/reserve_locker", {locker_number: $scope.input.value_1}).then(function(result){
                if (result && result.type == "success") {
                    var external = store.getDeliveryExternal();
                    var customer_id = parseInt(external.customer_id, 10);
                    var data = {locker_id: result.locker.locker_id, site_id: parseInt(external.site_id, 10),
                            reference: external.reference, parcel_type: external.parcel_type,
                            reserved_from: external.date_reservation, days: parseInt(external.days_rent, 10),
                            delivery_partner_id: parseInt(external.delivery_partner_id, 10),
                            pickup_partner_id: parseInt(external.pickup_partner_id, 10), barcode: external.barcode};
                    if (customer_id == 6) {
                        if(external.customer_email)
                            data.anonymous_email = external.customer_email;
                        if(external.customer_mobile)
                            data.anonymous_sms = external.customer_mobile;
                        data.notify_deliverer_on_empty = external.customer_email?
                                (external.customer_mobile?"emailandsms":"email"):
                                (external.customer_mobile?"sms":"None");
                    }else{
                        data.customer_id = customer_id;
                        data.notify_deliverer_on_empty = 'None';
                    }
                    data.language = $translate.use();
                    connection('/api/deliver/confirm_reservation', data).then(function(parcel_id){
                        hardware.openLocker(result.locker.module_id, result.locker.address_id, 'delivery',
                                {parcel_id: parcel_id, opentype:'open'}).then(function(success){

                                var settings = store.getTerminalConfig();
                                var locker_number = toolkit.formatLockerNumber(result.locker.locker_number);

                            if(success)
                                $state.go("locker_external_delivery_opened",
                                        {locker_number: locker_number });
                            else
                                console.log("failed to open :(");
                            $wait.stop();
                        }, function(){
                            $wait.stop();
                            console.log("failed to open :(");
                        });
                    }, function(){
                         $wait.stop();
                         toolkit.close();
                         console.log("failed to open :(");
                     });
                }else if(result.type=="smaller"){
                    $wait.stop();
                    if (store.getProcess().avisage) {
                        connection('/api/deliver/avisage_postal', {parcel_id: parcel.parcel_id}).then(function(result) {
                            $state.go('avisage_delivery_postal', {location: result.location});
                        }, function() {
                            $state.go('avisage_impossible');
                        });
                    } else {
                        $state.go("locker_not_available");
                    }
                }else if(result.type=="no_empty"){
                    $wait.stop();
                    if (store.getProcess().avisage) {
                        connection('/api/deliver/avisage_postal', {parcel_id: parcel.parcel_id}).then(function(result) {
                            $state.go('avisage_delivery_postal', {location: result.location});
                        }, function() {
                            $state.go('avisage_impossible');
                        });
                    } else {
                        $state.go("no_locker_available");
                    }
                }else{
                    $wait.stop();
                    $state.go("locker_unknown");
               }
            }, function(){
                console.log("Reject thingy thing");
                $wait.stop();
                $state.go("locker_unknown");
            });
        }else{
            $state.go("locker_incomplete");
        }
   };
});

mainApp.controller('reopenOptionsController', function($scope, $stateParams, hardware, store, listener, toolkit,
        $translate, $state) {
    listener($scope, {
        handleActionButton: function(actionCommand){
            var next_locker = $translate.instant('maintext.message_next_locker_customer');
            var process = store.getProcess()
            var locker_data = {parcel_id: $stateParams.parcel_id, reopen_later: actionCommand == 'yes'?1:0}
            var deliveryType = 'retrieval';
            if(process.type){
                locker_data.opentype = process.opentype;
                deliveryType = 'delivery';
            }
            hardware.openLocker($stateParams.module_id, $stateParams.address_id, deliveryType, locker_data)
                    .then(function(data) {
                $state.go('locker_opened',{locker_number:$stateParams.locker_number, message_next_locker: next_locker});
            }, function(data) {
                hardware.logdump("failed to open locker" + JSON.stringify({"module": $stateParams.module_id,
                        "address": $stateParams.address_id, "delivery": deliveryType, "locker_data": locker_data,
                        "response": data}));
                // TODO
                console.log(data);
            });
        }
    });
});

mainApp.controller('deliveryOptionsController', function($scope, $rootScope, $state, store, connection, hardware,
                listener, toolkit) {
    listener($scope, {
        handleActionButton: function(actionCommand){
            var parcels = store.getDeliveryParcels();
            var parcel = false;
            for(var i=0;i<parcels.length;++i){
                if(parcels[i].already_filled){
                    parcel = parcels[i];
                }
            }
            console.log("parcel: ", parcel);
            if(parcel==false)
                toolkit.close();
            if (actionCommand == 'reopen') {
                hardware.openLocker(parcel.module_id, parcel.address_id, 'delivery',
                        { parcel_id: parcel.parcel_id, process: store.getProcess() }).then(function(data2) {
                    if (data2) { // Redirect
                        var locker_number = toolkit.formatLockerNumber(parcel.locker_number);
                        $state.go('locker_opened', {
                            locker_number: locker_number
                        });
                    } else {
                        hardware.logdump("failed to open locker" + JSON.stringify({"request": [parcel.module_id,
                                parcel.address_id, 'delivery', { parcel_id: parcel.parcel_id,
                                process: store.getProcess() }], "response": data2}));
                        // TODO: Kluis is niet open gegaan, wat te doen?
                        console.log(['Kluis is niet open gegaan, why?', parcels, data2]);
                    }
                }, function(data2){
                    hardware.logdump("failed to open locker" + JSON.stringify({"request": [parcel.module_id,
                            parcel.address_id, 'delivery', { parcel_id: parcel.parcel_id,
                            process: store.getProcess() }], "response": data2}));
                });
            } else if(actionCommand=='abort'){
                connection("/api/deliver/add_remark", {parcel_id: parcel.parcel_id, remark: "fill_aborted"})
                    .then(function(data){
                    if(data){
                        $state.go('deliver_abort_final');
                    }else{
                        hardware.logdump("failed to add remark" + JSON.stringify({"request":
                                {parcel_id: parcel.parcel_id, remark: "fill_aborted"}, "response": data}));
                        console.log("Failed to set status :(");
                    }
                });
            } else if(actionCommand=='damaged'){
                connection("/api/deliver/add_remark", {parcel_id: parcel.parcel_id,
                                                       remark: "fill_aborted_parcel_damaged"})
                    .then(function(data){
                    if(data){
                        $state.go('deliver_damaged_final');
                    }else{
                        hardware.logdump("failed to add remark" + JSON.stringify({"request":
                                {parcel_id: parcel.parcel_id, remark: "fill_aborted_parcel_damaged"},
                                "response": data}));
                        console.log("Failed to set status :(");
                    }
                });
            }
        }
    });
});

