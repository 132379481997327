var pushModule = angular.module('pushModule', ['ngWebSocket', 'connectionModule', 'storeModule']);

pushModule.provider('push', function() {
    var dataStream;
    var id = 0;
    var requests = {};
    var rootScope;
    var openList = {};
    var connect;
    var timeout;
    var url;
    var websocket;
    var hardwareConnect = function(){
        dataStream = websocket(url);
        dataStream.onMessage(handleMessage, rootScope);
        dataStream.onError(onError, rootScope);
        dataStream.onClose(onClose, rootScope);
    };

    var handleMessage = function(data){
        console.log("push: data.data", data.data, requests);
    };
    var onError = function(data){
        console.log("failure: ", data);
    };
    var onClose = function(data){
        console.log("onClose failure: ", data);
        hardwareConnect();
    };

    this.$get = function ($websocket, $q, $rootScope, connection, $timeout, store) {
        console.log("push!!!!");
        console.log($websocket, $rootScope);
        rootScope = $rootScope;
        connect = connection;
        timeout = $timeout;
        url = "ws://localhost:8001";
        websocket = $websocket;
        hardwareConnect();
        return {
            test: function(data){
                console.log(data);
            }
        };
    };
});