(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/templates/warning.html',
    '<div class="row"><div class="col-md-4" ui-view="view0"></div><div class="col-md-5" ui-view="view1"></div></div>');
}]);
})();
