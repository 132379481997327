var storeModule = angular.module('storeModule', ['pascalprecht.translate']);

storeModule.provider('store', function() {
    var customer;
    var terminalConfig;
    var terminalConfigPromise;
    var driver;
    var deliveryParcels;
    var deliveryExternal;
    var deliveryBarcode;
    var anonymousReceiveBarcode;
    var amount_open;
    var amount_product_open;
    var parcels;
    var parcel;
    var sweepModules;
    var lastopenState;
	var process = {type:null, opentype:'reserved'};

    this.$get = function ($translate) {
        return {
            setAnonymousReceiveBarcode: function(data){
                anonymousReceiveBarcode = data;
            },
            getAnonymousReceiveBarcode: function(){
                return anonymousReceiveBarcode;
            },
            setLastOpenState: function(data){
                lastopenState = data;
            },
            getLastOpenState: function(){
                return lastopenState;
            },
            setDeliveryBarcode: function(data) { deliveryBarcode = data; },
	        getDeliveryBarcode: function() { return deliveryBarcode },
            setDeliveryParcels: function(data){
                deliveryParcels = data;
            },
            getDeliveryParcels: function(){ return deliveryParcels; },
            getDeliveryExternal: function(){
                return deliveryExternal;
            },
            setDeliveryExternal: function(data){
                deliveryExternal = data;
            },
            getAmountToPayRetrieve: function(){
                return amount_open;
            },
            setAmountToPayRetrieve: function(amountOpen){
                amount_open = amountOpen;
            },
            getAmountProductToPayRetrieve: function(){
                return amount_product_open;
            },
            setAmountProductToPayRetrieve: function(amountProductOpen){
                amount_product_open = amountProductOpen;
            },
            purge: function(){
                amount_open = undefined;
                amount_product_open = undefined;
                customer = undefined;
                driver = undefined;
                deliveryParcels = undefined;
                deliveryBarcode = undefined;
                parcels = undefined;
                parcel = undefined;
                process = {type:null, opentype:'reserved'};
                anonymousReceiveBarcode = undefined;
                lastopenState = false;
                sweepModules = undefined;
            },
            setTerminalConfig: function(data){
                terminalConfig = data;
                // $translate.refresh();
                $translate.use(terminalConfig.language);
            },
            getTerminalConfig: function(){
                return terminalConfig;
            },
            setTerminalConfigPromise: function(data){
                terminalConfigPromise = data;
            },
            getTerminalConfigPromise: function(){
                return terminalConfigPromise;
            },
            setCustomer: function(data){
                customer = data;
            },
            getCustomer: function() {
	            return customer;
            },
            setDriver: function(data, $state){
                driver = data;
                // $translate.refresh();
                $translate.use(driver.language);
                if($state) {
                    if((driver.role&4)!=0) {
                        if (driver.canAvisage) {
                            $state.go('transporter_choose_avisage');
                        } else {
                            $state.go('transporter_choose');
                        }
                    }else{
                        $state.go('transporter');
                    }
                }
            },
            getDriver: function(){
                return driver;
            },
            setParcel: function(data){
                parcel = data;
            },
            getParcel: function(){
                return parcel;
            },
            setParcels: function(data){
                parcels = data;
            },
            getParcels: function(){
                return parcels;
            },
	        setProcess: function(data) {
		        process = data;
	        },
	        getProcess: function() {
		        return process;
	        },
            setSweepModules: function(data){
                sweepModules = data;
            },
            getSweepModules: function(){
                return sweepModules;
            }
        };
    };
});
