var mainApp = angular.module('MainApp');

mainApp.controller('bodyController', function ($scope, $state, $rootScope, connection, store, listener, toolkit, $wait,
                                               APPLICATION_DATE_BUILT, APPLICATION_VERSION, $translate, hardware,
                                               $timeout, $stateParams) {
    $scope.show = function () {
        return $wait.isWaiting() ? 'wait' : 'main';
    };
    $scope.showVersion = false;
    $scope.status = {
        appVersion: APPLICATION_VERSION,
        appDateBuild: APPLICATION_DATE_BUILT
    };

    listener($scope, {
        handleFooterButton: function (actionCommand) {
            if (actionCommand == 'stop') {
                console.log('stop');
                if ($scope.show == 'main') {
                    $scope.show = 'stop';
                } else if ($scope.show == 'stop') {
                    $scope.show = 'main';
                }
            } else if (actionCommand == 'logout') {
                toolkit.close();
                $scope.show = 'main';
            } else if (actionCommand == 'back') {
                $rootScope.$emit("direction_handler", "back");
                window.history.back();
            } else if (actionCommand == 'input') {
                $state.go("deliver_input");
            } else if (actionCommand == 'input_avisage') {
                $state.go("deliver_input_avisage");
            } else if (actionCommand == 'again') {
                window.history.back();
            }
        },
        handleWaitingEvents: function (wait) {
            $timeout(function() {
                $scope.show = wait ? "wait" : "main";
            });
        },
        handleHardwareMessage: function(event){
            store.setLastOpenState(event == 'locker_opened');
        }
    });
    $scope.goBack = function () {
        window.history.back();
    };

    $scope.showVersionWindow = function (show) {
        if (show) {
            $scope.showVersion = true;
        } else {
            $scope.showVersion = false;
        }
    };
});