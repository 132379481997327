var mainApp = angular.module('MainApp');

mainApp.controller('barcodeRetrieveController', function($scope, $rootScope, $state, $stateParams, connection, store,
        listener, toolkit) {
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if (actionCommand == 'continue') {
                login();
            }
        },
        handleBarcodeEvent: function(barcode){
            console.log("newBarcode");
            connection("/api/scan", {code: barcode, types: ["receive"]}).then(function(data){
                if(data.type=="receive") {
                    store.setAnonymousReceiveBarcode(barcode);
                    $state.go("my_lockers");
                }else if(data.type=="failed_avisage"){
                    toolkit.handleFailedAvisageFound(data);
                }else{
                    $state.go("barcode_incorrect");
                }
            });
        }
    });

	$scope.$state = $state;

	$scope.input = { value_1: ''};
	var active_field = 'value_1';

	$scope.keyboard_input = function(value) {
		if (active_field == 'value_1') {
			$scope.input[active_field] = value.toUpperCase();
		} else {
			$scope.input[active_field] = value;
		}
	};

	var login = function() {
	    connection("/api/scan", {code: $scope.input.value_1}).then(function(data){
            if(data.type=="receive"){
                store.setAnonymousReceiveBarcode($scope.input.value_1);
                $state.go("my_lockers");
            }else if(data.type=="failed_avisage"){
                toolkit.handleFailedAvisageFound(data);
            }else{
                console.log("failed to incorrect barcode")
                $state.go("barcode_incorrect");
            }
        }, function(){
            $state.go("barcode_incorrect");
        });
	};

    if($stateParams.user){
        $scope.input.value_1 = $stateParams.user;
	    nextClicked();
	}
});

