(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/information.html',
    '<div ng-controller="helpController"><h1 class="informationHeader" translate>{{\'informationtext.title\' | translate}}</h1><br><p class="information" translate>{{\'informationtext.receive\' | translate}}</p><br><p class="information" translate>{{\'informationtext.ship\' | translate}}</p><br><p class="information" translate>{{\'informationtext.return\' | translate}}</p><br><p class="information" translate>{{\'informationtext.rent\' | translate}}</p><br><h1 class="informationHeader" translate>{{\'informationtext.question_title\' | translate}}</h1><p class="information" translate>{{\'informationtext.question\' | translate}}</p></div>');
}]);
})();
