(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/transporter.html',
    '<div ng-controller="TransportersController"><div ng-hide="showtransport"><div class="row"><div class="col-md-12"><p class="txt-buren1-welcome" translate translate-values="{ title: driver.title }">{{\'transportertext.dear\'}}</p><p class="txt-buren1-parcels" translate translate-values="{{ translate_values }}">{{mainText()}}</p></div></div><div ng-if="(parcels.length === 1)"></div><div ng-if="(parcels.length > 1)"><div ng-if="(parcels.length === already_opened_count)"></div><div ng-if="(already_opened_count === 0)"></div><div ng-if="(parcels.length !== already_opened_count) && (already_opened_count !== 0)"></div></div></div></div>');
}]);
})();
