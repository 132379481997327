(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/partials/charge.html',
    '<div class="keyboard" ng-controller="defaultController"><div class="row"><div class="col-md-12"><h1 class="title-buren1" ng-show="$state.current.data.titleTextButtons.title" translate="{{$state.current.data.titleTextButtons.title}}"></h1><div class="col-md-1" style="padding-left: 5px !important"><button style="float: left" class="btn btn-keyboard" ng-disabled="cannot_less()" ng-click="subtract()">-</button></div><div class="col-md-5" style="padding-right: 5px; padding-left: 20px"><input style="height: 70px; border-radius: 10px; text-align: center; font-size: 32px; color: #99001A" id="txtCustomercode" class="form-control input-lg" value="{{amount_html()}}"></div><div class="col-md-1" style="padding: 0!important"><button class="btn btn-keyboard" ng-click="add()">+</button></div><div class="col-md-7" style="padding: 10px 0 0 0"><button style="margin-left: 5px" class="btn btn-buren4 col-md-12" ng-click="charge()"><i class="glyphicon glyphicon-triangle-right pull-right"></i> <span class="pull-left ng-binding footer-button">Opladen</span></button></div></div></div></div>');
}]);
})();
