(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/bighead/doggy_bone.html',
    '<div class="show-bighead" ng-controller="blankController" style="position:absolute; z-index:2; top:-120px; right:250px" draggable="true"><img src="images/deburen_graphics/dog_2.png" style="position:absolute; width:150px; top:25px; left:36px" draggable="false"> <img src="images/deburen_graphics/bone_2.png" style="position:absolute; width:75px; top:120px; right:-50px" draggable="false"></div>');
}]);
})();
