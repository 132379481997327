(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/mine/new.html',
    '<h1>New</h1><input ng-model="inputvalue1"> <input ng-model="inputvalue2"> <button ng-click="mine()">Mine!!1</button><br><button ng-click="mine2()">Mine!!2</button><br><button ng-click="login()">login</button><br><button ng-click="check()">check</button><br><button ng-click="logout()">Logout</button><div>{{woei}}</div><div>{{$state.current.data.testvar}}</div>');
}]);
})();
