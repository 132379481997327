var toolkitModule = angular.module('toolkitModule', ['storeModule', 'connectionModule', 'hardwareModule']);

toolkitModule.provider('toolkit', function() {
    var closeCount = 0;
    var rent;
    var fullReload = false;
    this.$get = function (store, $state, hardware, connection, FRONTEND_URL, $wait, $translate) {
        return {
            formatLockerNumber: function(number) {
                var result = number.slice(2);
                if(store.getTerminalConfig().type_locker) {
                    result = number.slice(0, 2) + "." + result;
                }
                return result;
            },
            passwordify: function(password){
                var result = "";
                for(var i=0;i<password.length-1;++i){
                    result += "*";
                }
                result += password.substr(-1);
                return result;
            },
            setRent: function(rentModule){
                rent = rentModule;
            },
            close_soft: function(){
                fullReload = true;
                if($state&&$state.current&&$state.current.name=='home'){
                    this.close();
                }
            },
            close: function(){
                // $translate.refresh();
                $translate.use(store.getTerminalConfig().language || 'nl_NL');
                $wait.clear();
                hardware.abortSweep();
                connection("/api/login/logout");
                store.purge();
                if(rent)
                    rent.purge();
                if(++closeCount>20||fullReload){
                    window.location = FRONTEND_URL;
                }else{
                    $state.go('home');
                }
            },
            isSweeping: function() {
                _this = this;
                hardware.isSweeping().then(function(result) {
                    console.log(result);
                    if( result === false ) {
                        _this.close();
                    }
                });
            },
            openLocker: function(parcel){
                var process = store.getProcess()
                locker_data = {parcel_id: parcel.parcel_id}
                var deliveryType = 'retrieval';
                if(process.type){
                    locker_data.opentype = process.opentype;
                    deliveryType = 'delivery';
                }
                if(parcel.locker_id) {
                    hardware.openLocker(parcel.module_id, parcel.address_id, deliveryType,
                        locker_data).then(function (data2) {
                        if (data2) { // Redirect

                            var settings = store.getTerminalConfig();
                            if(!settings.type_locker) {
                                var locker_number = parcel.locker_number.slice(2);
                            }else{
                                var locker_number = parcel.locker_number.slice(0, 2) + "." +
                                    parcel.locker_number.slice(2);
                            }

                            $state.go('locker_opened', {
                                delivery_partner_id: parcel.delivery_partner_id,
                                locker_number: locker_number
                            });
                        } else {
                            console.log(['Kluis is niet open gegaan, why?', data, data2]);
                        }
                    });
                } else {
                    $state.go("bigger_locker", {direct: true});
                }
            },
            handleFailedAvisageFound: function(data){
                $state.go('avisage_retrieve_postal', {'location': data.location});
            },
            handleDeliveryFound: function(data){
                console.log(["handleDeliveryFound", data]);
                if(data.driver){
                    store.setDriver(data.driver);
                }

                store.setProcess({type:'delivery', opentype:'reserved', avisage: data['avisage']}); // TODO: moet dit wel 'reserved' zijn? Of variabel?
                store.setDeliveryBarcode(null); // Reset

                if (data.state === 'gologin') { // We should be loggedon (transportdriver)
                    store.setDeliveryBarcode(data.barcode);
                    $state.go("required_login_to_fill");
                } else if (data.state === 'goaway') { // Partner and barcode do not match (should have been delivered by an other partner)
                    $state.go("delivery_incorrect");
                } else if (data.state === 'clearout') { // Partner has parcels to empty
                    store.setProcess({type: null, opentype: 'reserved'});
                    $state.go("required_empty_expired_parcels");
                } else if (data.state === 'so_full') {
                    $state.go("no_locker_available");
                } else { // Deliver parcels
                    if( data.delivery_type == "external"){
                        store.setDeliveryExternal(data.data);
                        // Meerdere parcels gevonden die gevuld moeten worden, onder dezelfde barcode, of parcel is minder dan 10 minuten geleden al gevuld.
                        if(data.parcels&&data.parcels.length>0){
                            store.setDeliveryParcels(data.parcels);
                            $state.go("same_barcode_options_external");
                        }else{
                            if (!data.data.customer_email) {
                                $state.go("notify_sender_external");
                            } else {
                                $state.go("external_locker_choose");
                            }
                        }
                    } else if (data.parcels) {
                        if (data.parcels.length === 0) {
                            $state.go("delivery_incorrect");
                            return;
                        }

                        store.setDeliveryParcels(data.parcels);
                        if (data.parcels[0].already_filled) {
                            var allFilled = true;
                            for(var i=0;i<data.parcels.length && allFilled;++i){
                                allFilled = data.parcels[i].already_filled;
                            }
                            if(allFilled){
                                $state.go("locker_options_fill");
                            } else {
                                $state.go("same_barcode_options");
                            }
                        } else {
                            this.openLocker(data.parcels[0]);
                        }
                    } else { // Geen parcels gevonden met deze barcode
                        $state.go("delivery_incorrect");
                    }
                }
            }
        };
    };
});
