var mainApp = angular.module('MainApp');


mainApp.controller('transporterOverviewController', function($scope, $state, connection, listener, store, toolkit,
                                                             $rootScope) {
    listener($scope, {
        handleBarcodeEvent: function(barcode){
            connection("/api/scan", {code: barcode, types: ["delivery"]}).then(function(data){
                if(data.type=="delivery"){
	                data.barcode = $scope.barcode;
                    toolkit.handleDeliveryFound(data);
                }else if(data.type=="failed_avisage"){
                    toolkit.handleFailedAvisageFound(data);
                }else{
                    $state.go('delivery_incorrect');
                }
            });
        },
        handleFooterButton: function(actionCommand){
            if (actionCommand == 'avisage') {
                $state.go("scan_package_avisage");
            }
        }
    });
    if (store.getDriver() && store.getDriver().canAvisage) {
        $rootScope.$emit("footerburen.button.add", 'avisager');
    }
    console.log("store.getDriver()", store.getDriver());
});

mainApp.controller('FirstLoginScreenController', function(store, listener, $scope,
$rootScope, $state, $stateParams, $translate, toolkit,
        connection, hardware, filterFilter) {
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if (actionCommand == 'continue') {
                $state.go("transporter_login");
            }
        },
        handleBarcodeEvent: function(barcode){
            connection("/api/scan", {code: barcode, types: ["driver"]}).then(function(data){
                if(data.type=="driver"){
                    if(data.loggedin){
                        store.setDriver(data.driver, $state);
                    }else{
                        $state.go("transporter_login", {user: barcode, type:data.type})
                    }
                }
            });
        }
    });
});

mainApp.controller('FirstEmptyScreenController', function(store, listener, $scope,
$rootScope, $state, $stateParams, $translate, toolkit,
        connection, hardware, filterFilter) {
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if (actionCommand == 'continue') {
                $state.go("transporter_login");
            }
        },
        handleBarcodeEvent: function(barcode){
            connection("/api/scan", {code: barcode, types: ["driver"]}).then(function(data){
                if(data.type=="driver"){
                    if(data.loggedin){
                        store.setDriver(data.driver, $state);
                    }else{
                        $state.go("transporter_login", {user: barcode, type:data.type})
                    }
                }
            });
        }
    });
});



mainApp.controller('TransportersSosController', function($scope, $rootScope, $state, $stateParams, $translate, toolkit,
                                                          connection, store, listener, hardware, filterFilter) {
    $scope.$stateParams = $stateParams;

    listener($scope, {
        handleBarcodeEvent: function(barcode){
            if (barcode == "S12") {
                $scope.openLocker();
            }
        }
    });

    $scope.openLocker = function() {
        console.log("$state.params.autoopen", $state.params.autoopen);
        var parcel = store.getParcel();
        var process = store.getProcess();
        var locker_data = {parcel_id: parcel.parcel_id};
        var deliveryType = 'retrieval';
        if (process.type) {
            locker_data.opentype = process.opentype;
            deliveryType = 'delivery';
        }
        hardware.openLocker(parcel.module_id, parcel.address_id, deliveryType, locker_data).then(function (data) {
            if (data) { // Redirect
                var locker_number = toolkit.formatLockerNumber(parcel.locker_number);
                $state.go('locker_opened', {
                    show_bigger_locker: 0, locker_number: locker_number, autoopen: $state.params.autoopen,
                    message_status: $stateParams.message_status, message_next_locker: $stateParams.next_locker
                });
            } else {
                // TODO
                console.log(data);
            }
        }, function (data) {
            // TODO
            console.log(data);
        });
    };
});


mainApp.controller('TransportersNameController', function($scope, $rootScope, $state, $stateParams, $translate, toolkit,
                                                      connection, store, listener, hardware, filterFilter) {
    $scope.driver = store.getDriver();
    $scope.translate_values = {"title": $scope.driver.title};
});

mainApp.controller('TransportersController', function($scope, $rootScope, $state, $stateParams, $translate, toolkit,
        connection, store, listener, hardware, filterFilter) {
    $scope.$state = $state;
    $scope.$stateParams = $stateParams;
    $scope.parcels = [];
    $scope.parcels_on_page = [];
    $scope.start_parcel_index = 0;
    $scope.max_items_on_page = 16;
    $scope.already_opened_count = 0;

    $scope.driver = store.getDriver();

    $scope.mainText = function(){
        var result = 'transportertext.no_parcels_transporter';
        var parcels = $scope.parcels;
        var already_opened_count = $scope.already_opened_count;
        if(parcels.length==0){
            if(($scope.driver['role']&1)==0){
                result ='transportertext.nothing_todo';
            }else if(($scope.driver['role']&1)==1){
                result ='transportertext.only_deliver_parcels';
            }else{
                result ='transportertext.no_parcels_transporter';
            }
        }else if((parcels.length === 1) && (already_opened_count === 0)){
            result ='transportertext.one_parcel_transporter';
        } else if((parcels.length === 1) && (already_opened_count === parcels.length)){
            result = 'transportertext.one_parcel_emptied';
        } else if((parcels.length > 1) && (already_opened_count === 0)){
            result = 'transportertext.more_parcels_transporter';
        }else if((parcels.length > 1) && (parcels.length !== already_opened_count) && (already_opened_count !== 0)){

        } else if((parcels.length > 1) && (parcels.length === already_opened_count)){
            result = 'transportertext.all_parcels_emptied';
        }
        return result;
    };

    if($stateParams.user){
        $scope.input.value_1 = $stateParams.user;
        nextClicked();
    }

    var fetchParcels = function() {
        connection("/api/retrieve/parcels").then(function(data) {
            $scope.parcels = data;
            store.setParcels(data);

            $scope.already_opened_count = 0;
            angular.forEach($scope.parcels, function(parcel) {
                if (parcel.already_opened) { $scope.already_opened_count++; }
            });

            // Redirect to locker_options_emptied_filled
            if ($scope.parcels.length > 1 &&
                    $scope.parcels.length !== $scope.already_opened_count &&
                    $scope.already_opened_count !== 0) {

                $state.go('locker_options_emptied_filled');
            }

            if($scope.parcels.length-$scope.already_opened_count>1){
                $scope.leftOverride = "lefttext.more_parcel_to_be_emptied";
                if(!$scope.translate_values)
                    $scope.translate_values = {};
                $scope.translate_values.parcels_length = $scope.parcels.length-$scope.already_opened_count;
            }

            showParcels();
        }, function(data) {
            // TODO
            console.log(data);
        });
    };

    var showParcels = function() {
        if($scope.parcels.length==0){
            if (($scope.driver['role']&1)==0) {
                $rootScope.$emit("footerburen.button.remove", 'continuer');
            }
        }else{
            if (($scope.driver['role']&2)==0) {
                $rootScope.$emit("footerburen.button.remove", 'continuer');
            }
        }
        if (($scope.driver['role'])==7) {
            $rootScope.$emit("footerburen.button.add", 'backl');
        }
        var stopIndex = ($scope.start_parcel_index + $scope.max_items_on_page);
        $scope.parcels_on_page = $scope.parcels.slice($scope.start_parcel_index, stopIndex);
        for (var i=0;i<$scope.parcels_on_page.length;++i) {
            $scope.parcels_on_page[i].formattedLockerNumber =
                toolkit.formatLockerNumber($scope.parcels_on_page[i].locker_number);
        }
        $scope.options_parcel = false;
        angular.forEach($scope.parcels, function(item) {
            if (item.already_opened) { $scope.options_parcel = item; }
        });

        if (!$scope.translate_values) {
            $scope.translate_values = {};
        }
        if ($scope.options_parcel) {
            $scope.translate_values.locker_number = toolkit.formatLockerNumber($scope.options_parcel.locker_number);
        }
        //console.log($scope.parcels_on_page);
    };

    $scope.nav = function(new_start_index) {
        $scope.start_parcel_index = new_start_index;
        showParcels();
    };
    fetchParcels();

    listener($scope, {
        handleActionButton: function(actionCommand) {
            var parcel = store.getParcel();
            var message = "";
            if (actionCommand == 'locker_opened') {
                if(parcel['not_collected']) {
                    message = '"' + $translate.instant('maintext.message_not_pickedup') + '"';
                }
                $scope.openLocker(parcel, message, $translate.instant('maintext.message_next_locker_transporter'));
            } else if (actionCommand == 'locker_next_opened') {
                // Find first parcel that has not been opened yet
                parcel = false;
                angular.forEach($scope.parcels, function(item) {
                    if (!item.already_opened && !parcel) { parcel = item; }
                });
                if(parcel['not_collected']) {
                    message = '"' + $translate.instant('maintext.message_not_pickedup') + '"';
                }
                var next_locker = $translate.instant('maintext.message_next_locker_transporter');
                $scope.openLocker(parcel, message, next_locker, true);
            } else if (actionCommand == 'locker_options') {
                parcel = $scope.options_parcel;
                $scope.lockerOptions(parcel);
            } else if (actionCommand == 'locker_overview') {
                $state.go("my_lockers_transporter");
            } else if (actionCommand == 'locker_empty') {
                $scope.isEmpty(parcel);
            }
        },

        handleFooterButton: function(actionCommand){
            if (actionCommand == 'continue') {
                if ($scope.parcels.length === 1) {
                    if ($scope.already_opened_count === 0) { // 1 ongeopend pakket: ga direct door naar open pagina
                        var message = "";
                        if($scope.parcels[0]['not_collected']) {
                            message = '"' + $translate.instant('maintext.message_not_pickedup') + '"';
                        }
                        var next_locker = $translate.instant('maintext.message_next_locker_transporter');
                        $scope.openLocker($scope.parcels[0], message, next_locker, true);
                    } else if ($scope.already_opened_count === $scope.parcels.length) { // 1 geopend pakket: ga direct door naar optie pagina
                        $scope.lockerOptions($scope.parcels[0]);
                    }

                } else if ($scope.parcels.length > 1) {
                    if ($scope.parcels.length === $scope.already_opened_count) {
                        // TODO: >1 pakket, allemaal geopend: ga naar overzicht pagina
                        $state.go('my_lockers_transporter');
                    } else if ($scope.already_opened_count === 0) {
                        // TODO: >1 pakket, allemaal ongeopend: ga naar overzicht pagina

                        var message = "";
                        if($scope.parcels[0]['not_collected']) {
                            message = '"' + $translate.instant('maintext.message_not_pickedup') + '"';
                        }
                        
                        var next_locker = $translate.instant('maintext.message_next_locker_transporter');
                        $scope.openLocker($scope.parcels[0], message, next_locker, true);
                    } else { // TODO: >1 pakket, ongeopend en geopend: ga naar optie pagina
                        console.log('geopend en ongeopend')
                    }

                } else {
                    $state.go('scan_package');
                }
            }else if (actionCommand == 'options') {
                $state.go('locker_options');
            }else if(actionCommand=='open_lockers'){
                $state.go("my_lockers_transporter");
            }
        },
        handleBarcodeEvent: function(barcode){
            connection("/api/scan", {code: barcode, types: ["driver"]}).then(function(data){
                if(data.type=="driver"){
                    if(data.loggedin){
                        store.setDriver(data.driver, $state);
                    }else{
                        $state.go("transporter_login", {user: barcode, type:data.type})
                    }
                }
            });
        }
    });

    var nextClicked = function(){
        $scope.showtransport = !$scope.showtransport;
    };

    $scope.lockerOptions = function(parcel) {
        //console.log(parcel);

        store.setParcel(parcel);
        $state.go('locker_options', { locker_number: toolkit.formatLockerNumber(parcel.locker_number)});

    };

    $scope.openLocker = function(parcel, message_status, next_locker, newOpen) {
        store.setParcel(parcel);
        var autoopen = $scope.driver.autoOpen;
        var avisageText = ( $scope.driver.canAvisage ) ? $translate.instant('maintext.notify_about_avisage_transporter') : "";
        if(newOpen && parcel.sos){
            $state.go('transporter_sos', {message_status: message_status, next_locker: next_locker,
                autoopen: autoopen});
        } else {
            var process = store.getProcess();
            var locker_data = {parcel_id: parcel.parcel_id};
            var deliveryType = 'retrieval';
            if (process.type) {
                locker_data.opentype = process.opentype;
                deliveryType = 'delivery';
            }
            hardware.openLocker(parcel.module_id, parcel.address_id, deliveryType, locker_data).then(function (data) {
                if (data) { // Redirect
                    var locker_number = toolkit.formatLockerNumber(parcel.locker_number);
                    $state.go('locker_opened', {
                        show_bigger_locker: 0, locker_number: locker_number,
                        message_status: message_status, message_next_locker: next_locker,
                        notify_about_avisage: avisageText, autoopen: autoopen
                    });
                } else {
                    // TODO
                    console.log(data);
                }
            }, function (data) {
                // TODO
                console.log(data);
            });
        }
    };

    $scope.isEmpty = function(parcel) {

        connection("/api/deliver/add_remark", {parcel_id: parcel.parcel_id, remark: "locker_empty"})
        .then(function(data){
            if(data){
                toolkit.close();

                $state.go('locker_empty', {});
            }else{
                console.log("Failed to set status :(");
            }
            //console.log("data", data);
        });

        // connection("/api/retrieve/empty", {parcel_id: parcel.parcel_id}).then(function(data){
        //     if (data) { // Redirect
        //         $state.go('locker_opened', { locker_number:parcel.locker_number });
        //     } else {
        //         // TODO
        //         console.log(data);
        //     }
        // }, function(data){
        //     //failure
        //     console.log(data);
        // });
    }
});

