var mainApp = angular.module('MainApp');

mainApp.controller('keyboardController', function($scope, $rootScope, $state, store) {

    $scope.quertyFunction = function(){
        var settings = store.getTerminalConfig();
        return settings && settings.keyboard;
    }
    $scope.querty = $scope.quertyFunction();

    $scope.$watch($scope.quertyFunction, function() {
        $scope.querty = $scope.quertyFunction();
    });

	$scope.reset = function() {
		$scope.input = { value: '' };
	};

	$scope.set = function(value) {
		$scope.input = { value: value };
	};

    $scope.$on('$destroy',
        $rootScope.$on("keyboard.set", function(event, args) {
            $scope.set(args.value);
        })
    );

    $scope.keypress = function(key) {
		if (key === '[BACK]') {
			$scope.input.value = $scope.input.value.substr(0, $scope.input.value.length-1);
		} else {
			$scope.input.value += key;
		}
		if ($scope.$parent.keyboard_input) {
			$scope.$parent.keyboard_input($scope.input.value);
		}
	};

    $scope.$on('$destroy',
            $rootScope.$on("keyboard.reset", function(event, args) {
                $scope.reset();
            })
        );

	$scope.reset();
});

