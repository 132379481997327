(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/bighead/speak_arm_bend_man_dog.html',
    '<div class="show-bighead" ng-controller="blankController" style="position:absolute; z-index:2; top:50px" draggable="true"><div class="bubble btm-right-in" style="top:10px; left:15px; min-height: 100px; min-width: 275px; padding: 50px; font-size: 52px" translate="{{$state.current.data.bigheadText}}"></div><img src="images/deburen_graphics/man_2.png" style="position:absolute; width:250px; top:190px; left:75px" draggable="false"> <img src="images/deburen_graphics/dog_2.png" style="position:absolute; width:120px; top:500px; left:280px" draggable="false"></div>');
}]);
})();
