(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/templates/text.html',
    '<div ng-controller="blankController" style="position: absolute; top: 70px"><p translate-values="{{ translate_values || $stateParams }}" translate="{{$state.current.data.maintext}}" class="txt-buren1-normal"></p></div>');
}]);
})();
