(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/templates/lefttext.html',
    '<div ng-controller="blankController"><p translate-values="{{ translate_values }}" translate="{{leftOverride?leftOverride:$state.current.data.lefttext}}" style="padding:10px 15px 0 35px; margin-top: 50px; font-size: 35px; line-height: 42px"></p></div>');
}]);
})();
