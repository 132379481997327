(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/barcode_deliver_input.html',
    '<div><div ng-if="$state.current.data.input_1" style="overflow:hidden"><div class="pull-left" style="padding:20px 30px 10px 30px"><label id="receivecode" class="{{$state.current.data.input_1.class_label}}" translate="{{$state.current.data.input_1.text}}"></label></div><div class="pull-right" style="width:700px; background-color:#ccc; border-radius: 20px 20px 0 0; padding:20px 30px 10px 30px"><input id="txtReceivecode" type="{{$state.current.data.input_1.type}}" class="form-control input-lg" value="" ng-model="input.value_1"></div></div><div ng-controller="keyboardController as keyboard"><div ng-show="querty" class="keyboard-fluid" style="background-color:#cccccc; border-radius: 20px 0 20px 20px; border:1px solid #cccccc; padding:30px"><div ng-include="\'views/partials/keyboard.html\'"></div></div><div ng-hide="querty" class="keyboard-fluid" style="background-color:#cccccc; border-radius: 20px 0 20px 20px; border:1px solid #cccccc; padding:30px"><div ng-include="\'views/partials/keyboard-azerty.html\'"></div></div></div><div ng-if="$state.current.data.input_2" style="overflow:hidden"><div class="pull-left" style="padding:20px 30px 10px 30px"><label id="scan_receivecode" class="{{$state.current.data.input_2.class_label}}" translate="{{$state.current.data.input_2.text}}"></label></div></div></div>');
}]);
})();
