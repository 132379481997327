var mainApp = angular.module('MainApp');

mainApp.directive('footerburen', function($state) {
    return {
        restrict: 'E',
        templateUrl: 'views/footerburen.html'
      };
});

mainApp.controller('footerController', function($scope, $state, $rootScope, listener, toolkit) {
    listener($scope, {
        stateChangeSuccess: function(){
            $scope.removed = [];
            $scope.added = [];
            $scope.currentButtons = [];
        },
        footerButtonsAdd: function(toAdd){
            if($scope.removed.indexOf(toAdd)!=-1){
                $scope.removed.splice($scope.removed.indexOf(toAdd), 1);
            }else if($scope.added.indexOf(toAdd)==-1){
                $scope.added.push(toAdd);
            }
        },
        footerButtonsRemove: function(toRemove){
            if($scope.added.indexOf(toRemove)!=-1){
                $scope.added.splice($scope.added.indexOf(toRemove), 1);
            }else if($scope.removed.indexOf(toRemove)==-1){
                $scope.removed.push(toRemove);
            }
            $scope.currentButtons = [];
        }
    });
    $scope.removed = [];
    $scope.added = [];
    $scope.currentButtons = [];
    $scope.buttons = function(){
        for(var i in $scope.added){
            var button = $scope.added[i];
            if($scope.currentButtons.indexOf(button)==-1){
                $scope.currentButtons.push(button);
            }
        }
        if($state.current&&$state.current.data&&$state.current.data.buttons){
            for(var i in $state.current.data.buttons){
                var button = $state.current.data.buttons[i];
                if($scope.currentButtons.indexOf(button)==-1&&$scope.removed.indexOf(button)==-1){
                    $scope.currentButtons.push(button);
                }
            }
        }
        return $scope.currentButtons;
    };

    $scope.clickButton = function(actionCommand){
	    switch (actionCommand) {
		    case 'close': toolkit.close(); break;
	    }
        $rootScope.$emit("footerburen.button.clicked", actionCommand);
    };
});