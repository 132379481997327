(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/scan_or_input.html',
    '<h1 translate>Maak een keuze</h1><button class="btn btn-buren1 btn-block" ui-sref="retrieve_by_customer"><span class="pull-left">klantnummer invoeren </span><i class="glyphicon glyphicon-triangle-right pull-right"></i></button> <button class="btn btn-buren1 btn-block" ui-sref="retrieve_by_code"><span class="pull-left">haalcode invoeren </span><i class="glyphicon glyphicon-triangle-right pull-right"></i></button>');
}]);
})();
