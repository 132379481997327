var pushModule = angular.module('waitModule', []);

pushModule.provider('$wait', function() {
    waitingCount = 0;
    stacks = [];
    this.$get = function ($rootScope) {
        return {
            isWaiting: function(){
                return waitingCount>0;
            },
            start: function(){
                stacks.push(Error().stack);
                $rootScope.$emit("buren.debug", "wait");
                if(waitingCount==0){
                    $rootScope.$emit("buren.wait", true);
                }
                ++waitingCount;
            },
            getStacks: function(){
                return stacks;
            },
            clear: function(){
                if(stacks.length!=0){
                    console.log(stacks);
                    stacks = [];
                }
                waitingCount = 0;
                $rootScope.$emit("buren.wait", false);
            },
            stop: function(){
                --waitingCount;
                if(waitingCount<=0){
                    stacks = [];
                    waitingCount = 0;
                    $rootScope.$emit("buren.wait", false);
                }
            }
        };
    };
});