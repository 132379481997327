(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/days_choose.html',
    '<div class="keyboard" ng-controller="defaultController"><div class="row"><div class="col-md-12"><h1 class="title-buren1" ng-show="$state.current.data.titleTextButtons.title" translate="{{$state.current.data.titleTextButtons.title}}"></h1><div class="col-md-7" style="/*background-color: #C3A802;*/ background: rgba(00, 00, 00, 0.20); height: 90px; padding-left: 30px; padding-top: 10px; border-radius: 20px"><div style="padding: 0!important"><button style="float: left" class="btn btn-keyboard" ng-disabled="cannot_less()" ng-click="subtract()">-</button></div><div class="col-md-7"><input style="height: 70px; border-radius: 10px; text-align: center; font-size: 32px; color: #99001A" id="txtCustomercode" class="form-control input-lg" value="{{days}}"></div><div style="padding: 0!important"><button class="btn btn-keyboard" ng-click="add()">+</button></div></div></div><div class="col-md-12" style="margin-top: 30px; margin-bottom: 30px"><p style="font-size: 30px; line-height: 30px">van {{startDate | date : \'dd-MM-yyyy HH:mm\'}}</p><p class="red">tot {{endDate() | date : \'dd-MM-yyyy HH:mm\'}}</p></div><div class="col-md-12"><p>Totale kosten</p><p style="font-size: 30px; line-height: 10px">{{totalRent | toCurrency}}</p></div></div></div>');
}]);
})();
