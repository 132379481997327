(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/rent_overview.html',
    '<div class="col-md-11" style="margin-left: 50px; margin-top: 50px"><table style="width: 750px"><tr class="overview"><td translate>{{\'maintext.locker_number\' | translate}}:</td><td>{{getChosenLocker}}</td></tr><tr class="overview"><td translate>{{\'maintext.locker_period\' | translate}}:</td><td>Van {{startDate | date : \'dd-MM-yyyy HH:mm\'}}<br>Tot {{endDate | date : \'dd-MM-yyyy HH:mm\'}}</td></tr><tr ng-if="getReceiver.mail || getReceiver.number" class="overview"><td translate>{{\'maintext.rent_userinfo\' | translate}}:</td><td>{{getReceiver.mail}}<br>{{getReceiver.number}}</td></tr><tr ng-if="from" class="overview"><td translate>{{\'maintext.rent_userinfo\' | translate}}:</td><td>Klantnummer {{from}}</td></tr><tr ng-if="getReceiver.user" class="overview"><td translate>{{\'maintext.rent_receiver\' | translate}}:</td><td>Klantnummer {{getReceiver.user}}</td></tr><tr class="overview"><td translate>{{\'maintext.notification\' | translate}}:</td><td>{{notification}}</td></tr><tr class="overview"><td translate>{{\'maintext.total_costs\' | translate}}:</td><td>€ {{getTotalCost}}</td></tr></table></div>');
}]);
})();
