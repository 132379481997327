'use strict';
//
// function versionAllowDrop(ev) {
// 	ev.preventDefault();
// }
//
// function versionDrag(ev) {
// 	ev.dataTransfer.setData("text", ev.target.id);
// }
//
// function versionDrop(ev) {
// 	ev.preventDefault();
// 	var data = ev.dataTransfer.getData("text");
// 	if (data === 'versionSource') { // Show version popup
// 		var $scope = angular.element('[ng-controller=bodyController]').scope();
// 		$scope.showVersionWindow(true);
// 		$scope.$apply();
// 	}
// 	//	ev.target.appendChild(document.getElementById(data));
// }

// Lastly, define your "main" module and inject all other modules as dependencies
var mainapp = angular.module('MainApp', [
		'listenerModule',
		'connectionModule',
		'hardwareModule',
		'pushModule',
		'storeModule',
		'toolkitModule',
		'ui.router',
		'ngCookies',
		'ngWebSocket',
		'emulationModule',
		'pascalprecht.translate',
		'rentModule',
		'waitModule'
	]);

mainapp.factory('$exceptionHandler', ['$log', '$injector', '$window', function($log, $injector, $window) {
    var hardware;
    return function log(exception, cause) {
        hardware = hardware || $injector.get('hardware');
        $log.error.apply( $log, arguments );
        try {
            var args = [];
            if (typeof arguments === 'object') {
                for(var i = 0; i < arguments.length; i++ ) {
                    var exceptionItem = {};
                    exceptionItem.message = arguments[i].message;
                    exceptionItem.stack = arguments[i].stack;
                    args.push(JSON.stringify(exceptionItem));
                }
            }
            hardware.logdump(JSON.stringify({"exception": exception, "cause": cause, "args": args,
                    "url": $window.location.href}));
        } catch ( loggingError ) {
            // For Developers - log the log-failure.
            $log.warn( "Error logging failed" );
            $log.log( loggingError );
        }
    };
}]);

mainapp.config(["$translateProvider", function ($translateProvider) {
    // add translation table
    $translateProvider.useStaticFilesLoader({
        prefix: 'translate/',
        suffix: '.json'
    });
    $translateProvider.preferredLanguage('nl_NL');
    $translateProvider.fallbackLanguage('nl_NL');
    $translateProvider.useSanitizeValueStrategy('escapeParameters');
}]);

mainapp.filter('toCurrency', function($translate) {
	return function(intval) {
		var places = 2; //!isNaN(places = Math.abs(places)) ? places : 2;
		var symbol = $translate.instant('currency.symbol');
		var thousand = $translate.instant('currency.thousand');
		var decimal = $translate.instant('currency.decimal');

		var number = intval/100,
			negative = number < 0 ? "-" : "",
			i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
			j = (j = i.length) > 3 ? j % 3 : 0;
		return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
	};
});

mainapp.filter('formatLockerNumber', function(toolkit) {
    return function(lockerNumber) {
        return toolkit.formatLockerNumber(lockerNumber);
    };
});
//mainapp.controller('HeaderCtrl', function($translate, $scope, connection, hardware, emulation, push) {
mainapp.controller('HeaderCtrl', function($translate, $scope, $rootScope, connection, hardware, emulation, store,
        $state) {
    $scope.emulation = emulation;
    var ca = document.cookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') {
            c = c.substring(1);
        }
        if (c.indexOf("run") == 0) {
            $scope.emulation.toggleEnabled(true);
        }
    }

    $scope.scanDriver = function(code) {
		$rootScope.$emit('hardware.barcode', code);
		console.log('scanning drivercode');
	};

//    push.test();
    hardware.start();
	$scope.setLang = function (locale) {
        $translate.use(locale);
	};

    $scope.active = function(lang) {
        return lang == $translate.use();
    };

    $scope.viezehack = function() {
        if($state.current.name=="home") {
            $state.go('transporter_login');
        }
    };

    var langCache = {"lang": "", "result": []};
    $scope.langOrdered = function(){
        var curLang = store.getTerminalConfig();

        if(curLang){
            curLang = curLang.language;
        }
        if(!curLang){
            curLang = "nl_NL";
        }

        if(langCache.lang != curLang) {
            langCache.lang = curLang;

            //choose cubee design if default lang is not dutch
            //todo strpos
            if(curLang!='nl_NL'){ angular.element('body').addClass('cubee-design'); }

            if(curLang=='nl_NL'||curLang=='nl_BE'){
                langCache.result = [{"text": "NL", "code": "nl_BE"}, {"text": "FR", "code": "fr_BE"}, {"text": "EN", "code": "en_US"}];
            }else{
                langCache.result = [{"text": "FR", "code": "fr_BE"}, {"text": "NL", "code": "nl_BE"}, {"text": "EN", "code": "en_US"}];
            }
        }
        return langCache.result;
    }

});
