var mainApp = angular.module('MainApp');
var sweepTimeout = 60 * 60 * 1000;

mainApp.controller('sweepLoginController', function($scope, $state, $rootScope, 
        store, listener, hardware, toolkit) {
    $scope.settings = store.getTerminalConfig();
    $scope.$state = $state;
    $scope.input = { value_1: ''};
    $scope.inCorrectPin = 0;

    $scope.keyboard_input = function(value) {
        $scope.input['value_1'] = value;
    };

    var login = function() {
        hardware.sweepLogin($scope.input['value_1']).then(function(data){
            if( data === true ) {
                hardware.getSweepLockers().then(function(data){
                    store.setSweepModules(data.lockers);
                    $state.go('sweep-list');
                });
            } else {
                $scope.input['value_1'] = "";
                $rootScope.$emit("keyboard.reset");
                if(++$scope.inCorrectPin > 2) {
                    toolkit.close();
                }
            }
        });
    };

    listener($scope, {
        handleFooterButton: function(actionCommand){
            if( actionCommand === 'continue' ) {
                login();
            }
        },
        handleWebsocket: function(result){
            if( result ) {
                toolkit.isSweeping();
            }
        },
        homeTimeout: sweepTimeout
    });
});

mainApp.controller('sweepListController', function($scope, $state, store,
        listener, hardware, toolkit) {
    $scope.$state = $state;
    $scope.modules = [];
    $scope.items = [];
    $scope.start_index = 0;
    $scope.items_on_page = [];
    $scope.max_items_on_page = 16;

    $scope.type = store.getTerminalConfig().type_locker;

    hardware.getSweepLockers().then(function(data){
        store.setSweepModules(data.lockers);
        $scope.modules = store.getSweepModules();
        $scope.items = Object.keys($scope.modules);

        if( $scope.modules.length == 0 ) {
            toolkit.close();
        } else {
            showModules();
        }
    });

    var showModules = function() {
        var stopIndex = ($scope.start_index + $scope.max_items_on_page);
        $scope.items_on_page = $scope.items.slice($scope.start_index, stopIndex);
        for (var i=0;i<$scope.items_on_page.length;++i) {
            var module = $scope.modules[$scope.items_on_page[i]];
            if( !$scope.type ) {
                
                var first = module[Object.keys(module)[0]].number;
                var last = module[Object.keys(module)[module.length-1]].number;

                $scope.items_on_page[i] = { 'value': first + "-" + last };
            } else {
                $scope.items_on_page[i] = { 'value': $scope.items_on_page[i] };
            }
        }
    };

    $scope.nav = function(new_start_index) {
        $scope.start_index = new_start_index;
        showModules();
    };

    listener($scope, {
        handleWebsocket: function(result){
            if( result ) {
                toolkit.isSweeping();
            }
        },
        homeTimeout: sweepTimeout
    });

    $scope.clickOption = function(module) {
        $state.go('sweep-opened', {'module': module});
    };
});

mainApp.controller('sweepOpenedController', function($scope, $state, $stateParams, toolkit, store,
        listener, hardware, $rootScope) {
    $scope.$state = $state;
    $scope.module = $stateParams.module;
    $scope.modules = store.getSweepModules();
    $scope.lockers = $scope.modules[$scope.module];
    
    $scope.items = Object.keys($scope.lockers);
    $scope.start_index = 0;
    $scope.items_on_page = [];
    $scope.max_items_on_page = 16;

    $scope.hasOpenLockers = false;
    
    var showLockers = function() {
        if( $scope.hasOpenLockers ) {
            $rootScope.$emit("footerburen.button.remove", 'continuer');
        } else {
            $rootScope.$emit("footerburen.button.add", 'continuer');
        }

        var stopIndex = ($scope.start_index + $scope.max_items_on_page);
        $scope.items_on_page = $scope.items.slice($scope.start_index, stopIndex);
        for (var i=0;i<$scope.items_on_page.length;++i) {
            var id = $scope.items_on_page[i];
            $scope.items_on_page[i] = {
                'value': $scope.lockers[id].number,
                'disabled': $scope.lockers[id]['status'] !== 'ls'
            };
        }
    };

    var getStatusLockers = function(result) {
        var lockers = {};
        for(var num in result.s){
            var data = [];
            for(var i=0;i<12;++i){
                var status = "";
                if((result.l[num]&(1<<i)) == 0)
                    status += 'l';
                if((result.s[num]&(1<<i)) != 0)
                    status += 's';
                data.push(status);
            }
            lockers[num] = data;
        }
        return lockers;
    };

    var setStatusLockers = function(data) {
        var statusLockers = getStatusLockers(data);
        $scope.hasOpenLockers = false;
        for( var k in $scope.lockers ) {
            var l = $scope.lockers[k];
            $scope.lockers[k]['status'] = statusLockers[l.module][l.address];

            if( $scope.lockers[k]['status'] !== 'ls' ) {
                $scope.hasOpenLockers = true;
            }
        }

        showLockers();
    };

    hardware.getStatus().then(function(data) {
        setStatusLockers(data);
    });

    $scope.nav = function(new_start_index) {
        $scope.start_index = new_start_index;
        showLockers();
    };

    listener($scope, {
        handleFooterButton: function(actionCommand){
            if( actionCommand === 'continue' ) {
                if( !$scope.hasOpenLockers ) {
                    $state.go('sweep-list');
                } else {
                    // TODO: Notify that not all lockers are closed
                }
            } else if( actionCommand === 'openall' ) {
                hardware.sweepOpenModule($scope.module);
            }
        },
        handleHardwareMessage: function(event) {
            if( event === 'locker_opened' || event === 'locker_closed' ) {
                hardware.getStatus().then(function(data) {
                    setStatusLockers(data);
                });
            }
        },
        handleWebsocket: function(result){
            if( result ) {
                toolkit.isSweeping();
            }
        },
        homeTimeout: sweepTimeout
    });

    $scope.clickOption = function(locker) {
        var l = $scope.lockers[locker];
        hardware.openLocker(parseInt(l.module, 10), parseInt(l.address, 10));
    };
});