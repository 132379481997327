(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/_locker_options.html',
    '<div class="row"><div class="col-md-5" ui-view="view0"><p translate="{{\'transportertext.options_locker_text\'}}" translate-values="{{$stateParams}}" style="padding:0 50px"></p></div><div class="col-md-7" ui-view="view1"><h1 translate>{{\'maintext.make_choice\'}}</h1><div ng-hide="showTransportOptions"><button class="btn btn-buren1 btn-block" ng-controller="TransportersController" ng-click="openLocker(parcel)"><span translate class="pull-left">{{\'transportertext.reopen\'}}</span><i class="glyphicon glyphicon-triangle-right pull-right"></i></button> <button class="btn btn-buren1 btn-block" ng-controller="TransportersController" ng-click="isEmpty(parcel)"><span translate class="pull-left">{{\'transportertext.empty\'}}</span><i class="glyphicon glyphicon-triangle-right pull-right"></i></button></div></div></div>');
}]);
})();
