var rentModule = angular.module('rentModule', ['ui.router', 'toolkitModule', 'hardwareModule']);

rentModule.provider('rent', function() {
    var state = null;
    var _from = null;
    var _chosenLocker = null;
    var _lockerCost = null;
    var _reservedLocker = null;
    var _totalCost = null;
    var _notification = "none";
    var _delivery_notification = "none";
    var _days = null;
    var _startDate = 0;
    var _wrongCount = 0;
    var _paying = false;
    var _receiver = {};
    var _anonymous = {};
    var _payed_by_card = false;
    this.$get = function (toolkit, hardware, $translate) {
        return {
            payed_by_card: function(){
                _payed_by_card = true;
            },
            is_payed_by_card: function(){
                return _payed_by_card;
            },
            checkStart: function(){
                if(state===null)
                    toolkit.close(true);
                else
                    angular.element('#customercode').hide();
                    console.log('state: ', state);
            },
            hasStarted: function(){
                return state!==null;
            },
            goError: function(page, $state){
                $state.go(page);
            },
            goBack: function(){
                if(!_paying){
                    if(state&&state.length>0)
                        state = state.substr(0, state.length-1);
                    else
                        this.purge();
                }
            },
            fromErrorToAnonymous: function($state){
                this.useUserReceiver(null);
                this.next($state);
            },
            next: function($state){
                var useState = state.replace(new RegExp("(s_)|(b__)|(m_)|(y_)|(q__)|(i_)", 'g'), "d");
                useState = useState.replace(new RegExp("(w_)|(x_)", 'g'), "n");

                //console.log([state, useState]);
                if(state.substr(-1)=="h"){
                    var hs = state.length-state.indexOf('h');
                    $state.go('help' + hs);
                }else if(_paying){
                    if(useState[0]=="A")
                        $state.go('rent_mustpay_a');
                    else
                        $state.go('rent_mustpay');
                }else if(state.length==1){// [AU]
                    $state.go('locker_choose');
                }else if(state.length==2){// [AU]l
                    $state.go('days_choose');
                }else if(state.length==3){// [AU]ld
                    $state.go('who_emptied');
                }else if(state.substr(-1)=="b"||state.substr(-1)=="m"){
                    $state.go('email_input');
                }else if(state.substr(-2)=="b_"||state.substr(-1)=="s"){
                    $state.go('mobile_input');
                }else if(state.substr(-1)=="q"||state.substr(-1)=="y"){
                    $state.go('email_input_haler');
                }else if(state.substr(-2)=="q_"||state.substr(-1)=="i"){
                    $state.go('mobile_input_haler');
                }else if(state.substr(-1)=="w"){
                    $state.go('email_input');
                }else if(state.substr(-1)=="x"){
                    $state.go('mobile_input');
                }else{// [AU]ld[OS].*
                    switch(useState){
                        case "UldS":
                        case "AldSdn":
                        case "AldOdnAd":
                        case "AldOdnU":
                        case "UldOAdn":
                        case "UldOUn":
                            $state.go('rent_overview');
                            break;
                        case "AldO":
                        case "AldS":
                            $state.go('rent_information_sender');
                            break;
                        case "UldO":
                        case "AldOdn":
                            $state.go('rent_information_receiver');
                            break;
                        case "UldOA":
                        case "AldOdnA":
                            $state.go('rent_information_receiver_anonymous');
                            break;
                        case "UldOAd":
                        case "UldOU":
                        case "AldSd":
                        case "AldOd":
                            $state.go('notify_sender');
                            break;
                        default:
                            console.log("Epic failure", useState, state);
                    }
                }
            },
            start: function(from){
                toolkit.setRent(this);
                this.purge();
                _from = from;
                if(from==false){
                    state = "A";
                }else{
                    state = "U";
                }
            },

            confirmRegistration: function(connection){
                console.log("getObject", this.getObject());
                var data = {
                    notify_deliverer_on_empty: _notification,
                    days: _days,
                    locker_id: _reservedLocker.locker_id,
                    price_day: _lockerCost,
                    rent: 1,
                    payed_by_card: _payed_by_card?1:0
                };
                if(state.substr(0, 1)=="U"){
                    data.user = _from;
                }else{
                    data.anonymous_email = _anonymous.email;
                    data.anonymous_sms = _anonymous.number;
                    data.anonymous_notification = _delivery_notification;
                }
                if(state.substr(1).indexOf("S")!=-1){
                    data.toUser = data.user;
                    data.anonymous_receiver_email = data.anonymous_email;
                    data.anonymous_receiver_sms = data.anonymous_sms;
                }else if(state.substr(1).indexOf("U")!=-1){
                    data.toUser = _receiver.user;
                }else{
                    data.anonymous_receiver_email = _receiver.email;
                    data.anonymous_receiver_sms = _receiver.number;
                }
                data.language = $translate.use();
                return connection('/api/deliver/confirm_reservation', data);
            },

            pickLocker: function(chosenLocker, lockerCost){
                _chosenLocker = chosenLocker;
                _lockerCost = lockerCost;
                state += "l";
            },
            setReservedLocker: function(reservedLocker, totalCost, days){
                _totalCost = totalCost;
                _reservedLocker = reservedLocker;
                _days = days;
                _startDate = Date.now();
                state+="d";
            },
            incrHelp: function(){
                if(state.substr(-1)=="h"){
                    var hs = state.length-state.indexOf('h');
                    if(hs==5){
                        state = state.substr(0, state.length-hs);
                    }else{
                        state += "h";
                    }
                }else{
                     state += "h";
                }
            },
            useUserReceiver: function(user){
                if(user==null){
                    state += "A";
                }else{
                    state += "U";
                    _receiver.user = user;
                }
            },
            setPickupSelf: function(self){
                state += self?"S":"O";
            },
            setSenderInformationType: function(type){
                if(type==="both"){
                    state += "b";
                    _delivery_notification = "emailandsms";
                }else if(type==="email"){
                    state += "m";
                    _delivery_notification = "email";
                }else{
                    state += "s";
                    _delivery_notification = "sms";
                }
            },
            setReceiverInformationType: function(type){
                if(type==="both"){
                    state += "q";
                }else if(type==="email"){
                    state += "y";
                }else{
                    state += "i";
                }
            },
            setMail: function(email){
                if(state.indexOf("Ald")!=-1&&(state.substr(4)=="b"||state.substr(4)=="m"||state.substr(-1)=="w")){
                    _anonymous.email = email;
                }else{
                    _receiver.email = email;
                }
                state += "_";
            },
            setMobile: function(number){
                if(state.indexOf("Ald")!=-1&&((state.substr(4)=="b_"||state.substr(4)=="s")||state.substr(-1)=="x")){
                    _anonymous.number = number;
                }else{
                    _receiver.number = number;
                }
                state += "_";
            },
            setSenderNotify: function(actionCommand){
                _notification = actionCommand;
                if(state.substr(0, 1)=="A"){
                    if(actionCommand=="emailandsms"&&!_anonymous.number){
                        state += "x";
                    }else if(actionCommand=="emailandsms"&&!_anonymous.email){
                        state += "w";
                    }else if(actionCommand=="sms"&&!_anonymous.number){
                        state += "x";
                    }else if(actionCommand=="email"&&!_anonymous.email){
                        state += "w";
                    }else{
                        state += "n";
                    }
                }else{
                    state += "n";
                }
            },
            purge: function(){
                state = null;
                _from = null;
                _chosenLocker = null;
                _lockerCost = null;
                _reservedLocker = null;
                _totalCost = null;
                _notification = "none";
                _delivery_notification = "none";
                _anonymous = {};
                _days = null;
                _startDate = 0;
                _paying = false;
                _receiver = {};
                _anonymous = {};
                _payed_by_card = false;
                _wrongCount = 0;
            },
            toManyWrong: function(){
                var result = _wrongCount==2;
                _wrongCount = result?0:_wrongCount+1;
                return result;
            },
            setPayment: function(paying){
                _paying = paying;
            },
            setDays: function(days){
                _days = days;
            },
            isPaying: function(){
                return _paying;
            },
            isPayed: function($state, connection, store){
                _paying = false;
                this.confirmRegistration(connection).then(function(result){
                    console.log("this.confirmRegistration", result);
                    hardware.openLocker(_reservedLocker.module_id, _reservedLocker.address_id, 'delivery',
                            {parcel_id: result, opentype:'rent'}).then(function(success){
                        var locker_number = toolkit.formatLockerNumber(_reservedLocker.locker_number);
                        if(success)
                            $state.go('rent_locker_opened',
                                {locker_number: locker_number});
                        else
                            console.log("failed to open :(");
                    }, function(){
                        // TODO: Kluis is niet open gegaan, wat te doen?
                        console.log("failed to open :(");
                    });
                });
            },
            getChosenLocker: function(){
                return _chosenLocker;
            },
            getLockerCost: function(){
                return _lockerCost;
            },
            getTotalCost: function(){
                return _totalCost;
            },
            getReservedLocker: function(){
                return _reservedLocker;
            },
            getFrom: function(){
                return _from;
            },
            getNotification: function(){
                return _notification;
            },
            getReceiver: function () {
                return _receiver;
            },
            getState: function () {
                return state;
            },
            getObject: function(){
                return [state, _from, _chosenLocker, _lockerCost, _reservedLocker, _totalCost, _notification, _paying,
                    _receiver, _anonymous];
            },
            getEndDate: function(){
                var result = Date.now();
                result /= (1000 * 60 * 60);
                result = Math.floor(result) + 1;
                result *= (1000 * 60 * 60);
                result = new Date(result);
                result.setDate(result.getDate()+_days);
                return result.getTime();
            }
        };
    };
});