(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/bighead/man_arm_bend.html',
    '<div class="show-bighead" ng-controller="blankController" style="position:absolute; z-index:2; top:50px" draggable="true"><img src="images/deburen_graphics/man_2.png" style="position:absolute; width:275px; top:120px; left:65px" draggable="false"></div>');
}]);
})();
