(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/stop_session.html',
    '<div class="row" style="margin-left: 10px"><div class="col-md-5" ui-view="view0"><div class="show-bighead" ng-controller="blankController" style="position:absolute; z-index:2; top:50px" draggable="true"><div class="bubble" style="top:-60px; left:0px; min-width:340px" translate>{{\'maintext.stop\' | translate}}</div><img src="images/deburen_graphics/man_3.png" style="position:absolute; width:423px; top:130px; left:50px" draggable="false"></div></div><div ng-hide="" class="col-md-7" ui-view="view1"><h1 translate>{{\'maintext.make_choice\' | translate}}</h1><button class="btn btn-buren1 btn-block" ng-controller="footerController" ng-click="clickButton(\'logout\')"><span class="pull-left" translate>{{\'button.yes_quit\' | translate}} </span><i class="glyphicon glyphicon-triangle-right pull-right"></i></button> <button class="btn btn-buren1 btn-block" ng-controller="footerController" ng-click="clickButton(\'stop\')"><span class="pull-left" translate>{{\'button.no_quit\' | translate}} </span><i class="glyphicon glyphicon-triangle-right pull-right"></i></button></div></div>');
}]);
})();
