var mainApp = angular.module('MainApp');

mainApp.controller('biggerLockerController', function($scope, $rootScope, $state, $stateParams, connection, store,
                    listener, hardware, toolkit) {
    listener($scope, {
        handleFooterButton: function(actionCommand){
            if (actionCommand == 'continue') {
                checkLocker();
            }
        }
    });

    $scope.clickButton = function(){
        $state.go('help1');
    }

	$scope.$state = $state;
    $scope.settings = store.getTerminalConfig();

    $scope.input_sub = ['','','','']
	$scope.input = { value_1: ''};
	var active_field = 'value_1';

    $scope.shouldBeDisabled = true;

    if ($scope.settings) {
        if ($scope.settings.type_locker == 0){
            $scope.shouldBeDisabled = false;
            var module = false;
        }
    }

	$scope.keyboard_input = function(value) {

        if(module == false){
            value = '00' + value;
        }

		if (active_field == 'value_1') {
		    if(value.length>4)
		        value = value.substr(0, 4);
			$scope.input[active_field] = value.toUpperCase();
			for(var i=0;i<4;++i){
                if(module == false && i == 0 || module == false && i == 1){
                    $scope.input_sub[i] = 0;
                }else{
                    $scope.input_sub[i] = i<value.length?value.substr(i,1):"";
                }
			}
		} else {
			$scope.input[active_field] = value;
		}
	};

	var checkLocker = function() {
        var parcels = store.getDeliveryParcels();
        var parcel = false;
        if($stateParams.direct){
            for(var i=0;i<parcels.length&&!parcel;++i){
                if(!parcels[i].already_filled){
                    parcel = parcels[i];
                }
            }
        }else{
            for(var i=0;i<parcels.length;++i){
                if(parcels[i].already_filled){
                    parcel = parcels[i];
                }
            }
        }
        if(parcel==false)
            toolkit.close();
        if($scope.input.value_1.length==4){
            connection("/api/deliver/bigger", {parcel_id: parcel.parcel_id, comparison_locker:$scope.input.value_1})
                    .then(function(data){
                if(data&&data.hasOwnProperty('address_id')){
                    parcel.address_id = data.address_id;
                    parcel.module_id = data.module_id;
                    parcel.locker_id = data.locker_id;
                    parcel.locker_number = data.locker_number;
                    var process = store.getProcess()
                    var locker_data = {parcel_id: parcel.parcel_id}
                    var deliveryType = 'retrieval';
                    if(process.type){
                        locker_data.opentype = process.opentype;
                        deliveryType = 'delivery';
                    }
                    hardware.openLocker(parcel.module_id, parcel.address_id, deliveryType, locker_data)
                            .then(function(data2) {
                        if (data2) { // Redirect
                            var locker_number = toolkit.formatLockerNumber(parcel.locker_number);
                            $state.go('locker_opened', {
                                locker_number: locker_number
                            });
                        } else {
                            console.log(['Kluis is niet open gegaan, why?', parcels, data2]);
                        }
                    });
                }else if(data===false){
                    $state.go("locker_unknown");
                }else if(data){
                    if (store.getProcess().avisage) {
                        connection('/api/deliver/avisage_postal', {parcel_id: parcel.parcel_id}).then(function(result) {
                            $state.go('avisage_delivery_postal', {location: result.location});
                        }, function() {
                            $state.go('avisage_impossible');
                        });
                    } else {
                        $state.go("locker_not_available");
                    }
                }
            }, function() {
                $state.go("locker_incorrect");
            });
        }else{
            $state.go("locker_incorrect");
        }
    };
});

