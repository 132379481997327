(function(module) {
try {
  module = angular.module('MainApp');
} catch (e) {
  module = angular.module('MainApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('views/sweep/list.html',
    '<div><div class="row"><div class="col-md-12"><p class="txt-buren1-welcome" translate="{{$state.current.data.welcome}}"></p><p class="txt-buren1-parcels" translate="{{$state.current.data.maintext}}"></p></div></div><div><div ng-if="items.length > 0"><div class="row"><div ng-repeat="col_index in [start_index, start_index+4, start_index+8, start_index+12]" class="col-md-3"><div ng-repeat="index in [col_index, col_index+1, col_index+2, col_index+3]"><button ng-if="(index < items.length)" ng-click="clickOption(items[index]);" ng-class="{\'disabled\': (items_on_page[index-start_index].disabled === true)}" class="btn btn-buren1 btn-block" style="text-align:center; width:90%; padding:6px 12px"><span>{{items_on_page[index-start_index].value}}</span> <i class="glyphicon glyphicon-triangle-right pull-right"></i></button></div></div></div><div ng-if="items.length > max_items_on_page" class="row"><div class="col-md-offset-6 col-md-6" style="text-align:center; margin-top: 15px"><button class="btn btn-buren3 pull-left" ng-click="nav(start_index - max_items_on_page);" ng-disabled="(start_index === 0)" style="font-size:26px !important"><i style="top: 5px" class="glyphicon glyphicon-triangle-left"></i> <span>{{\'maintext.previous_page\' | translate}}</span></button> <span style="display:inline-block; font-size:26px; line-height:56px" translate translate-values="{ start_nr:(start_index + 1), stop_nr:(start_index + items_on_page.length) }">{{\'maintext.locker_from_to\'}}</span> <button class="btn btn-buren3 pull-right" ng-click="nav(start_index + max_items_on_page);" ng-disabled="(start_index + max_items_on_page) >= items.length" style="font-size:26px !important"><span>{{\'maintext.next_page\' | translate}}</span> <i style="top: 5px" class="glyphicon glyphicon-triangle-right"></i></button></div></div></div></div></div>');
}]);
})();
