var mainApp = angular.module('MainApp');

mainApp.controller('customerLoginController', function($scope, $rootScope, $state, $stateParams, connection, store,
        listener, toolkit) {
    listener($scope, {
        handleFooterButton: function(actionCommand){
            console.log(actionCommand);
            if (actionCommand == 'next') {
                nextClicked();
            } else if (actionCommand == 'backForm') {
                backClicked();
            } else if (actionCommand == 'continue') {
                login();
            }
        },
        handleBarcodeEvent: function(barcode){
            connection("/api/scan", {code: barcode, types: ["customer", "driver"]}).then(function(data){
                if(data.type=="customer"){
                    if(data.loggedin){
                        store.setCustomer(data.customer);
                        $state.go('my_lockers');
                    }else{
                        $state.go("customercode_input", {user: barcode, type:data.type})
                    }
                }else if(data.type=="driver"){
                    if(data.loggedin){
                        store.setDriver(data.driver, $state);
                    }else{
                        $state.go("transporter_login", {user: barcode, type:data.type})
                    }
                }
            });
        }
    });

	$scope.$state = $state;
    $scope.input_2_title = $state.current.data.input_2.text;

	$scope.input = { value_1: '', value_2: '' };
	var active_field = 'value_1';
	$scope.password = "";

	$scope.keyboard_input = function(value) {
        if (active_field == 'value_1') {
            $scope.input[active_field] = value.toUpperCase();
        } else {
            $scope.input[active_field] = toolkit.passwordify(value);
            $scope.password = value;
        }
    };

    var backClicked = function() {
        //$rootScope.$emit("keyboard.reset");

        active_field = 'value_1';

        $rootScope.$emit("keyboard.set", { value: $scope.input[active_field] });

        $rootScope.$emit("footerburen.button.add", "nextr");
        $rootScope.$emit("footerburen.button.remove", "continuer");
        $rootScope.$emit("footerburen.button.add", "backl");
        $rootScope.$emit("footerburen.button.remove", "back2");

        angular.element('#customercode').show();
        angular.element('#scan_customercode').html('Je kunt je klantnummer ook scannen');
        angular.element('#txtCustomercode').removeAttr('disabled');
        angular.element('#txtCustomercode_1').removeAttr('disabled');
        angular.element('#txtScanCustomercode').prop('disabled', 'disabled');

    };

	var nextClicked = function() {
        $rootScope.$emit("keyboard.reset");

		active_field = 'value_2';

        $rootScope.$emit("footerburen.button.remove", "nextr");
        $rootScope.$emit("footerburen.button.add", "continuer");
        $rootScope.$emit("footerburen.button.remove", "backl");
        $rootScope.$emit("footerburen.button.add", "back2");

		angular.element('#txtCustomercode').prop('disabled', 'disabled');
        angular.element('#txtCustomercode_1').prop('disabled', 'disabled');
		angular.element('#customercode').hide();
		angular.element('#scan_customercode').html('Voer je geheime code in.');
		angular.element('#txtScanCustomercode').removeAttr('disabled');
	};

	var login = function() {
		connection("/api/login/login", {"user":$scope.input.value_1, "password":$scope.password}).then(function(data) {
			if (data.success) {
                if(data.driver){
                    store.setDriver(data.driver, $state);
                }
                if(data.customer){
                    store.setCustomer(data.customer);
                    store.setCustomer(data.customer);
                    $state.go('my_lockers');
                }
			}else{
                $state.go('customer_incorrect');
            }
		}, function() {
            $state.go('customer_incorrect');
			// TODO
			console.log('Error while logging on...')
		});
	};

    if($stateParams.user){
        $scope.input.value_1 = $stateParams.user;
	    nextClicked();
	}

    if($stateParams.type == 'driver'){
        $scope.input_2_title = $state.current.data.input_3.text;
    } else {
        $scope.input_2_title = $state.current.data.input_2.text;
    }
});

mainApp.controller('customerRentLoginController', function($scope, $rootScope, $state, $stateParams, connection, store,
            listener, rent, toolkit) {
    $scope.settings = store.getTerminalConfig();

    listener($scope, {
        handleFooterButton: function(actionCommand){
            console.log(actionCommand);
            if (actionCommand == 'next') {
                nextClicked();
            } else if (actionCommand == 'backForm') {
                backClicked();
            } else if (actionCommand == 'continue') {
                login();
            }
        },
        handleBarcodeEvent: function(barcode){
            connection("/api/scan", {code: barcode}).then(function(data){
                if(data.type=="customer"){
                    if(data.loggedin){
                        store.setCustomer(data.customer);
                        rent.start(barcode);
                        rent.next($state);
                    }else{
                        $state.go("rent_customercode_input", {user: barcode})
                    }
                }else{
                    $state.go('delivery_incorrect');
                }
            });
        }
    });

	$scope.$state = $state;

	$scope.input = { value_1: '', value_2: '' };
	var active_field = 'value_1';
    $scope.password = "";

	$scope.keyboard_input = function(value) {
        if (active_field == 'value_1') {
            $scope.input[active_field] = value.toUpperCase();
        } else {
            $scope.input[active_field] = toolkit.passwordify(value);
            $scope.password = value;
        }
    };

    var backClicked = function() {
        //$rootScope.$emit("keyboard.reset");

        active_field = 'value_1';

        $rootScope.$emit("keyboard.set", { value: $scope.input[active_field] });

        $rootScope.$emit("footerburen.button.add", "nextr");
        $rootScope.$emit("footerburen.button.remove", "continuer");
        $rootScope.$emit("footerburen.button.add", "backl");
        $rootScope.$emit("footerburen.button.remove", "back2");

        angular.element('#customercode').show();
        angular.element('#scan_customercode').html('Je kunt je klantnummer ook scannen');
        angular.element('#txtCustomercode').removeAttr('disabled');
        angular.element('#txtCustomercode_1').removeAttr('disabled');
        angular.element('#txtScanCustomercode').prop('disabled', 'disabled');

    };

	var nextClicked = function() {
        $rootScope.$emit("keyboard.reset");

		active_field = 'value_2';

        $rootScope.$emit("footerburen.button.remove", "nextr");
        $rootScope.$emit("footerburen.button.add", "continuer");
        $rootScope.$emit("footerburen.button.remove", "backl");
        $rootScope.$emit("footerburen.button.add", "back2");

		angular.element('#txtCustomercode').prop('disabled', 'disabled');
        angular.element('#txtCustomercode_1').prop('disabled', 'disabled');
		angular.element('#customercode').hide();
		angular.element('#scan_customercode').html('Vul je geheime code in');
		angular.element('#txtScanCustomercode').removeAttr('disabled');
	};

	var login = function() {
		connection("/api/login/login", {"user":$scope.input.value_1, "password":$scope.password}).then(function(data) {
			if (data.success&&data.customer) {
                store.setCustomer(data.customer);
                rent.start($scope.input.value_1);
                rent.next($state);
			}else{
                $state.go('customer_incorrect');
            }
		}, function() {
            $state.go('customer_incorrect');
		});
	};

    if($stateParams.user){
        $scope.input.value_1 = $stateParams.user;
	    nextClicked();
	}
	
	$scope.anonymous_clicked = function(){
	    rent.start(false);
	    rent.next($state);
	};
});

